/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./LoginReports.css";
import PageHeader from "../../Components/PageHeader";
import useGetSuperAgentList from "../../hooks/SuperAgentMaster/useGetSuperAgentList";
import useGetLoginReport from "../../hooks/useGetLoginReport";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import Select from "react-select";
import { toast } from "react-hot-toast";
import useRemoveLoginReports from "../../hooks/useRemoveLoginReports";
import Swal from "sweetalert2";

export default function SuperAgentLoginReport(props) {
  const uuid = localStorage.getItem("UUID");
  const userId = localStorage.getItem("UUID");
  const loggedAccType = localStorage.getItem("account");

  const { data: superAgentListData } = useGetSuperAgentList();
  const { mutate: getLoginReport } = useGetLoginReport();
  const { mutate: removeLoginReports, isLoading: isRemovingLoginReports } =
    useRemoveLoginReports();

  const [data, setData] = useState([]);
  const [loginData, setLoginData] = useState([]);
  const [completeLoginData, setCompleteLoginData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const [selectedOption, setSelectedOption] = useState(null);

  const totalPages = Math.ceil(loginData.length / itemsPerPage);
  const middlePagesToShow = 3;
  const pageNumbers = [];

  if (totalPages <= middlePagesToShow + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= Math.ceil(middlePagesToShow / 2) + 1) {
    for (let i = 1; i <= middlePagesToShow + 1; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - Math.floor(middlePagesToShow / 2)) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = totalPages - middlePagesToShow; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - Math.floor(middlePagesToShow / 2);
      i <= currentPage + Math.floor(middlePagesToShow / 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  useEffect(() => {
    const listData = superAgentListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
    }

    const payload = {
      acc_type: "super_agent",
      user_id: userId,
      logged_acc_type: loggedAccType,
    };

    getLoginReport(payload, {
      onSuccess: (response) => {
        // console.log(response);
        setLoginData(response.data.data);
        setCompleteLoginData(response.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, [superAgentListData]);

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const user = formData.get("user");
    const from = formData.get("from");
    const to = formData.get("to");

    if (user === "" && from === "" && to === "") {
      setLoginData(completeLoginData);
    } else {
      const filteredData = completeLoginData.filter((item) => {
        if (user !== "" && from !== "" && to !== "") {
          return (
            item.user_super_agent.id === user &&
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (user !== "" && from !== "") {
          return (
            item.user_super_agent.id === user &&
            new Date(item.createdAt) >= new Date(from)
          );
        } else if (user !== "" && to !== "") {
          return (
            item.user_super_agent.id === user &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (from !== "" && to !== "") {
          return (
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (user !== "") {
          return item.user_super_agent.id === user;
        } else if (from !== "") {
          return new Date(item.createdAt) >= new Date(from);
        } else if (to !== "") {
          return new Date(item.createdAt) <= new Date(to);
        }
      });
      setLoginData(filteredData);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loginData.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber === 1) {
      setIsFirstPage(true);
    } else if (pageNumber === Math.ceil(loginData.length / itemsPerPage)) {
      setIsLastPage(true);
    } else {
      setIsFirstPage(false);
      setIsLastPage(false);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(loginData.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 === Math.ceil(loginData.length / itemsPerPage)
      );
    }
  };

  const options = data?.map((item) => ({
    value: item.id,
    label: `${item.code} ${item.name}`,
  }));

  const handleRemoveLoginReports = () => {
    const payload = {
      logged_user_id: uuid,
      acc_type: "super_agent",
    };

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete all login history data of before today. You won't be able to revert it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeLoginReports(payload, {
          onSuccess: (response) => {
            console.log(response);
            toast.success(response?.data?.message);
            window.location.reload();
          },
          onError: (error) => {
            console.log(error);
            toast.error(error.message);
          },
        });
      }
    });
  };

  return (
    <div className="content-wrapper">
      <PageHeader title={`${props.user} Login Report`} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card ">
              <div className="card-header bg-purple">
                <div className="container-fluid">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>{props.user}</label>
                          <Select
                            className="basic-single"
                            id="selectorloginreport"
                            name="user"
                            options={options}
                            value={selectedOption}
                            onChange={setSelectedOption}
                            placeholder="Select"
                          />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Date From</label>
                          <input
                            type="date"
                            name="from"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Date To</label>
                          <input
                            type="date"
                            name="to"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-3 d-flex">
                        <div className="form-group w-100 align-self-end">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      {loggedAccType === "admin" ? (
                        <div className="col-md-2">
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveLoginReports();
                            }}
                            disabled={isRemovingLoginReports}
                          >
                            Delete Reports
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="bg-yellow">
                      <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Login Name</th>
                        <th>IP Address</th>
                        <th>Date Time</th>
                        <th>Last Activity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loginData && loginData.length !== 0 ? (
                        <>
                          {currentItems.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.user_super_agent.code}</td>
                                  <td>{item.user_super_agent.name}</td>
                                  <td>{item.ip}</td>
                                  <td>{dateTimeFormatter(item.createdAt)}</td>
                                  <td>
                                    {dateTimeFormatter(
                                      item.user_super_agent.updatedAt
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <tr className="bg-white">
                            <td colSpan="6">
                              <h6 className="text-center">
                                There are no records to display
                              </h6>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeLoginData.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Previous
                          </a>
                        </li>
                        {pageNumbers.map((number, index) => (
                          <li key={index} className="page-item">
                            <a
                              onClick={() => {
                                if (number !== "...") {
                                  paginate(number);
                                }
                              }}
                              className={`page-link ${
                                currentPage === number ? "active-page-link" : ""
                              } ${number === "..." ? "disabled" : ""}`}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {number}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
