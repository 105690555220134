/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./CompleteSubPages.css";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import useGetMatchSessionAll from "../../hooks/Games/Complete/useGetMatchSessionAll";
import AdminCard from "./components/AdminCard";
import SuperAdminCard from "./components/SuperAdminCard";
import SubAdminCard from "./components/SubAdminCard";
import MasterCard from "./components/MasterCard";
import SuperAgentCard from "./components/SuperAgentCard";
import AgentCard from "./components/AgentCard";

export default function CompleteLongTable() {
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);

  const matchId = UrlSearchParams.get("id");
  const teamWon = UrlSearchParams.get("team");
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");
  const userCode = localStorage.getItem("CODE");
  const userName = localStorage.getItem("NAME");

  const { mutate: getMatchSessionAll } = useGetMatchSessionAll();

  const [matchString, setMatchString] = useState("");
  const [finalReport, setFinalReport] = useState([]);

  const getCompanyReports = () => {
    let result__ = [];
    let match_string = "";

    const payload = {
      acc_type: accountType,
      user_id: userId,
      match_id: matchId,
    };

    getMatchSessionAll(payload, {
      onSuccess: (response) => {
        let game = response.data.Gamedata;

        match_string = `(${game.code}) ${game.name} (${game.match_type})`;
        setMatchString(match_string);

        let result = response.data.SessionData;

        let resp = [];

        if (accountType === "admin") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined && teamWon === "B") {
              m_amt = el.bettingData[0].total_TeamB;
            } else if (teamWon !== undefined && teamWon === "TIE") {
              m_amt = el.bettingData[0].total_draw;
            } else {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_wallet_amt_tot =
              el.SessoinData.length !== 0
                ? el.SessoinData.map((o) => o.wallet_use_amount).reduce(
                    (a, c) => {
                      return a + c;
                    }
                  )
                : 0;

            let s_amt = 0;
            let return_amt = 0;

            if (el.SessoinData.length !== 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.won) {
                    if (ele.mode === "Y") {
                      if (ele.wallet_use_amount < 0) {
                        return_amt =
                          return_amt +
                          ele.wallet_use_amount * -1 +
                          ele.amount * ele.rate;
                      } else {
                        return_amt =
                          return_amt +
                          ele.amount * ele.rate -
                          ele.wallet_use_amount;
                      }
                    } else {
                      if (ele.wallet_use_amount < 0) {
                        return_amt =
                          return_amt + ele.wallet_use_amount * -1 + ele.amount;
                      } else {
                        return_amt =
                          return_amt + ele.amount - ele.wallet_use_amount;
                      }
                    }
                  } else {
                    if (ele.mode === "Y") {
                      if (ele.wallet_use_amount > 0) {
                        return_amt =
                          return_amt +
                          (ele.amount + ele.wallet_use_amount) * -1;
                      } else {
                        if (ele.wallet_use_amount * -1 > ele.amount) {
                          return_amt = return_amt + 0;
                        } else {
                          return_amt =
                            return_amt +
                            (ele.amount - ele.wallet_use_amount * -1) * -1;
                        }
                      }
                    } else {
                      if (ele.wallet_use_amount > 0) {
                        return_amt =
                          return_amt +
                          (ele.amount * ele.rate + ele.wallet_use_amount) * -1;
                      } else {
                        if (
                          ele.wallet_use_amount * -1 >
                          ele.amount * ele.rate
                        ) {
                          return_amt = return_amt + 0;
                        } else {
                          return_amt =
                            return_amt +
                            (ele.amount * ele.rate -
                              ele.wallet_use_amount * -1) *
                              -1;
                        }
                      }
                    }
                  }
                }
              });
            }

            s_amt = return_amt + s_wallet_amt_tot;

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt === 0 &&
              s_amt_tot === 0
            ) {
              return;
            }

            s_amt = s_amt * -1;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;

            let m_comm = 0;
            let s_comm = 0;

            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let client_m_com = 0;
            let mob_charge = 0;

            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (s_amt_tot * el.session_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let client_net_amt = m_amt + s_amt - client_t_amt;

            let client_final =
              m_amt +
              s_amt -
              client_t_amt +
              (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;
            agent_net_amt = m_amt + s_amt - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;
            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            super_agent_net_amt = m_amt + s_amt - super_agent_t_com;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = m_amt + s_amt - master_t_com;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            let sub_admin_t_com =
              sub_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.session_commission) /
                100;
            let sub_admin_net_amt = 0;
            sub_admin_net_amt = m_amt + s_amt - sub_admin_t_com;
            let sub_admin_shr_amount =
              (sub_admin_net_amt * el.parents.parents.parents.parents.share) /
              100;
            let sub_admin_final = sub_admin_net_amt - sub_admin_shr_amount;

            let super_admin_t_com =
              super_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.parents.session_commission) /
                100;
            let super_admin_net_amt = 0;
            super_admin_net_amt = m_amt + s_amt - super_admin_t_com;
            let super_admin_shr_amount =
              (super_admin_net_amt *
                el.parents.parents.parents.parents.parents.share) /
              100;
            let super_admin_final =
              super_admin_net_amt - super_admin_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              client_id: el.id,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt - client_t_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
              sub_admin: `(${el.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.name}`,
              sub_admin_id: el.parents.parents.parents.parents.id,
              sub_admin_m_com: master_m_com,
              sub_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.session_commission) /
                100,
              sub_admin_t_com: sub_admin_t_com,
              sub_admin_shr_amt: sub_admin_shr_amount,
              sub_admin_net_amt: sub_admin_net_amt,
              sub_admin_mob_app: mob_charge,
              sub_admin_final: sub_admin_final,
              super_admin: `(${el.parents.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.parents.name}`,
              super_admin_id: el.parents.parents.parents.parents.parents.id,
              super_admin_m_com: master_m_com,
              super_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.parents
                    .session_commission) /
                100,
              super_admin_t_com: super_admin_t_com,
              super_admin_shr_amt: super_admin_shr_amount,
              super_admin_net_amt: super_admin_net_amt,
              super_admin_mob_app: mob_charge,
              super_admin_final: super_admin_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.super_admin_id)) {
              resp.push({
                id: el.super_admin_id,
                name: el.super_admin,
                sub_admin: [],
              });
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            if (
              !resp[super_admin_index].sub_admin.some(
                (eb) => eb.id === el.sub_admin_id
              )
            ) {
              resp[super_admin_index].sub_admin.push({
                id: el.sub_admin_id,
                name: el.sub_admin,
                master: [],
              });
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              if (
                !resp[super_admin_index].sub_admin[sub_admin_index].master.some(
                  (eb) => eb.id === el.master_id
                )
              ) {
                resp[super_admin_index].sub_admin[sub_admin_index].master.push({
                  id: el.master_id,
                  name: el.master,
                  super_agent: [],
                });
              }
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[super_admin_index].sub_admin[
                sub_admin_index
              ].master.findIndex((em) => em.id === el.master_id);
              if (master_index !== -1) {
                if (
                  !resp[super_admin_index].sub_admin[sub_admin_index].master[
                    master_index
                  ].super_agent.some((eb) => eb.id === el.super_agent_id)
                ) {
                  resp[super_admin_index].sub_admin[sub_admin_index].master[
                    master_index
                  ].super_agent.push({
                    id: el.super_agent_id,
                    name: el.super_agent,
                    agent: [],
                  });
                }
              }
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[super_admin_index].sub_admin[
                sub_admin_index
              ].master.findIndex((em) => em.id === el.master_id);
              if (master_index !== -1) {
                const agent_index = resp[super_admin_index].sub_admin[
                  sub_admin_index
                ].master[master_index].super_agent.findIndex(
                  (em) => em.id === el.super_agent_id
                );
                if (agent_index !== -1) {
                  if (
                    !resp[super_admin_index].sub_admin[sub_admin_index].master[
                      master_index
                    ].super_agent[agent_index].agent.some(
                      (eb) => eb.id === el.agent_id
                    )
                  ) {
                    resp[super_admin_index].sub_admin[sub_admin_index].master[
                      master_index
                    ].super_agent[agent_index].agent.push({
                      id: el.agent_id,
                      name: el.agent,
                      client: [],
                    });
                  }
                }
              }
            }
          });

          result__.forEach((el) => {
            const super_admin_index = resp.findIndex(
              (em) => em.id === el.super_admin_id
            );
            const sub_admin_index = resp[super_admin_index].sub_admin.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[super_admin_index].sub_admin[
                sub_admin_index
              ].master.findIndex((em) => em.id === el.master_id);
              if (master_index !== -1) {
                const agent_index = resp[super_admin_index].sub_admin[
                  sub_admin_index
                ].master[master_index].super_agent.findIndex(
                  (em) => em.id === el.super_agent_id
                );
                if (agent_index !== -1) {
                  const client_index = resp[super_admin_index].sub_admin[
                    sub_admin_index
                  ].master[master_index].super_agent[
                    agent_index
                  ].agent.findIndex((em) => em.id === el.agent_id);
                  if (client_index !== -1) {
                    if (
                      !resp[super_admin_index].sub_admin[
                        sub_admin_index
                      ].master[master_index].super_agent[agent_index].agent[
                        client_index
                      ].client.some((eb) => eb.id === el.agent_id)
                    ) {
                      resp[super_admin_index].sub_admin[sub_admin_index].master[
                        master_index
                      ].super_agent[agent_index].agent[
                        client_index
                      ].client.push(el);
                      // agent_final  +=el.agent_final
                    }

                    const agent =
                      resp[super_admin_index].sub_admin[sub_admin_index].master[
                        master_index
                      ].super_agent[agent_index].agent[client_index];

                    let agent_totals = {
                      m_amt: 0,
                      s_amt: 0,
                      t_amt: 0,
                      m_com: 0,
                      s_com: 0,
                      c_com: 0,
                      net_amt: 0,
                      c_mob: 0,
                      final: 0,

                      agent_m_com: 0,
                      agent_s_com: 0,
                      agent_t_com: 0,
                      agent_net_amt: 0,
                      agent_shr_amt: 0,
                      agent_mob_app: 0,
                      agent_final: 0,

                      super_agent_m_com: 0,
                      super_agent_s_com: 0,
                      super_agent_t_com: 0,
                      super_agent_shr_amt: 0,
                      super_agent_net_amt: 0,
                      super_agent_mob_app: 0,
                      super_agent_final: 0,

                      master_m_com: 0,
                      master_s_com: 0,
                      master_t_com: 0,
                      master_shr_amt: 0,
                      master_net_amt: 0,
                      master_mob_app: 0,
                      master_final: 0,

                      sub_admin_m_com: 0,
                      sub_admin_s_com: 0,
                      sub_admin_t_com: 0,
                      sub_admin_shr_amt: 0,
                      sub_admin_net_amt: 0,
                      sub_admin_mob_app: 0,
                      sub_admin_final: 0,

                      super_admin_m_com: 0,
                      super_admin_s_com: 0,
                      super_admin_t_com: 0,
                      super_admin_shr_amt: 0,
                      super_admin_net_amt: 0,
                      super_admin_mob_app: 0,
                      super_admin_final: 0,
                    };

                    agent.client.forEach((el) => {
                      agent_totals.m_amt += el.m_amt;
                      agent_totals.s_amt += el.s_amt;
                      agent_totals.t_amt += el.t_amt;
                      agent_totals.m_com += el.m_com;
                      agent_totals.s_com += el.s_com;
                      agent_totals.c_com += el.c_com;
                      agent_totals.net_amt += el.net_amt;
                      agent_totals.c_mob += el.c_mob;
                      agent_totals.final += el.final;
                      agent_totals.agent_m_com += el.agent_m_com;
                      agent_totals.agent_s_com += el.agent_s_com;
                      agent_totals.agent_t_com += el.agent_t_com;
                      agent_totals.agent_net_amt += el.agent_net_amt;
                      agent_totals.agent_shr_amt += el.agent_shr_amt;
                      agent_totals.agent_mob_app += el.agent_mob_app;
                      agent_totals.agent_final += el.agent_final;
                      agent_totals.super_agent_m_com += el.super_agent_m_com;
                      agent_totals.super_agent_s_com += el.super_agent_s_com;
                      agent_totals.super_agent_t_com += el.super_agent_t_com;
                      agent_totals.super_agent_shr_amt +=
                        el.super_agent_shr_amt;
                      agent_totals.super_agent_net_amt +=
                        el.super_agent_net_amt;
                      agent_totals.super_agent_mob_app +=
                        el.super_agent_mob_app;
                      agent_totals.super_agent_final += el.super_agent_final;
                      agent_totals.master_m_com += el.master_m_com;
                      agent_totals.master_s_com += el.master_s_com;
                      agent_totals.master_t_com += el.master_t_com;
                      agent_totals.master_shr_amt += el.master_shr_amt;
                      agent_totals.master_net_amt += el.master_net_amt;
                      agent_totals.master_mob_app += el.master_mob_app;
                      agent_totals.master_final += el.master_final;
                      agent_totals.sub_admin_m_com += el.sub_admin_m_com;
                      agent_totals.sub_admin_s_com += el.sub_admin_s_com;
                      agent_totals.sub_admin_t_com += el.sub_admin_t_com;
                      agent_totals.sub_admin_shr_amt += el.sub_admin_shr_amt;
                      agent_totals.sub_admin_net_amt += el.sub_admin_net_amt;
                      agent_totals.sub_admin_mob_app += el.sub_admin_mob_app;
                      agent_totals.sub_admin_final += el.sub_admin_final;
                      agent_totals.super_admin_m_com += el.super_admin_m_com;
                      agent_totals.super_admin_s_com += el.super_admin_s_com;
                      agent_totals.super_admin_t_com += el.super_admin_t_com;
                      agent_totals.super_admin_shr_amt +=
                        el.super_admin_shr_amt;
                      agent_totals.super_admin_net_amt +=
                        el.super_admin_net_amt;
                      agent_totals.super_admin_mob_app +=
                        el.super_admin_mob_app;
                      agent_totals.super_admin_final += el.super_admin_final;
                    });

                    agent.totals = agent_totals;
                  }
                }
              }
            }
          });

          resp.forEach((el) => {
            el.sub_admin.forEach((sub) => {
              sub.master.forEach((master) => {
                master.super_agent.forEach((super_agent) => {
                  if (!super_agent.totals) {
                    super_agent.totals = {
                      m_amt: 0,
                      s_amt: 0,
                      t_amt: 0,
                      m_com: 0,
                      s_com: 0,
                      c_com: 0,
                      net_amt: 0,
                      c_mob: 0,
                      final: 0,

                      agent_m_com: 0,
                      agent_s_com: 0,
                      agent_t_com: 0,
                      agent_net_amt: 0,
                      agent_shr_amt: 0,
                      agent_mob_app: 0,
                      agent_final: 0,

                      super_agent_m_com: 0,
                      super_agent_s_com: 0,
                      super_agent_t_com: 0,
                      super_agent_shr_amt: 0,
                      super_agent_net_amt: 0,
                      super_agent_mob_app: 0,
                      super_agent_final: 0,

                      master_m_com: 0,
                      master_s_com: 0,
                      master_t_com: 0,
                      master_shr_amt: 0,
                      master_net_amt: 0,
                      master_mob_app: 0,
                      master_final: 0,

                      sub_admin_m_com: 0,
                      sub_admin_s_com: 0,
                      sub_admin_t_com: 0,
                      sub_admin_shr_amt: 0,
                      sub_admin_net_amt: 0,
                      sub_admin_mob_app: 0,
                      sub_admin_final: 0,

                      super_admin_m_com: 0,
                      super_admin_s_com: 0,
                      super_admin_t_com: 0,
                      super_admin_shr_amt: 0,
                      super_admin_net_amt: 0,
                      super_admin_mob_app: 0,
                      super_admin_final: 0,
                    };
                  }

                  super_agent.agent.forEach((agent) => {
                    super_agent.totals.m_amt += agent.totals.m_amt;
                    super_agent.totals.s_amt += agent.totals.s_amt;
                    super_agent.totals.t_amt += agent.totals.t_amt;
                    super_agent.totals.m_com += agent.totals.m_com;
                    super_agent.totals.s_com += agent.totals.s_com;
                    super_agent.totals.c_com += agent.totals.c_com;
                    super_agent.totals.net_amt += agent.totals.net_amt;
                    super_agent.totals.c_mob += agent.totals.c_mob;
                    super_agent.totals.final += agent.totals.final;
                    super_agent.totals.agent_m_com += agent.totals.agent_m_com;
                    super_agent.totals.agent_s_com += agent.totals.agent_s_com;
                    super_agent.totals.agent_t_com += agent.totals.agent_t_com;
                    super_agent.totals.agent_net_amt +=
                      agent.totals.agent_net_amt;
                    super_agent.totals.agent_shr_amt +=
                      agent.totals.agent_shr_amt;
                    super_agent.totals.agent_mob_app +=
                      agent.totals.agent_mob_app;
                    super_agent.totals.agent_final += agent.totals.agent_final;
                    super_agent.totals.super_agent_m_com +=
                      agent.totals.super_agent_m_com;
                    super_agent.totals.super_agent_s_com +=
                      agent.totals.super_agent_s_com;
                    super_agent.totals.super_agent_t_com +=
                      agent.totals.super_agent_t_com;
                    super_agent.totals.super_agent_shr_amt +=
                      agent.totals.super_agent_shr_amt;
                    super_agent.totals.super_agent_net_amt +=
                      agent.totals.super_agent_net_amt;
                    super_agent.totals.super_agent_mob_app +=
                      agent.totals.super_agent_mob_app;
                    super_agent.totals.super_agent_final +=
                      agent.totals.super_agent_final;
                    super_agent.totals.master_m_com +=
                      agent.totals.master_m_com;
                    super_agent.totals.master_s_com +=
                      agent.totals.master_s_com;
                    super_agent.totals.master_t_com +=
                      agent.totals.master_t_com;
                    super_agent.totals.master_shr_amt +=
                      agent.totals.master_shr_amt;
                    super_agent.totals.master_net_amt +=
                      agent.totals.master_net_amt;
                    super_agent.totals.master_mob_app +=
                      agent.totals.master_mob_app;
                    super_agent.totals.master_final +=
                      agent.totals.master_final;
                    super_agent.totals.sub_admin_m_com +=
                      agent.totals.sub_admin_m_com;
                    super_agent.totals.sub_admin_s_com +=
                      agent.totals.sub_admin_s_com;
                    super_agent.totals.sub_admin_t_com +=
                      agent.totals.sub_admin_t_com;
                    super_agent.totals.sub_admin_shr_amt +=
                      agent.totals.sub_admin_shr_amt;
                    super_agent.totals.sub_admin_net_amt +=
                      agent.totals.sub_admin_net_amt;
                    super_agent.totals.sub_admin_mob_app +=
                      agent.totals.sub_admin_mob_app;
                    super_agent.totals.sub_admin_final +=
                      agent.totals.sub_admin_final;
                    super_agent.totals.super_admin_m_com +=
                      agent.totals.super_admin_m_com;
                    super_agent.totals.super_admin_s_com +=
                      agent.totals.super_admin_s_com;
                    super_agent.totals.super_admin_t_com +=
                      agent.totals.super_admin_t_com;
                    super_agent.totals.super_admin_shr_amt +=
                      agent.totals.super_admin_shr_amt;
                    super_agent.totals.super_admin_net_amt +=
                      agent.totals.super_admin_net_amt;
                    super_agent.totals.super_admin_mob_app +=
                      agent.totals.super_admin_mob_app;
                    super_agent.totals.super_admin_final +=
                      agent.totals.super_admin_final;
                  });
                });
              });
            });
          });

          resp.forEach((el) => {
            el.sub_admin.forEach((sub) => {
              sub.master.forEach((master) => {
                if (!master.totals) {
                  master.totals = {
                    m_amt: 0,
                    s_amt: 0,
                    t_amt: 0,
                    m_com: 0,
                    s_com: 0,
                    c_com: 0,
                    net_amt: 0,
                    c_mob: 0,
                    final: 0,

                    agent_m_com: 0,
                    agent_s_com: 0,
                    agent_t_com: 0,
                    agent_net_amt: 0,
                    agent_shr_amt: 0,
                    agent_mob_app: 0,
                    agent_final: 0,

                    super_agent_m_com: 0,
                    super_agent_s_com: 0,
                    super_agent_t_com: 0,
                    super_agent_shr_amt: 0,
                    super_agent_net_amt: 0,
                    super_agent_mob_app: 0,
                    super_agent_final: 0,

                    master_m_com: 0,
                    master_s_com: 0,
                    master_t_com: 0,
                    master_shr_amt: 0,
                    master_net_amt: 0,
                    master_mob_app: 0,
                    master_final: 0,

                    sub_admin_m_com: 0,
                    sub_admin_s_com: 0,
                    sub_admin_t_com: 0,
                    sub_admin_shr_amt: 0,
                    sub_admin_net_amt: 0,
                    sub_admin_mob_app: 0,
                    sub_admin_final: 0,

                    super_admin_m_com: 0,
                    super_admin_s_com: 0,
                    super_admin_t_com: 0,
                    super_admin_shr_amt: 0,
                    super_admin_net_amt: 0,
                    super_admin_mob_app: 0,
                    super_admin_final: 0,
                  };
                }

                master.super_agent.forEach((super_agent) => {
                  master.totals.m_amt += super_agent.totals.m_amt;
                  master.totals.s_amt += super_agent.totals.s_amt;
                  master.totals.t_amt += super_agent.totals.t_amt;
                  master.totals.m_com += super_agent.totals.m_com;
                  master.totals.s_com += super_agent.totals.s_com;
                  master.totals.c_com += super_agent.totals.c_com;
                  master.totals.net_amt += super_agent.totals.net_amt;
                  master.totals.c_mob += super_agent.totals.c_mob;
                  master.totals.final += super_agent.totals.final;
                  master.totals.agent_m_com += super_agent.totals.agent_m_com;
                  master.totals.agent_s_com += super_agent.totals.agent_s_com;
                  master.totals.agent_t_com += super_agent.totals.agent_t_com;
                  master.totals.agent_net_amt +=
                    super_agent.totals.agent_net_amt;
                  master.totals.agent_shr_amt +=
                    super_agent.totals.agent_shr_amt;
                  master.totals.agent_mob_app +=
                    super_agent.totals.agent_mob_app;
                  master.totals.agent_final += super_agent.totals.agent_final;
                  master.totals.super_agent_m_com +=
                    super_agent.totals.super_agent_m_com;
                  master.totals.super_agent_s_com +=
                    super_agent.totals.super_agent_s_com;
                  master.totals.super_agent_t_com +=
                    super_agent.totals.super_agent_t_com;
                  master.totals.super_agent_shr_amt +=
                    super_agent.totals.super_agent_shr_amt;
                  master.totals.super_agent_net_amt +=
                    super_agent.totals.super_agent_net_amt;
                  master.totals.super_agent_mob_app +=
                    super_agent.totals.super_agent_mob_app;
                  master.totals.super_agent_final +=
                    super_agent.totals.super_agent_final;
                  master.totals.master_m_com += super_agent.totals.master_m_com;
                  master.totals.master_s_com += super_agent.totals.master_s_com;
                  master.totals.master_t_com += super_agent.totals.master_t_com;
                  master.totals.master_shr_amt +=
                    super_agent.totals.master_shr_amt;
                  master.totals.master_net_amt +=
                    super_agent.totals.master_net_amt;
                  master.totals.master_mob_app +=
                    super_agent.totals.master_mob_app;
                  master.totals.master_final += super_agent.totals.master_final;
                  master.totals.sub_admin_m_com +=
                    super_agent.totals.sub_admin_m_com;
                  master.totals.sub_admin_s_com +=
                    super_agent.totals.sub_admin_s_com;
                  master.totals.sub_admin_t_com +=
                    super_agent.totals.sub_admin_t_com;
                  master.totals.sub_admin_shr_amt +=
                    super_agent.totals.sub_admin_shr_amt;
                  master.totals.sub_admin_net_amt +=
                    super_agent.totals.sub_admin_net_amt;
                  master.totals.sub_admin_mob_app +=
                    super_agent.totals.sub_admin_mob_app;
                  master.totals.sub_admin_final +=
                    super_agent.totals.sub_admin_final;
                  master.totals.super_admin_m_com +=
                    super_agent.totals.super_admin_m_com;
                  master.totals.super_admin_s_com +=
                    super_agent.totals.super_admin_s_com;
                  master.totals.super_admin_t_com +=
                    super_agent.totals.super_admin_t_com;
                  master.totals.super_admin_shr_amt +=
                    super_agent.totals.super_admin_shr_amt;
                  master.totals.super_admin_net_amt +=
                    super_agent.totals.super_admin_net_amt;
                  master.totals.super_admin_mob_app +=
                    super_agent.totals.super_admin_mob_app;
                  master.totals.super_admin_final +=
                    super_agent.totals.super_admin_final;
                });
              });
            });
          });

          resp.forEach((el) => {
            el.sub_admin.forEach((sub) => {
              if (!sub.totals) {
                sub.totals = {
                  m_amt: 0,
                  s_amt: 0,
                  t_amt: 0,
                  m_com: 0,
                  s_com: 0,
                  c_com: 0,
                  net_amt: 0,
                  c_mob: 0,
                  final: 0,

                  agent_m_com: 0,
                  agent_s_com: 0,
                  agent_t_com: 0,
                  agent_net_amt: 0,
                  agent_shr_amt: 0,
                  agent_mob_app: 0,
                  agent_final: 0,

                  super_agent_m_com: 0,
                  super_agent_s_com: 0,
                  super_agent_t_com: 0,
                  super_agent_shr_amt: 0,
                  super_agent_net_amt: 0,
                  super_agent_mob_app: 0,
                  super_agent_final: 0,

                  master_m_com: 0,
                  master_s_com: 0,
                  master_t_com: 0,
                  master_shr_amt: 0,
                  master_net_amt: 0,
                  master_mob_app: 0,
                  master_final: 0,

                  sub_admin_m_com: 0,
                  sub_admin_s_com: 0,
                  sub_admin_t_com: 0,
                  sub_admin_shr_amt: 0,
                  sub_admin_net_amt: 0,
                  sub_admin_mob_app: 0,
                  sub_admin_final: 0,

                  super_admin_m_com: 0,
                  super_admin_s_com: 0,
                  super_admin_t_com: 0,
                  super_admin_shr_amt: 0,
                  super_admin_net_amt: 0,
                  super_admin_mob_app: 0,
                  super_admin_final: 0,
                };
              }

              sub.master.forEach((master) => {
                sub.totals.m_amt += master.totals.m_amt;
                sub.totals.s_amt += master.totals.s_amt;
                sub.totals.t_amt += master.totals.t_amt;
                sub.totals.m_com += master.totals.m_com;
                sub.totals.s_com += master.totals.s_com;
                sub.totals.c_com += master.totals.c_com;
                sub.totals.net_amt += master.totals.net_amt;
                sub.totals.c_mob += master.totals.c_mob;
                sub.totals.final += master.totals.final;
                sub.totals.agent_m_com += master.totals.agent_m_com;
                sub.totals.agent_s_com += master.totals.agent_s_com;
                sub.totals.agent_t_com += master.totals.agent_t_com;
                sub.totals.agent_net_amt += master.totals.agent_net_amt;
                sub.totals.agent_shr_amt += master.totals.agent_shr_amt;
                sub.totals.agent_mob_app += master.totals.agent_mob_app;
                sub.totals.agent_final += master.totals.agent_final;
                sub.totals.super_agent_m_com += master.totals.super_agent_m_com;
                sub.totals.super_agent_s_com += master.totals.super_agent_s_com;
                sub.totals.super_agent_t_com += master.totals.super_agent_t_com;
                sub.totals.super_agent_shr_amt +=
                  master.totals.super_agent_shr_amt;
                sub.totals.super_agent_net_amt +=
                  master.totals.super_agent_net_amt;
                sub.totals.super_agent_mob_app +=
                  master.totals.super_agent_mob_app;
                sub.totals.super_agent_final += master.totals.super_agent_final;
                sub.totals.master_m_com += master.totals.master_m_com;
                sub.totals.master_s_com += master.totals.master_s_com;
                sub.totals.master_t_com += master.totals.master_t_com;
                sub.totals.master_shr_amt += master.totals.master_shr_amt;
                sub.totals.master_net_amt += master.totals.master_net_amt;
                sub.totals.master_mob_app += master.totals.master_mob_app;
                sub.totals.master_final += master.totals.master_final;
                sub.totals.sub_admin_m_com += master.totals.sub_admin_m_com;
                sub.totals.sub_admin_s_com += master.totals.sub_admin_s_com;
                sub.totals.sub_admin_t_com += master.totals.sub_admin_t_com;
                sub.totals.sub_admin_shr_amt += master.totals.sub_admin_shr_amt;
                sub.totals.sub_admin_net_amt += master.totals.sub_admin_net_amt;
                sub.totals.sub_admin_mob_app += master.totals.sub_admin_mob_app;
                sub.totals.sub_admin_final += master.totals.sub_admin_final;
                sub.totals.super_admin_m_com += master.totals.super_admin_m_com;
                sub.totals.super_admin_s_com += master.totals.super_admin_s_com;
                sub.totals.super_admin_t_com += master.totals.super_admin_t_com;
                sub.totals.super_admin_shr_amt +=
                  master.totals.super_admin_shr_amt;
                sub.totals.super_admin_net_amt +=
                  master.totals.super_admin_net_amt;
                sub.totals.super_admin_mob_app +=
                  master.totals.super_admin_mob_app;
                sub.totals.super_admin_final += master.totals.super_admin_final;
              });
            });
          });

          resp.forEach((mini) => {
            if (!mini.totals) {
              mini.totals = {
                m_amt: 0,
                s_amt: 0,
                t_amt: 0,
                m_com: 0,
                s_com: 0,
                c_com: 0,
                net_amt: 0,
                c_mob: 0,
                final: 0,

                agent_m_com: 0,
                agent_s_com: 0,
                agent_t_com: 0,
                agent_net_amt: 0,
                agent_shr_amt: 0,
                agent_mob_app: 0,
                agent_final: 0,

                super_agent_m_com: 0,
                super_agent_s_com: 0,
                super_agent_t_com: 0,
                super_agent_shr_amt: 0,
                super_agent_net_amt: 0,
                super_agent_mob_app: 0,
                super_agent_final: 0,

                master_m_com: 0,
                master_s_com: 0,
                master_t_com: 0,
                master_shr_amt: 0,
                master_net_amt: 0,
                master_mob_app: 0,
                master_final: 0,

                sub_admin_m_com: 0,
                sub_admin_s_com: 0,
                sub_admin_t_com: 0,
                sub_admin_shr_amt: 0,
                sub_admin_net_amt: 0,
                sub_admin_mob_app: 0,
                sub_admin_final: 0,

                super_admin_m_com: 0,
                super_admin_s_com: 0,
                super_admin_t_com: 0,
                super_admin_shr_amt: 0,
                super_admin_net_amt: 0,
                super_admin_mob_app: 0,
                super_admin_final: 0,
              };
            }

            mini.sub_admin.forEach((sub) => {
              mini.totals.m_amt += sub.totals.m_amt;
              mini.totals.s_amt += sub.totals.s_amt;
              mini.totals.t_amt += sub.totals.t_amt;
              mini.totals.m_com += sub.totals.m_com;
              mini.totals.s_com += sub.totals.s_com;
              mini.totals.c_com += sub.totals.c_com;
              mini.totals.net_amt += sub.totals.net_amt;
              mini.totals.c_mob += sub.totals.c_mob;
              mini.totals.final += sub.totals.final;
              mini.totals.agent_m_com += sub.totals.agent_m_com;
              mini.totals.agent_s_com += sub.totals.agent_s_com;
              mini.totals.agent_t_com += sub.totals.agent_t_com;
              mini.totals.agent_net_amt += sub.totals.agent_net_amt;
              mini.totals.agent_shr_amt += sub.totals.agent_shr_amt;
              mini.totals.agent_mob_app += sub.totals.agent_mob_app;
              mini.totals.agent_final += sub.totals.agent_final;
              mini.totals.super_agent_m_com += sub.totals.super_agent_m_com;
              mini.totals.super_agent_s_com += sub.totals.super_agent_s_com;
              mini.totals.super_agent_t_com += sub.totals.super_agent_t_com;
              mini.totals.super_agent_shr_amt += sub.totals.super_agent_shr_amt;
              mini.totals.super_agent_net_amt += sub.totals.super_agent_net_amt;
              mini.totals.super_agent_mob_app += sub.totals.super_agent_mob_app;
              mini.totals.super_agent_final += sub.totals.super_agent_final;
              mini.totals.master_m_com += sub.totals.master_m_com;
              mini.totals.master_s_com += sub.totals.master_s_com;
              mini.totals.master_t_com += sub.totals.master_t_com;
              mini.totals.master_shr_amt += sub.totals.master_shr_amt;
              mini.totals.master_net_amt += sub.totals.master_net_amt;
              mini.totals.master_mob_app += sub.totals.master_mob_app;
              mini.totals.master_final += sub.totals.master_final;
              mini.totals.sub_admin_m_com += sub.totals.sub_admin_m_com;
              mini.totals.sub_admin_s_com += sub.totals.sub_admin_s_com;
              mini.totals.sub_admin_t_com += sub.totals.sub_admin_t_com;
              mini.totals.sub_admin_shr_amt += sub.totals.sub_admin_shr_amt;
              mini.totals.sub_admin_net_amt += sub.totals.sub_admin_net_amt;
              mini.totals.sub_admin_mob_app += sub.totals.sub_admin_mob_app;
              mini.totals.sub_admin_final += sub.totals.sub_admin_final;
              mini.totals.super_admin_m_com += sub.totals.super_admin_m_com;
              mini.totals.super_admin_s_com += sub.totals.super_admin_s_com;
              mini.totals.super_admin_t_com += sub.totals.super_admin_t_com;
              mini.totals.super_admin_shr_amt += sub.totals.super_admin_shr_amt;
              mini.totals.super_admin_net_amt += sub.totals.super_admin_net_amt;
              mini.totals.super_admin_mob_app += sub.totals.super_admin_mob_app;
              mini.totals.super_admin_final += sub.totals.super_admin_final;
            });
          });

          if (!resp.totals) {
            resp.totals = {
              m_amt: 0,
              s_amt: 0,
              t_amt: 0,
              m_com: 0,
              s_com: 0,
              c_com: 0,
              net_amt: 0,
              c_mob: 0,
              final: 0,

              agent_m_com: 0,
              agent_s_com: 0,
              agent_t_com: 0,
              agent_net_amt: 0,
              agent_shr_amt: 0,
              agent_mob_app: 0,
              agent_final: 0,

              super_agent_m_com: 0,
              super_agent_s_com: 0,
              super_agent_t_com: 0,
              super_agent_shr_amt: 0,
              super_agent_net_amt: 0,
              super_agent_mob_app: 0,
              super_agent_final: 0,

              master_m_com: 0,
              master_s_com: 0,
              master_t_com: 0,
              master_shr_amt: 0,
              master_net_amt: 0,
              master_mob_app: 0,
              master_final: 0,

              sub_admin_m_com: 0,
              sub_admin_s_com: 0,
              sub_admin_t_com: 0,
              sub_admin_shr_amt: 0,
              sub_admin_net_amt: 0,
              sub_admin_mob_app: 0,
              sub_admin_final: 0,

              super_admin_m_com: 0,
              super_admin_s_com: 0,
              super_admin_t_com: 0,
              super_admin_shr_amt: 0,
              super_admin_net_amt: 0,
              super_admin_mob_app: 0,
              super_admin_final: 0,
            };
          }

          resp.forEach((mini) => {
            resp.totals.m_amt += mini.totals.m_amt;
            resp.totals.s_amt += mini.totals.s_amt;
            resp.totals.t_amt += mini.totals.t_amt;
            resp.totals.m_com += mini.totals.m_com;
            resp.totals.s_com += mini.totals.s_com;
            resp.totals.c_com += mini.totals.c_com;
            resp.totals.net_amt += mini.totals.net_amt;
            resp.totals.c_mob += mini.totals.c_mob;
            resp.totals.final += mini.totals.final;
            resp.totals.agent_m_com += mini.totals.agent_m_com;
            resp.totals.agent_s_com += mini.totals.agent_s_com;
            resp.totals.agent_t_com += mini.totals.agent_t_com;
            resp.totals.agent_net_amt += mini.totals.agent_net_amt;
            resp.totals.agent_shr_amt += mini.totals.agent_shr_amt;
            resp.totals.agent_mob_app += mini.totals.agent_mob_app;
            resp.totals.agent_final += mini.totals.agent_final;
            resp.totals.super_agent_m_com += mini.totals.super_agent_m_com;
            resp.totals.super_agent_s_com += mini.totals.super_agent_s_com;
            resp.totals.super_agent_t_com += mini.totals.super_agent_t_com;
            resp.totals.super_agent_shr_amt += mini.totals.super_agent_shr_amt;
            resp.totals.super_agent_net_amt += mini.totals.super_agent_net_amt;
            resp.totals.super_agent_mob_app += mini.totals.super_agent_mob_app;
            resp.totals.super_agent_final += mini.totals.super_agent_final;
            resp.totals.master_m_com += mini.totals.master_m_com;
            resp.totals.master_s_com += mini.totals.master_s_com;
            resp.totals.master_t_com += mini.totals.master_t_com;
            resp.totals.master_shr_amt += mini.totals.master_shr_amt;
            resp.totals.master_net_amt += mini.totals.master_net_amt;
            resp.totals.master_mob_app += mini.totals.master_mob_app;
            resp.totals.master_final += mini.totals.master_final;
            resp.totals.sub_admin_m_com += mini.totals.sub_admin_m_com;
            resp.totals.sub_admin_s_com += mini.totals.sub_admin_s_com;
            resp.totals.sub_admin_t_com += mini.totals.sub_admin_t_com;
            resp.totals.sub_admin_shr_amt += mini.totals.sub_admin_shr_amt;
            resp.totals.sub_admin_net_amt += mini.totals.sub_admin_net_amt;
            resp.totals.sub_admin_mob_app += mini.totals.sub_admin_mob_app;
            resp.totals.sub_admin_final += mini.totals.sub_admin_final;
            resp.totals.super_admin_m_com += mini.totals.super_admin_m_com;
            resp.totals.super_admin_s_com += mini.totals.super_admin_s_com;
            resp.totals.super_admin_t_com += mini.totals.super_admin_t_com;
            resp.totals.super_admin_shr_amt += mini.totals.super_admin_shr_amt;
            resp.totals.super_admin_net_amt += mini.totals.super_admin_net_amt;
            resp.totals.super_admin_mob_app += mini.totals.super_admin_mob_app;
            resp.totals.super_admin_final += mini.totals.super_admin_final;
          });

          setTimeout(() => {
            console.log("result", result);
            console.log(resp);
            setFinalReport(resp);
          }, 1000);
        } else if (accountType === "super_admin") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined && teamWon === "B") {
              m_amt = el.bettingData[0].total_TeamB;
            } else {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length !== 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare !== null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;

            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let client_m_com = 0;
            let m_comm = 0;
            let s_comm = 0;
            let mob_charge = 0;

            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let client_net_amt = m_amt + s_amt - client_t_amt;

            let client_final =
              m_amt +
              s_amt -
              client_t_amt +
              (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;
            agent_net_amt = m_amt + s_amt - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;
            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            if (super_agent_t_com < 0) {
              super_agent_net_amt = m_amt + s_amt + super_agent_t_com;
            } else {
              super_agent_net_amt = m_amt + s_amt - super_agent_t_com;
            }
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = m_amt + s_amt - master_t_com;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            let sub_admin_t_com =
              sub_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.session_commission) /
                100;
            let sub_admin_net_amt = 0;
            sub_admin_net_amt = m_amt + s_amt - sub_admin_t_com;
            let sub_admin_shr_amount =
              (sub_admin_net_amt * el.parents.parents.parents.parents.share) /
              100;
            let sub_admin_final = sub_admin_net_amt - sub_admin_shr_amount;

            let super_admin_t_com =
              super_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.parents.session_commission) /
                100;
            let super_admin_net_amt = 0;
            super_admin_net_amt = m_amt + s_amt - super_admin_t_com;
            let super_admin_shr_amount =
              (super_admin_net_amt *
                el.parents.parents.parents.parents.parents.share) /
              100;
            let super_admin_final =
              super_admin_net_amt - super_admin_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.name,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
              sub_admin: `(${el.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.name}`,
              sub_admin_id: el.parents.parents.parents.parents.id,
              sub_admin_m_com: master_m_com,
              sub_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.session_commission) /
                100,
              sub_admin_t_com: sub_admin_t_com,
              sub_admin_shr_amt: sub_admin_shr_amount,
              sub_admin_net_amt: sub_admin_net_amt,
              sub_admin_mob_app: mob_charge,
              sub_admin_final: sub_admin_final,
              super_admin: `(${el.parents.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.parents.name}`,
              super_admin_id: el.parents.parents.parents.parents.parents.id,
              super_admin_m_com: master_m_com,
              super_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.parents
                    .session_commission) /
                100,
              super_admin_t_com: super_admin_t_com,
              super_admin_shr_amt: super_admin_shr_amount,
              super_admin_net_amt: super_admin_net_amt,
              super_admin_mob_app: mob_charge,
              super_admin_final: super_admin_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.sub_admin_id)) {
              resp.push({
                id: el.sub_admin_id,
                name: el.sub_admin,
                master: [],
              });
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (
              !resp[sub_admin_index].master.some((eb) => eb.id === el.master_id)
            ) {
              resp[sub_admin_index].master.push({
                id: el.master_id,
                name: el.master,
                super_agent: [],
              });
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[sub_admin_index].master.findIndex(
                (em) => em.id === el.master_id
              );
              if (master_index !== -1) {
                if (
                  !resp[sub_admin_index].master[master_index].super_agent.some(
                    (eb) => eb.id === el.super_agent_id
                  )
                ) {
                  resp[sub_admin_index].master[master_index].super_agent.push({
                    id: el.super_agent_id,
                    name: el.super_agent,
                    agent: [],
                  });
                }
              }
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[sub_admin_index].master.findIndex(
                (em) => em.id === el.master_id
              );
              if (master_index !== -1) {
                const agent_index = resp[sub_admin_index].master[
                  master_index
                ].super_agent.findIndex((em) => em.id === el.super_agent_id);
                if (agent_index !== -1) {
                  if (
                    !resp[sub_admin_index].master[master_index].super_agent[
                      agent_index
                    ].agent.some((eb) => eb.id === el.agent_id)
                  ) {
                    resp[sub_admin_index].master[master_index].super_agent[
                      agent_index
                    ].agent.push({
                      id: el.agent_id,
                      name: el.agent,
                      client: [],
                    });
                  }
                }
              }
            }
          });

          result__.forEach((el) => {
            const sub_admin_index = resp.findIndex(
              (em) => em.id === el.sub_admin_id
            );
            if (sub_admin_index !== -1) {
              const master_index = resp[sub_admin_index].master.findIndex(
                (em) => em.id === el.master_id
              );
              if (master_index !== -1) {
                const agent_index = resp[sub_admin_index].master[
                  master_index
                ].super_agent.findIndex((em) => em.id === el.super_agent_id);
                if (agent_index !== -1) {
                  const client_index = resp[sub_admin_index].master[
                    master_index
                  ].super_agent[agent_index].agent.findIndex(
                    (em) => em.id === el.agent_id
                  );
                  if (client_index !== -1) {
                    if (
                      !resp[sub_admin_index].master[master_index].super_agent[
                        agent_index
                      ].agent[client_index].client.some(
                        (eb) => eb.id === el.agent_id
                      )
                    ) {
                      resp[sub_admin_index].master[master_index].super_agent[
                        agent_index
                      ].agent[client_index].client.push(el);
                    }

                    const agent =
                      resp[sub_admin_index].master[master_index].super_agent[
                        agent_index
                      ].agent[client_index];

                    let agent_totals = {
                      m_amt: 0,
                      s_amt: 0,
                      t_amt: 0,
                      m_com: 0,
                      s_com: 0,
                      c_com: 0,
                      net_amt: 0,
                      c_mob: 0,
                      final: 0,

                      agent_m_com: 0,
                      agent_s_com: 0,
                      agent_t_com: 0,
                      agent_net_amt: 0,
                      agent_shr_amt: 0,
                      agent_mob_app: 0,
                      agent_final: 0,

                      super_agent_m_com: 0,
                      super_agent_s_com: 0,
                      super_agent_t_com: 0,
                      super_agent_shr_amt: 0,
                      super_agent_net_amt: 0,
                      super_agent_mob_app: 0,
                      super_agent_final: 0,

                      master_m_com: 0,
                      master_s_com: 0,
                      master_t_com: 0,
                      master_shr_amt: 0,
                      master_net_amt: 0,
                      master_mob_app: 0,
                      master_final: 0,

                      sub_admin_m_com: 0,
                      sub_admin_s_com: 0,
                      sub_admin_t_com: 0,
                      sub_admin_shr_amt: 0,
                      sub_admin_net_amt: 0,
                      sub_admin_mob_app: 0,
                      sub_admin_final: 0,

                      super_admin_m_com: 0,
                      super_admin_s_com: 0,
                      super_admin_t_com: 0,
                      super_admin_shr_amt: 0,
                      super_admin_net_amt: 0,
                      super_admin_mob_app: 0,
                      super_admin_final: 0,
                    };

                    agent.client.forEach((el) => {
                      agent_totals.m_amt += el.m_amt;
                      agent_totals.s_amt += el.s_amt;
                      agent_totals.t_amt += el.t_amt;
                      agent_totals.m_com += el.m_com;
                      agent_totals.s_com += el.s_com;
                      agent_totals.c_com += el.c_com;
                      agent_totals.net_amt += el.net_amt;
                      agent_totals.c_mob += el.c_mob;
                      agent_totals.final += el.final;
                      agent_totals.agent_m_com += el.agent_m_com;
                      agent_totals.agent_s_com += el.agent_s_com;
                      agent_totals.agent_t_com += el.agent_t_com;
                      agent_totals.agent_net_amt += el.agent_net_amt;
                      agent_totals.agent_shr_amt += el.agent_shr_amt;
                      agent_totals.agent_mob_app += el.agent_mob_app;
                      agent_totals.agent_final += el.agent_final;
                      agent_totals.super_agent_m_com += el.super_agent_m_com;
                      agent_totals.super_agent_s_com += el.super_agent_s_com;
                      agent_totals.super_agent_t_com += el.super_agent_t_com;
                      agent_totals.super_agent_shr_amt +=
                        el.super_agent_shr_amt;
                      agent_totals.super_agent_net_amt +=
                        el.super_agent_net_amt;
                      agent_totals.super_agent_mob_app +=
                        el.super_agent_mob_app;
                      agent_totals.super_agent_final += el.super_agent_final;
                      agent_totals.master_m_com += el.master_m_com;
                      agent_totals.master_s_com += el.master_s_com;
                      agent_totals.master_t_com += el.master_t_com;
                      agent_totals.master_shr_amt += el.master_shr_amt;
                      agent_totals.master_net_amt += el.master_net_amt;
                      agent_totals.master_mob_app += el.master_mob_app;
                      agent_totals.master_final += el.master_final;
                      agent_totals.sub_admin_m_com += el.sub_admin_m_com;
                      agent_totals.sub_admin_s_com += el.sub_admin_s_com;
                      agent_totals.sub_admin_t_com += el.sub_admin_t_com;
                      agent_totals.sub_admin_shr_amt += el.sub_admin_shr_amt;
                      agent_totals.sub_admin_net_amt += el.sub_admin_net_amt;
                      agent_totals.sub_admin_mob_app += el.sub_admin_mob_app;
                      agent_totals.sub_admin_final += el.sub_admin_final;
                      agent_totals.super_admin_m_com += el.super_admin_m_com;
                      agent_totals.super_admin_s_com += el.super_admin_s_com;
                      agent_totals.super_admin_t_com += el.super_admin_t_com;
                      agent_totals.super_admin_shr_amt +=
                        el.super_admin_shr_amt;
                      agent_totals.super_admin_net_amt +=
                        el.super_admin_net_amt;
                      agent_totals.super_admin_mob_app +=
                        el.super_admin_mob_app;
                      agent_totals.super_admin_final += el.super_admin_final;
                    });

                    agent.totals = agent_totals;
                  }
                }
              }
            }
          });

          resp.forEach((el) => {
            el.master.forEach((master) => {
              master.super_agent.forEach((super_agent) => {
                if (!super_agent.totals) {
                  super_agent.totals = {
                    m_amt: 0,
                    s_amt: 0,
                    t_amt: 0,
                    m_com: 0,
                    s_com: 0,
                    c_com: 0,
                    net_amt: 0,
                    c_mob: 0,
                    final: 0,

                    agent_m_com: 0,
                    agent_s_com: 0,
                    agent_t_com: 0,
                    agent_net_amt: 0,
                    agent_shr_amt: 0,
                    agent_mob_app: 0,
                    agent_final: 0,

                    super_agent_m_com: 0,
                    super_agent_s_com: 0,
                    super_agent_t_com: 0,
                    super_agent_shr_amt: 0,
                    super_agent_net_amt: 0,
                    super_agent_mob_app: 0,
                    super_agent_final: 0,

                    master_m_com: 0,
                    master_s_com: 0,
                    master_t_com: 0,
                    master_shr_amt: 0,
                    master_net_amt: 0,
                    master_mob_app: 0,
                    master_final: 0,

                    sub_admin_m_com: 0,
                    sub_admin_s_com: 0,
                    sub_admin_t_com: 0,
                    sub_admin_shr_amt: 0,
                    sub_admin_net_amt: 0,
                    sub_admin_mob_app: 0,
                    sub_admin_final: 0,

                    super_admin_m_com: 0,
                    super_admin_s_com: 0,
                    super_admin_t_com: 0,
                    super_admin_shr_amt: 0,
                    super_admin_net_amt: 0,
                    super_admin_mob_app: 0,
                    super_admin_final: 0,
                  };
                }

                super_agent.agent.forEach((agent) => {
                  super_agent.totals.m_amt += agent.totals.m_amt;
                  super_agent.totals.s_amt += agent.totals.s_amt;
                  super_agent.totals.t_amt += agent.totals.t_amt;
                  super_agent.totals.m_com += agent.totals.m_com;
                  super_agent.totals.s_com += agent.totals.s_com;
                  super_agent.totals.c_com += agent.totals.c_com;
                  super_agent.totals.net_amt += agent.totals.net_amt;
                  super_agent.totals.c_mob += agent.totals.c_mob;
                  super_agent.totals.final += agent.totals.final;
                  super_agent.totals.agent_m_com += agent.totals.agent_m_com;
                  super_agent.totals.agent_s_com += agent.totals.agent_s_com;
                  super_agent.totals.agent_t_com += agent.totals.agent_t_com;
                  super_agent.totals.agent_net_amt +=
                    agent.totals.agent_net_amt;
                  super_agent.totals.agent_shr_amt +=
                    agent.totals.agent_shr_amt;
                  super_agent.totals.agent_mob_app +=
                    agent.totals.agent_mob_app;
                  super_agent.totals.agent_final += agent.totals.agent_final;
                  super_agent.totals.super_agent_m_com +=
                    agent.totals.super_agent_m_com;
                  super_agent.totals.super_agent_s_com +=
                    agent.totals.super_agent_s_com;
                  super_agent.totals.super_agent_t_com +=
                    agent.totals.super_agent_t_com;
                  super_agent.totals.super_agent_shr_amt +=
                    agent.totals.super_agent_shr_amt;
                  super_agent.totals.super_agent_net_amt +=
                    agent.totals.super_agent_net_amt;
                  super_agent.totals.super_agent_mob_app +=
                    agent.totals.super_agent_mob_app;
                  super_agent.totals.super_agent_final +=
                    agent.totals.super_agent_final;
                  super_agent.totals.master_m_com += agent.totals.master_m_com;
                  super_agent.totals.master_s_com += agent.totals.master_s_com;
                  super_agent.totals.master_t_com += agent.totals.master_t_com;
                  super_agent.totals.master_shr_amt +=
                    agent.totals.master_shr_amt;
                  super_agent.totals.master_net_amt +=
                    agent.totals.master_net_amt;
                  super_agent.totals.master_mob_app +=
                    agent.totals.master_mob_app;
                  super_agent.totals.master_final += agent.totals.master_final;
                  super_agent.totals.sub_admin_m_com +=
                    agent.totals.sub_admin_m_com;
                  super_agent.totals.sub_admin_s_com +=
                    agent.totals.sub_admin_s_com;
                  super_agent.totals.sub_admin_t_com +=
                    agent.totals.sub_admin_t_com;
                  super_agent.totals.sub_admin_shr_amt +=
                    agent.totals.sub_admin_shr_amt;
                  super_agent.totals.sub_admin_net_amt +=
                    agent.totals.sub_admin_net_amt;
                  super_agent.totals.sub_admin_mob_app +=
                    agent.totals.sub_admin_mob_app;
                  super_agent.totals.sub_admin_final +=
                    agent.totals.sub_admin_final;
                  super_agent.totals.super_admin_m_com +=
                    agent.totals.super_admin_m_com;
                  super_agent.totals.super_admin_s_com +=
                    agent.totals.super_admin_s_com;
                  super_agent.totals.super_admin_t_com +=
                    agent.totals.super_admin_t_com;
                  super_agent.totals.super_admin_shr_amt +=
                    agent.totals.super_admin_shr_amt;
                  super_agent.totals.super_admin_net_amt +=
                    agent.totals.super_admin_net_amt;
                  super_agent.totals.super_admin_mob_app +=
                    agent.totals.super_admin_mob_app;
                  super_agent.totals.super_admin_final +=
                    agent.totals.super_admin_final;
                });
              });
            });
          });

          resp.forEach((el) => {
            el.master.forEach((master) => {
              if (!master.totals) {
                master.totals = {
                  m_amt: 0,
                  s_amt: 0,
                  t_amt: 0,
                  m_com: 0,
                  s_com: 0,
                  c_com: 0,
                  net_amt: 0,
                  c_mob: 0,
                  final: 0,

                  agent_m_com: 0,
                  agent_s_com: 0,
                  agent_t_com: 0,
                  agent_net_amt: 0,
                  agent_shr_amt: 0,
                  agent_mob_app: 0,
                  agent_final: 0,

                  super_agent_m_com: 0,
                  super_agent_s_com: 0,
                  super_agent_t_com: 0,
                  super_agent_shr_amt: 0,
                  super_agent_net_amt: 0,
                  super_agent_mob_app: 0,
                  super_agent_final: 0,

                  master_m_com: 0,
                  master_s_com: 0,
                  master_t_com: 0,
                  master_shr_amt: 0,
                  master_net_amt: 0,
                  master_mob_app: 0,
                  master_final: 0,

                  sub_admin_m_com: 0,
                  sub_admin_s_com: 0,
                  sub_admin_t_com: 0,
                  sub_admin_shr_amt: 0,
                  sub_admin_net_amt: 0,
                  sub_admin_mob_app: 0,
                  sub_admin_final: 0,

                  super_admin_m_com: 0,
                  super_admin_s_com: 0,
                  super_admin_t_com: 0,
                  super_admin_shr_amt: 0,
                  super_admin_net_amt: 0,
                  super_admin_mob_app: 0,
                  super_admin_final: 0,
                };
              }

              master.super_agent.forEach((super_agent) => {
                master.totals.m_amt += super_agent.totals.m_amt;
                master.totals.s_amt += super_agent.totals.s_amt;
                master.totals.t_amt += super_agent.totals.t_amt;
                master.totals.m_com += super_agent.totals.m_com;
                master.totals.s_com += super_agent.totals.s_com;
                master.totals.c_com += super_agent.totals.c_com;
                master.totals.net_amt += super_agent.totals.net_amt;
                master.totals.c_mob += super_agent.totals.c_mob;
                master.totals.final += super_agent.totals.final;
                master.totals.agent_m_com += super_agent.totals.agent_m_com;
                master.totals.agent_s_com += super_agent.totals.agent_s_com;
                master.totals.agent_t_com += super_agent.totals.agent_t_com;
                master.totals.agent_net_amt += super_agent.totals.agent_net_amt;
                master.totals.agent_shr_amt += super_agent.totals.agent_shr_amt;
                master.totals.agent_mob_app += super_agent.totals.agent_mob_app;
                master.totals.agent_final += super_agent.totals.agent_final;
                master.totals.super_agent_m_com +=
                  super_agent.totals.super_agent_m_com;
                master.totals.super_agent_s_com +=
                  super_agent.totals.super_agent_s_com;
                master.totals.super_agent_t_com +=
                  super_agent.totals.super_agent_t_com;
                master.totals.super_agent_shr_amt +=
                  super_agent.totals.super_agent_shr_amt;
                master.totals.super_agent_net_amt +=
                  super_agent.totals.super_agent_net_amt;
                master.totals.super_agent_mob_app +=
                  super_agent.totals.super_agent_mob_app;
                master.totals.super_agent_final +=
                  super_agent.totals.super_agent_final;
                master.totals.master_m_com += super_agent.totals.master_m_com;
                master.totals.master_s_com += super_agent.totals.master_s_com;
                master.totals.master_t_com += super_agent.totals.master_t_com;
                master.totals.master_shr_amt +=
                  super_agent.totals.master_shr_amt;
                master.totals.master_net_amt +=
                  super_agent.totals.master_net_amt;
                master.totals.master_mob_app +=
                  super_agent.totals.master_mob_app;
                master.totals.master_final += super_agent.totals.master_final;
                master.totals.sub_admin_m_com +=
                  super_agent.totals.sub_admin_m_com;
                master.totals.sub_admin_s_com +=
                  super_agent.totals.sub_admin_s_com;
                master.totals.sub_admin_t_com +=
                  super_agent.totals.sub_admin_t_com;
                master.totals.sub_admin_shr_amt +=
                  super_agent.totals.sub_admin_shr_amt;
                master.totals.sub_admin_net_amt +=
                  super_agent.totals.sub_admin_net_amt;
                master.totals.sub_admin_mob_app +=
                  super_agent.totals.sub_admin_mob_app;
                master.totals.sub_admin_final +=
                  super_agent.totals.sub_admin_final;
                master.totals.super_admin_m_com +=
                  super_agent.totals.super_admin_m_com;
                master.totals.super_admin_s_com +=
                  super_agent.totals.super_admin_s_com;
                master.totals.super_admin_t_com +=
                  super_agent.totals.super_admin_t_com;
                master.totals.super_admin_shr_amt +=
                  super_agent.totals.super_admin_shr_amt;
                master.totals.super_admin_net_amt +=
                  super_agent.totals.super_admin_net_amt;
                master.totals.super_admin_mob_app +=
                  super_agent.totals.super_admin_mob_app;
                master.totals.super_admin_final +=
                  super_agent.totals.super_admin_final;
              });
            });
          });

          resp.forEach((el) => {
            if (!el.totals) {
              el.totals = {
                m_amt: 0,
                s_amt: 0,
                t_amt: 0,
                m_com: 0,
                s_com: 0,
                c_com: 0,
                net_amt: 0,
                c_mob: 0,
                final: 0,

                agent_m_com: 0,
                agent_s_com: 0,
                agent_t_com: 0,
                agent_net_amt: 0,
                agent_shr_amt: 0,
                agent_mob_app: 0,
                agent_final: 0,

                super_agent_m_com: 0,
                super_agent_s_com: 0,
                super_agent_t_com: 0,
                super_agent_shr_amt: 0,
                super_agent_net_amt: 0,
                super_agent_mob_app: 0,
                super_agent_final: 0,

                master_m_com: 0,
                master_s_com: 0,
                master_t_com: 0,
                master_shr_amt: 0,
                master_net_amt: 0,
                master_mob_app: 0,
                master_final: 0,

                sub_admin_m_com: 0,
                sub_admin_s_com: 0,
                sub_admin_t_com: 0,
                sub_admin_shr_amt: 0,
                sub_admin_net_amt: 0,
                sub_admin_mob_app: 0,
                sub_admin_final: 0,

                super_admin_m_com: 0,
                super_admin_s_com: 0,
                super_admin_t_com: 0,
                super_admin_shr_amt: 0,
                super_admin_net_amt: 0,
                super_admin_mob_app: 0,
                super_admin_final: 0,
              };
            }

            el.master.forEach((master) => {
              el.totals.m_amt += master.totals.m_amt;
              el.totals.s_amt += master.totals.s_amt;
              el.totals.t_amt += master.totals.t_amt;
              el.totals.m_com += master.totals.m_com;
              el.totals.s_com += master.totals.s_com;
              el.totals.c_com += master.totals.c_com;
              el.totals.net_amt += master.totals.net_amt;
              el.totals.c_mob += master.totals.c_mob;
              el.totals.final += master.totals.final;
              el.totals.agent_m_com += master.totals.agent_m_com;
              el.totals.agent_s_com += master.totals.agent_s_com;
              el.totals.agent_t_com += master.totals.agent_t_com;
              el.totals.agent_net_amt += master.totals.agent_net_amt;
              el.totals.agent_shr_amt += master.totals.agent_shr_amt;
              el.totals.agent_mob_app += master.totals.agent_mob_app;
              el.totals.agent_final += master.totals.agent_final;
              el.totals.super_agent_m_com += master.totals.super_agent_m_com;
              el.totals.super_agent_s_com += master.totals.super_agent_s_com;
              el.totals.super_agent_t_com += master.totals.super_agent_t_com;
              el.totals.super_agent_shr_amt +=
                master.totals.super_agent_shr_amt;
              el.totals.super_agent_net_amt +=
                master.totals.super_agent_net_amt;
              el.totals.super_agent_mob_app +=
                master.totals.super_agent_mob_app;
              el.totals.super_agent_final += master.totals.super_agent_final;
              el.totals.master_m_com += master.totals.master_m_com;
              el.totals.master_s_com += master.totals.master_s_com;
              el.totals.master_t_com += master.totals.master_t_com;
              el.totals.master_shr_amt += master.totals.master_shr_amt;
              el.totals.master_net_amt += master.totals.master_net_amt;
              el.totals.master_mob_app += master.totals.master_mob_app;
              el.totals.master_final += master.totals.master_final;
              el.totals.sub_admin_m_com += master.totals.sub_admin_m_com;
              el.totals.sub_admin_s_com += master.totals.sub_admin_s_com;
              el.totals.sub_admin_t_com += master.totals.sub_admin_t_com;
              el.totals.sub_admin_shr_amt += master.totals.sub_admin_shr_amt;
              el.totals.sub_admin_net_amt += master.totals.sub_admin_net_amt;
              el.totals.sub_admin_mob_app += master.totals.sub_admin_mob_app;
              el.totals.sub_admin_final += master.totals.sub_admin_final;
              el.totals.super_admin_m_com += master.totals.super_admin_m_com;
              el.totals.super_admin_s_com += master.totals.super_admin_s_com;
              el.totals.super_admin_t_com += master.totals.super_admin_t_com;
              el.totals.super_admin_shr_amt +=
                master.totals.super_admin_shr_amt;
              el.totals.super_admin_net_amt +=
                master.totals.super_admin_net_amt;
              el.totals.super_admin_mob_app +=
                master.totals.super_admin_mob_app;
              el.totals.super_admin_final += master.totals.super_admin_final;
            });
          });

          if (!resp.totals) {
            resp.totals = {
              m_amt: 0,
              s_amt: 0,
              t_amt: 0,
              m_com: 0,
              s_com: 0,
              c_com: 0,
              net_amt: 0,
              c_mob: 0,
              final: 0,

              agent_m_com: 0,
              agent_s_com: 0,
              agent_t_com: 0,
              agent_net_amt: 0,
              agent_shr_amt: 0,
              agent_mob_app: 0,
              agent_final: 0,

              super_agent_m_com: 0,
              super_agent_s_com: 0,
              super_agent_t_com: 0,
              super_agent_shr_amt: 0,
              super_agent_net_amt: 0,
              super_agent_mob_app: 0,
              super_agent_final: 0,

              master_m_com: 0,
              master_s_com: 0,
              master_t_com: 0,
              master_shr_amt: 0,
              master_net_amt: 0,
              master_mob_app: 0,
              master_final: 0,

              sub_admin_m_com: 0,
              sub_admin_s_com: 0,
              sub_admin_t_com: 0,
              sub_admin_shr_amt: 0,
              sub_admin_net_amt: 0,
              sub_admin_mob_app: 0,
              sub_admin_final: 0,

              super_admin_m_com: 0,
              super_admin_s_com: 0,
              super_admin_t_com: 0,
              super_admin_shr_amt: 0,
              super_admin_net_amt: 0,
              super_admin_mob_app: 0,
              super_admin_final: 0,
            };
          }

          resp.forEach((sub) => {
            resp.totals.m_amt += sub.totals.m_amt;
            resp.totals.s_amt += sub.totals.s_amt;
            resp.totals.t_amt += sub.totals.t_amt;
            resp.totals.m_com += sub.totals.m_com;
            resp.totals.s_com += sub.totals.s_com;
            resp.totals.c_com += sub.totals.c_com;
            resp.totals.net_amt += sub.totals.net_amt;
            resp.totals.c_mob += sub.totals.c_mob;
            resp.totals.final += sub.totals.final;
            resp.totals.agent_m_com += sub.totals.agent_m_com;
            resp.totals.agent_s_com += sub.totals.agent_s_com;
            resp.totals.agent_t_com += sub.totals.agent_t_com;
            resp.totals.agent_net_amt += sub.totals.agent_net_amt;
            resp.totals.agent_shr_amt += sub.totals.agent_shr_amt;
            resp.totals.agent_mob_app += sub.totals.agent_mob_app;
            resp.totals.agent_final += sub.totals.agent_final;
            resp.totals.super_agent_m_com += sub.totals.super_agent_m_com;
            resp.totals.super_agent_s_com += sub.totals.super_agent_s_com;
            resp.totals.super_agent_t_com += sub.totals.super_agent_t_com;
            resp.totals.super_agent_shr_amt += sub.totals.super_agent_shr_amt;
            resp.totals.super_agent_net_amt += sub.totals.super_agent_net_amt;
            resp.totals.super_agent_mob_app += sub.totals.super_agent_mob_app;
            resp.totals.super_agent_final += sub.totals.super_agent_final;
            resp.totals.master_m_com += sub.totals.master_m_com;
            resp.totals.master_s_com += sub.totals.master_s_com;
            resp.totals.master_t_com += sub.totals.master_t_com;
            resp.totals.master_shr_amt += sub.totals.master_shr_amt;
            resp.totals.master_net_amt += sub.totals.master_net_amt;
            resp.totals.master_mob_app += sub.totals.master_mob_app;
            resp.totals.master_final += sub.totals.master_final;
            resp.totals.sub_admin_m_com += sub.totals.sub_admin_m_com;
            resp.totals.sub_admin_s_com += sub.totals.sub_admin_s_com;
            resp.totals.sub_admin_t_com += sub.totals.sub_admin_t_com;
            resp.totals.sub_admin_shr_amt += sub.totals.sub_admin_shr_amt;
            resp.totals.sub_admin_net_amt += sub.totals.sub_admin_net_amt;
            resp.totals.sub_admin_mob_app += sub.totals.sub_admin_mob_app;
            resp.totals.sub_admin_final += sub.totals.sub_admin_final;
            resp.totals.super_admin_m_com += sub.totals.super_admin_m_com;
            resp.totals.super_admin_s_com += sub.totals.super_admin_s_com;
            resp.totals.super_admin_t_com += sub.totals.super_admin_t_com;
            resp.totals.super_admin_shr_amt += sub.totals.super_admin_shr_amt;
            resp.totals.super_admin_net_amt += sub.totals.super_admin_net_amt;
            resp.totals.super_admin_mob_app += sub.totals.super_admin_mob_app;
            resp.totals.super_admin_final += sub.totals.super_admin_final;
          });

          setTimeout(() => {
            console.log(resp);
            setFinalReport(resp);
          }, 1000);
        } else if (accountType === "sub_admin") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined && teamWon === "B") {
              m_amt = el.bettingData[0].total_TeamB;
            } else {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length != 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;
            let m_comm = 0;
            let client_m_com = 0;
            let s_comm = 0;

            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let mob_charge = 0;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }
            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let client_net_amt = m_amt + s_amt - client_t_amt;

            let client_final =
              m_amt +
              s_amt -
              client_t_amt +
              (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;

            agent_net_amt = s_amt + m_amt - agent_t_com;
            let agent_shr_amount = (el.parents.share / 100) * agent_net_amt;

            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            if (super_agent_t_com < 0) {
              super_agent_net_amt = s_amt + m_amt + super_agent_t_com;
            } else {
              super_agent_net_amt = s_amt + m_amt - super_agent_t_com;
            }
            super_agent_net_amt = s_amt + m_amt - super_agent_t_com;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = s_amt + m_amt - master_t_com;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            let sub_admin_t_com =
              sub_admin_m_com +
              (s_amt_tot *
                el.parents.parents.parents.parents.session_commission) /
                100;
            let sub_admin_net_amt = 0;
            sub_admin_net_amt = s_amt + m_amt - sub_admin_t_com;
            let sub_admin_shr_amount =
              (sub_admin_net_amt * el.parents.parents.parents.parents.share) /
              100;
            let sub_admin_final = sub_admin_net_amt - sub_admin_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
              sub_admin: `(${el.parents.parents.parents.parents.code}) ${el.parents.parents.parents.parents.name}`,
              sub_admin_id: el.parents.parents.parents.parents.id,
              sub_admin_m_com: master_m_com,
              sub_admin_s_com:
                (s_amt_tot *
                  el.parents.parents.parents.parents.session_commission) /
                100,
              sub_admin_t_com: sub_admin_t_com,
              sub_admin_shr_amt: sub_admin_shr_amount,
              sub_admin_net_amt: sub_admin_net_amt,
              sub_admin_mob_app: mob_charge,
              sub_admin_final: sub_admin_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.master_id)) {
              resp.push({
                id: el.master_id,
                name: el.master,
                super_agent: [],
              });
            }
          });

          result__.forEach((el) => {
            const master_index = resp.findIndex((em) => em.id === el.master_id);
            if (master_index !== -1) {
              if (
                !resp[master_index].super_agent.some(
                  (eb) => eb.id === el.super_agent_id
                )
              ) {
                resp[master_index].super_agent.push({
                  id: el.super_agent_id,
                  name: el.super_agent,
                  agent: [],
                });
              }
            }
          });

          result__.forEach((el) => {
            const master_index = resp.findIndex((em) => em.id === el.master_id);
            if (master_index !== -1) {
              const agent_index = resp[master_index].super_agent.findIndex(
                (em) => em.id === el.super_agent_id
              );
              if (agent_index !== -1) {
                if (
                  !resp[master_index].super_agent[agent_index].agent.some(
                    (eb) => eb.id === el.agent_id
                  )
                ) {
                  resp[master_index].super_agent[agent_index].agent.push({
                    id: el.agent_id,
                    name: el.agent,
                    client: [],
                  });
                }
              }
            }
          });

          result__.forEach((el) => {
            const master_index = resp.findIndex((em) => em.id === el.master_id);
            if (master_index !== -1) {
              const agent_index = resp[master_index].super_agent.findIndex(
                (em) => em.id === el.super_agent_id
              );
              if (agent_index !== -1) {
                const client_index = resp[master_index].super_agent[
                  agent_index
                ].agent.findIndex((em) => em.id === el.agent_id);
                if (client_index !== -1) {
                  if (
                    !resp[master_index].super_agent[agent_index].agent[
                      client_index
                    ].client.some((eb) => eb.id === el.agent_id)
                  ) {
                    resp[master_index].super_agent[agent_index].agent[
                      client_index
                    ].client.push(el);
                  }

                  const agent =
                    resp[master_index].super_agent[agent_index].agent[
                      client_index
                    ];

                  let agent_totals = {
                    m_amt: 0,
                    s_amt: 0,
                    t_amt: 0,
                    m_com: 0,
                    s_com: 0,
                    c_com: 0,
                    net_amt: 0,
                    c_mob: 0,
                    final: 0,

                    agent_m_com: 0,
                    agent_s_com: 0,
                    agent_t_com: 0,
                    agent_net_amt: 0,
                    agent_shr_amt: 0,
                    agent_mob_app: 0,
                    agent_final: 0,

                    super_agent_m_com: 0,
                    super_agent_s_com: 0,
                    super_agent_t_com: 0,
                    super_agent_shr_amt: 0,
                    super_agent_net_amt: 0,
                    super_agent_mob_app: 0,
                    super_agent_final: 0,

                    master_m_com: 0,
                    master_s_com: 0,
                    master_t_com: 0,
                    master_shr_amt: 0,
                    master_net_amt: 0,
                    master_mob_app: 0,
                    master_final: 0,

                    sub_admin_m_com: 0,
                    sub_admin_s_com: 0,
                    sub_admin_t_com: 0,
                    sub_admin_shr_amt: 0,
                    sub_admin_net_amt: 0,
                    sub_admin_mob_app: 0,
                    sub_admin_final: 0,
                  };

                  agent.client.forEach((el) => {
                    agent_totals.m_amt += el.m_amt;
                    agent_totals.s_amt += el.s_amt;
                    agent_totals.t_amt += el.t_amt;
                    agent_totals.m_com += el.m_com;
                    agent_totals.s_com += el.s_com;
                    agent_totals.c_com += el.c_com;
                    agent_totals.net_amt += el.net_amt;
                    agent_totals.c_mob += el.c_mob;
                    agent_totals.final += el.final;
                    agent_totals.agent_m_com += el.agent_m_com;
                    agent_totals.agent_s_com += el.agent_s_com;
                    agent_totals.agent_t_com += el.agent_t_com;
                    agent_totals.agent_net_amt += el.agent_net_amt;
                    agent_totals.agent_shr_amt += el.agent_shr_amt;
                    agent_totals.agent_mob_app += el.agent_mob_app;
                    agent_totals.agent_final += el.agent_final;
                    agent_totals.super_agent_m_com += el.super_agent_m_com;
                    agent_totals.super_agent_s_com += el.super_agent_s_com;
                    agent_totals.super_agent_t_com += el.super_agent_t_com;
                    agent_totals.super_agent_shr_amt += el.super_agent_shr_amt;
                    agent_totals.super_agent_net_amt += el.super_agent_net_amt;
                    agent_totals.super_agent_mob_app += el.super_agent_mob_app;
                    agent_totals.super_agent_final += el.super_agent_final;
                    agent_totals.master_m_com += el.master_m_com;
                    agent_totals.master_s_com += el.master_s_com;
                    agent_totals.master_t_com += el.master_t_com;
                    agent_totals.master_shr_amt += el.master_shr_amt;
                    agent_totals.master_net_amt += el.master_net_amt;
                    agent_totals.master_mob_app += el.master_mob_app;
                    agent_totals.master_final += el.master_final;
                    agent_totals.sub_admin_m_com += el.sub_admin_m_com;
                    agent_totals.sub_admin_s_com += el.sub_admin_s_com;
                    agent_totals.sub_admin_t_com += el.sub_admin_t_com;
                    agent_totals.sub_admin_shr_amt += el.sub_admin_shr_amt;
                    agent_totals.sub_admin_net_amt += el.sub_admin_net_amt;
                    agent_totals.sub_admin_mob_app += el.sub_admin_mob_app;
                    agent_totals.sub_admin_final += el.sub_admin_final;
                  });

                  agent.totals = agent_totals;
                }
              }
            }
          });

          resp.forEach((el) => {
            el.super_agent.forEach((super_agent) => {
              if (!super_agent.totals) {
                super_agent.totals = {
                  m_amt: 0,
                  s_amt: 0,
                  t_amt: 0,
                  m_com: 0,
                  s_com: 0,
                  c_com: 0,
                  net_amt: 0,
                  c_mob: 0,
                  final: 0,

                  agent_m_com: 0,
                  agent_s_com: 0,
                  agent_t_com: 0,
                  agent_net_amt: 0,
                  agent_shr_amt: 0,
                  agent_mob_app: 0,
                  agent_final: 0,

                  super_agent_m_com: 0,
                  super_agent_s_com: 0,
                  super_agent_t_com: 0,
                  super_agent_shr_amt: 0,
                  super_agent_net_amt: 0,
                  super_agent_mob_app: 0,
                  super_agent_final: 0,

                  master_m_com: 0,
                  master_s_com: 0,
                  master_t_com: 0,
                  master_shr_amt: 0,
                  master_net_amt: 0,
                  master_mob_app: 0,
                  master_final: 0,

                  sub_admin_m_com: 0,
                  sub_admin_s_com: 0,
                  sub_admin_t_com: 0,
                  sub_admin_shr_amt: 0,
                  sub_admin_net_amt: 0,
                  sub_admin_mob_app: 0,
                  sub_admin_final: 0,
                };
              }

              super_agent.agent.forEach((agent) => {
                super_agent.totals.m_amt += agent.totals.m_amt;
                super_agent.totals.s_amt += agent.totals.s_amt;
                super_agent.totals.t_amt += agent.totals.t_amt;
                super_agent.totals.m_com += agent.totals.m_com;
                super_agent.totals.s_com += agent.totals.s_com;
                super_agent.totals.c_com += agent.totals.c_com;
                super_agent.totals.net_amt += agent.totals.net_amt;
                super_agent.totals.c_mob += agent.totals.c_mob;
                super_agent.totals.final += agent.totals.final;
                super_agent.totals.agent_m_com += agent.totals.agent_m_com;
                super_agent.totals.agent_s_com += agent.totals.agent_s_com;
                super_agent.totals.agent_t_com += agent.totals.agent_t_com;
                super_agent.totals.agent_net_amt += agent.totals.agent_net_amt;
                super_agent.totals.agent_shr_amt += agent.totals.agent_shr_amt;
                super_agent.totals.agent_mob_app += agent.totals.agent_mob_app;
                super_agent.totals.agent_final += agent.totals.agent_final;
                super_agent.totals.super_agent_m_com +=
                  agent.totals.super_agent_m_com;
                super_agent.totals.super_agent_s_com +=
                  agent.totals.super_agent_s_com;
                super_agent.totals.super_agent_t_com +=
                  agent.totals.super_agent_t_com;
                super_agent.totals.super_agent_shr_amt +=
                  agent.totals.super_agent_shr_amt;
                super_agent.totals.super_agent_net_amt +=
                  agent.totals.super_agent_net_amt;
                super_agent.totals.super_agent_mob_app +=
                  agent.totals.super_agent_mob_app;
                super_agent.totals.super_agent_final +=
                  agent.totals.super_agent_final;
                super_agent.totals.master_m_com += agent.totals.master_m_com;
                super_agent.totals.master_s_com += agent.totals.master_s_com;
                super_agent.totals.master_t_com += agent.totals.master_t_com;
                super_agent.totals.master_shr_amt +=
                  agent.totals.master_shr_amt;
                super_agent.totals.master_net_amt +=
                  agent.totals.master_net_amt;
                super_agent.totals.master_mob_app +=
                  agent.totals.master_mob_app;
                super_agent.totals.master_final += agent.totals.master_final;
                super_agent.totals.sub_admin_m_com +=
                  agent.totals.sub_admin_m_com;
                super_agent.totals.sub_admin_s_com +=
                  agent.totals.sub_admin_s_com;
                super_agent.totals.sub_admin_t_com +=
                  agent.totals.sub_admin_t_com;
                super_agent.totals.sub_admin_shr_amt +=
                  agent.totals.sub_admin_shr_amt;
                super_agent.totals.sub_admin_net_amt +=
                  agent.totals.sub_admin_net_amt;
                super_agent.totals.sub_admin_mob_app +=
                  agent.totals.sub_admin_mob_app;
                super_agent.totals.sub_admin_final +=
                  agent.totals.sub_admin_final;
              });
            });
          });

          resp.forEach((el) => {
            if (!el.totals) {
              el.totals = {
                m_amt: 0,
                s_amt: 0,
                t_amt: 0,
                m_com: 0,
                s_com: 0,
                c_com: 0,
                net_amt: 0,
                c_mob: 0,
                final: 0,

                agent_m_com: 0,
                agent_s_com: 0,
                agent_t_com: 0,
                agent_net_amt: 0,
                agent_shr_amt: 0,
                agent_mob_app: 0,
                agent_final: 0,

                super_agent_m_com: 0,
                super_agent_s_com: 0,
                super_agent_t_com: 0,
                super_agent_shr_amt: 0,
                super_agent_net_amt: 0,
                super_agent_mob_app: 0,
                super_agent_final: 0,

                master_m_com: 0,
                master_s_com: 0,
                master_t_com: 0,
                master_shr_amt: 0,
                master_net_amt: 0,
                master_mob_app: 0,
                master_final: 0,

                sub_admin_m_com: 0,
                sub_admin_s_com: 0,
                sub_admin_t_com: 0,
                sub_admin_shr_amt: 0,
                sub_admin_net_amt: 0,
                sub_admin_mob_app: 0,
                sub_admin_final: 0,
              };
            }

            el.super_agent.forEach((master) => {
              el.totals.m_amt += master.totals.m_amt;
              el.totals.s_amt += master.totals.s_amt;
              el.totals.t_amt += master.totals.t_amt;
              el.totals.m_com += master.totals.m_com;
              el.totals.s_com += master.totals.s_com;
              el.totals.c_com += master.totals.c_com;
              el.totals.net_amt += master.totals.net_amt;
              el.totals.c_mob += master.totals.c_mob;
              el.totals.final += master.totals.final;
              el.totals.agent_m_com += master.totals.agent_m_com;
              el.totals.agent_s_com += master.totals.agent_s_com;
              el.totals.agent_t_com += master.totals.agent_t_com;
              el.totals.agent_net_amt += master.totals.agent_net_amt;
              el.totals.agent_shr_amt += master.totals.agent_shr_amt;
              el.totals.agent_mob_app += master.totals.agent_mob_app;
              el.totals.agent_final += master.totals.agent_final;
              el.totals.super_agent_m_com += master.totals.super_agent_m_com;
              el.totals.super_agent_s_com += master.totals.super_agent_s_com;
              el.totals.super_agent_t_com += master.totals.super_agent_t_com;
              el.totals.super_agent_shr_amt +=
                master.totals.super_agent_shr_amt;
              el.totals.super_agent_net_amt +=
                master.totals.super_agent_net_amt;
              el.totals.super_agent_mob_app +=
                master.totals.super_agent_mob_app;
              el.totals.super_agent_final += master.totals.super_agent_final;
              el.totals.master_m_com += master.totals.master_m_com;
              el.totals.master_s_com += master.totals.master_s_com;
              el.totals.master_t_com += master.totals.master_t_com;
              el.totals.master_shr_amt += master.totals.master_shr_amt;
              el.totals.master_net_amt += master.totals.master_net_amt;
              el.totals.master_mob_app += master.totals.master_mob_app;
              el.totals.master_final += master.totals.master_final;
              el.totals.sub_admin_m_com += master.totals.sub_admin_m_com;
              el.totals.sub_admin_s_com += master.totals.sub_admin_s_com;
              el.totals.sub_admin_t_com += master.totals.sub_admin_t_com;
              el.totals.sub_admin_shr_amt += master.totals.sub_admin_shr_amt;
              el.totals.sub_admin_net_amt += master.totals.sub_admin_net_amt;
              el.totals.sub_admin_mob_app += master.totals.sub_admin_mob_app;
              el.totals.sub_admin_final += master.totals.sub_admin_final;
            });
          });

          if (!resp.totals) {
            resp.totals = {
              m_amt: 0,
              s_amt: 0,
              t_amt: 0,
              m_com: 0,
              s_com: 0,
              c_com: 0,
              net_amt: 0,
              c_mob: 0,
              final: 0,

              agent_m_com: 0,
              agent_s_com: 0,
              agent_t_com: 0,
              agent_net_amt: 0,
              agent_shr_amt: 0,
              agent_mob_app: 0,
              agent_final: 0,

              super_agent_m_com: 0,
              super_agent_s_com: 0,
              super_agent_t_com: 0,
              super_agent_shr_amt: 0,
              super_agent_net_amt: 0,
              super_agent_mob_app: 0,
              super_agent_final: 0,

              master_m_com: 0,
              master_s_com: 0,
              master_t_com: 0,
              master_shr_amt: 0,
              master_net_amt: 0,
              master_mob_app: 0,
              master_final: 0,

              sub_admin_m_com: 0,
              sub_admin_s_com: 0,
              sub_admin_t_com: 0,
              sub_admin_shr_amt: 0,
              sub_admin_net_amt: 0,
              sub_admin_mob_app: 0,
              sub_admin_final: 0,
            };
          }

          resp.forEach((super_agent) => {
            resp.totals.m_amt += super_agent.totals.m_amt;
            resp.totals.s_amt += super_agent.totals.s_amt;
            resp.totals.t_amt += super_agent.totals.t_amt;
            resp.totals.m_com += super_agent.totals.m_com;
            resp.totals.s_com += super_agent.totals.s_com;
            resp.totals.c_com += super_agent.totals.c_com;
            resp.totals.net_amt += super_agent.totals.net_amt;
            resp.totals.c_mob += super_agent.totals.c_mob;
            resp.totals.final += super_agent.totals.final;
            resp.totals.agent_m_com += super_agent.totals.agent_m_com;
            resp.totals.agent_s_com += super_agent.totals.agent_s_com;
            resp.totals.agent_t_com += super_agent.totals.agent_t_com;
            resp.totals.agent_net_amt += super_agent.totals.agent_net_amt;
            resp.totals.agent_shr_amt += super_agent.totals.agent_shr_amt;
            resp.totals.agent_mob_app += super_agent.totals.agent_mob_app;
            resp.totals.agent_final += super_agent.totals.agent_final;
            resp.totals.super_agent_m_com +=
              super_agent.totals.super_agent_m_com;
            resp.totals.super_agent_s_com +=
              super_agent.totals.super_agent_s_com;
            resp.totals.super_agent_t_com +=
              super_agent.totals.super_agent_t_com;
            resp.totals.super_agent_shr_amt +=
              super_agent.totals.super_agent_shr_amt;
            resp.totals.super_agent_net_amt +=
              super_agent.totals.super_agent_net_amt;
            resp.totals.super_agent_mob_app +=
              super_agent.totals.super_agent_mob_app;
            resp.totals.super_agent_final +=
              super_agent.totals.super_agent_final;
            resp.totals.master_m_com += super_agent.totals.master_m_com;
            resp.totals.master_s_com += super_agent.totals.master_s_com;
            resp.totals.master_t_com += super_agent.totals.master_t_com;
            resp.totals.master_shr_amt += super_agent.totals.master_shr_amt;
            resp.totals.master_net_amt += super_agent.totals.master_net_amt;
            resp.totals.master_mob_app += super_agent.totals.master_mob_app;
            resp.totals.master_final += super_agent.totals.master_final;
            resp.totals.sub_admin_m_com += super_agent.totals.sub_admin_m_com;
            resp.totals.sub_admin_s_com += super_agent.totals.sub_admin_s_com;
            resp.totals.sub_admin_t_com += super_agent.totals.sub_admin_t_com;
            resp.totals.sub_admin_shr_amt +=
              super_agent.totals.sub_admin_shr_amt;
            resp.totals.sub_admin_net_amt +=
              super_agent.totals.sub_admin_net_amt;
            resp.totals.sub_admin_mob_app +=
              super_agent.totals.sub_admin_mob_app;
            resp.totals.sub_admin_final += super_agent.totals.sub_admin_final;
          });

          setTimeout(() => {
            console.log(resp);
            setFinalReport(resp);
          }, 1000);
        } else if (accountType === "master") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined && teamWon === "B") {
              m_amt = el.bettingData[0].total_TeamB;
            } else {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length != 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;
            let client_m_com = 0;
            let m_comm = 0;
            let s_comm = 0;
            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let mob_charge = 0;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }
            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let client_net_amt = m_amt + s_amt - client_t_amt;

            let client_final =
              m_amt +
              s_amt -
              client_t_amt +
              (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;
            agent_net_amt = m_amt + s_amt - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;

            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            super_agent_net_amt = m_amt + s_amt - super_agent_t_com;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            let master_t_com =
              master_m_com +
              (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
            let master_net_amt = 0;
            master_net_amt = m_amt + s_amt - master_t_com;
            let master_shr_amount =
              (master_net_amt * el.parents.parents.parents.share) / 100;
            let master_final = master_net_amt - master_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
              master: `(${el.parents.parents.parents.code}) ${el.parents.parents.parents.name}`,
              master_id: el.parents.parents.parents.id,
              master_m_com: master_m_com,
              master_s_com:
                (s_amt_tot * el.parents.parents.parents.session_commission) /
                100,
              master_t_com: master_t_com,
              master_shr_amt: master_shr_amount,
              master_net_amt: master_net_amt,
              master_mob_app: mob_charge,
              master_final: master_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.super_agent_id)) {
              resp.push({
                id: el.super_agent_id,
                name: el.super_agent,
                agent: [],
              });
            }
          });

          result__.forEach((el) => {
            const agent_index = resp.findIndex(
              (em) => em.id === el.super_agent_id
            );
            if (agent_index !== -1) {
              if (
                !resp[agent_index].agent.some((eb) => eb.id === el.agent_id)
              ) {
                resp[agent_index].agent.push({
                  id: el.agent_id,
                  name: el.agent,
                  client: [],
                });
              }
            }
          });

          result__.forEach((el) => {
            const agent_index = resp.findIndex(
              (em) => em.id === el.super_agent_id
            );
            if (agent_index !== -1) {
              const client_index = resp[agent_index].agent.findIndex(
                (em) => em.id === el.agent_id
              );
              if (client_index !== -1) {
                if (
                  !resp[agent_index].agent[client_index].client.some(
                    (eb) => eb.id === el.agent_id
                  )
                ) {
                  resp[agent_index].agent[client_index].client.push(el);
                }

                const agent = resp[agent_index].agent[client_index];

                let agent_totals = {
                  m_amt: 0,
                  s_amt: 0,
                  t_amt: 0,
                  m_com: 0,
                  s_com: 0,
                  c_com: 0,
                  net_amt: 0,
                  c_mob: 0,
                  final: 0,

                  agent_m_com: 0,
                  agent_s_com: 0,
                  agent_t_com: 0,
                  agent_net_amt: 0,
                  agent_shr_amt: 0,
                  agent_mob_app: 0,
                  agent_final: 0,

                  super_agent_m_com: 0,
                  super_agent_s_com: 0,
                  super_agent_t_com: 0,
                  super_agent_shr_amt: 0,
                  super_agent_net_amt: 0,
                  super_agent_mob_app: 0,
                  super_agent_final: 0,

                  master_m_com: 0,
                  master_s_com: 0,
                  master_t_com: 0,
                  master_shr_amt: 0,
                  master_net_amt: 0,
                  master_mob_app: 0,
                  master_final: 0,
                };

                agent.client.forEach((el) => {
                  agent_totals.m_amt += el.m_amt;
                  agent_totals.s_amt += el.s_amt;
                  agent_totals.t_amt += el.t_amt;
                  agent_totals.m_com += el.m_com;
                  agent_totals.s_com += el.s_com;
                  agent_totals.c_com += el.c_com;
                  agent_totals.net_amt += el.net_amt;
                  agent_totals.c_mob += el.c_mob;
                  agent_totals.final += el.final;
                  agent_totals.agent_m_com += el.agent_m_com;
                  agent_totals.agent_s_com += el.agent_s_com;
                  agent_totals.agent_t_com += el.agent_t_com;
                  agent_totals.agent_net_amt += el.agent_net_amt;
                  agent_totals.agent_shr_amt += el.agent_shr_amt;
                  agent_totals.agent_mob_app += el.agent_mob_app;
                  agent_totals.agent_final += el.agent_final;
                  agent_totals.super_agent_m_com += el.super_agent_m_com;
                  agent_totals.super_agent_s_com += el.super_agent_s_com;
                  agent_totals.super_agent_t_com += el.super_agent_t_com;
                  agent_totals.super_agent_shr_amt += el.super_agent_shr_amt;
                  agent_totals.super_agent_net_amt += el.super_agent_net_amt;
                  agent_totals.super_agent_mob_app += el.super_agent_mob_app;
                  agent_totals.super_agent_final += el.super_agent_final;
                  agent_totals.master_m_com += el.master_m_com;
                  agent_totals.master_s_com += el.master_s_com;
                  agent_totals.master_t_com += el.master_t_com;
                  agent_totals.master_shr_amt += el.master_shr_amt;
                  agent_totals.master_net_amt += el.master_net_amt;
                  agent_totals.master_mob_app += el.master_mob_app;
                  agent_totals.master_final += el.master_final;
                });

                agent.totals = agent_totals;
              }
            }
          });

          resp.forEach((el) => {
            if (!el.totals) {
              el.totals = {
                m_amt: 0,
                s_amt: 0,
                t_amt: 0,
                m_com: 0,
                s_com: 0,
                c_com: 0,
                net_amt: 0,
                c_mob: 0,
                final: 0,

                agent_m_com: 0,
                agent_s_com: 0,
                agent_t_com: 0,
                agent_net_amt: 0,
                agent_shr_amt: 0,
                agent_mob_app: 0,
                agent_final: 0,

                super_agent_m_com: 0,
                super_agent_s_com: 0,
                super_agent_t_com: 0,
                super_agent_shr_amt: 0,
                super_agent_net_amt: 0,
                super_agent_mob_app: 0,
                super_agent_final: 0,

                master_m_com: 0,
                master_s_com: 0,
                master_t_com: 0,
                master_shr_amt: 0,
                master_net_amt: 0,
                master_mob_app: 0,
                master_final: 0,
              };
            }

            el.agent.forEach((master) => {
              el.totals.m_amt += master.totals.m_amt;
              el.totals.s_amt += master.totals.s_amt;
              el.totals.t_amt += master.totals.t_amt;
              el.totals.m_com += master.totals.m_com;
              el.totals.s_com += master.totals.s_com;
              el.totals.c_com += master.totals.c_com;
              el.totals.net_amt += master.totals.net_amt;
              el.totals.c_mob += master.totals.c_mob;
              el.totals.final += master.totals.final;
              el.totals.agent_m_com += master.totals.agent_m_com;
              el.totals.agent_s_com += master.totals.agent_s_com;
              el.totals.agent_t_com += master.totals.agent_t_com;
              el.totals.agent_net_amt += master.totals.agent_net_amt;
              el.totals.agent_shr_amt += master.totals.agent_shr_amt;
              el.totals.agent_mob_app += master.totals.agent_mob_app;
              el.totals.agent_final += master.totals.agent_final;
              el.totals.super_agent_m_com += master.totals.super_agent_m_com;
              el.totals.super_agent_s_com += master.totals.super_agent_s_com;
              el.totals.super_agent_t_com += master.totals.super_agent_t_com;
              el.totals.super_agent_shr_amt +=
                master.totals.super_agent_shr_amt;
              el.totals.super_agent_net_amt +=
                master.totals.super_agent_net_amt;
              el.totals.super_agent_mob_app +=
                master.totals.super_agent_mob_app;
              el.totals.super_agent_final += master.totals.super_agent_final;
              el.totals.master_m_com += master.totals.master_m_com;
              el.totals.master_s_com += master.totals.master_s_com;
              el.totals.master_t_com += master.totals.master_t_com;
              el.totals.master_shr_amt += master.totals.master_shr_amt;
              el.totals.master_net_amt += master.totals.master_net_amt;
              el.totals.master_mob_app += master.totals.master_mob_app;
              el.totals.master_final += master.totals.master_final;
            });
          });

          if (!resp.totals) {
            resp.totals = {
              m_amt: 0,
              s_amt: 0,
              t_amt: 0,
              m_com: 0,
              s_com: 0,
              c_com: 0,
              net_amt: 0,
              c_mob: 0,
              final: 0,

              agent_m_com: 0,
              agent_s_com: 0,
              agent_t_com: 0,
              agent_net_amt: 0,
              agent_shr_amt: 0,
              agent_mob_app: 0,
              agent_final: 0,

              super_agent_m_com: 0,
              super_agent_s_com: 0,
              super_agent_t_com: 0,
              super_agent_shr_amt: 0,
              super_agent_net_amt: 0,
              super_agent_mob_app: 0,
              super_agent_final: 0,

              master_m_com: 0,
              master_s_com: 0,
              master_t_com: 0,
              master_shr_amt: 0,
              master_net_amt: 0,
              master_mob_app: 0,
              master_final: 0,
            };
          }

          resp.forEach((agent) => {
            resp.totals.m_amt += agent.totals.m_amt;
            resp.totals.s_amt += agent.totals.s_amt;
            resp.totals.t_amt += agent.totals.t_amt;
            resp.totals.m_com += agent.totals.m_com;
            resp.totals.s_com += agent.totals.s_com;
            resp.totals.c_com += agent.totals.c_com;
            resp.totals.net_amt += agent.totals.net_amt;
            resp.totals.c_mob += agent.totals.c_mob;
            resp.totals.final += agent.totals.final;
            resp.totals.agent_m_com += agent.totals.agent_m_com;
            resp.totals.agent_s_com += agent.totals.agent_s_com;
            resp.totals.agent_t_com += agent.totals.agent_t_com;
            resp.totals.agent_net_amt += agent.totals.agent_net_amt;
            resp.totals.agent_shr_amt += agent.totals.agent_shr_amt;
            resp.totals.agent_mob_app += agent.totals.agent_mob_app;
            resp.totals.agent_final += agent.totals.agent_final;
            resp.totals.super_agent_m_com += agent.totals.super_agent_m_com;
            resp.totals.super_agent_s_com += agent.totals.super_agent_s_com;
            resp.totals.super_agent_t_com += agent.totals.super_agent_t_com;
            resp.totals.super_agent_shr_amt += agent.totals.super_agent_shr_amt;
            resp.totals.super_agent_net_amt += agent.totals.super_agent_net_amt;
            resp.totals.super_agent_mob_app += agent.totals.super_agent_mob_app;
            resp.totals.super_agent_final += agent.totals.super_agent_final;
            resp.totals.master_m_com += agent.totals.master_m_com;
            resp.totals.master_s_com += agent.totals.master_s_com;
            resp.totals.master_t_com += agent.totals.master_t_com;
            resp.totals.master_shr_amt += agent.totals.master_shr_amt;
            resp.totals.master_net_amt += agent.totals.master_net_amt;
            resp.totals.master_mob_app += agent.totals.master_mob_app;
            resp.totals.master_final += agent.totals.master_final;
          });

          setTimeout(() => {
            console.log(resp);
            setFinalReport(resp);
          }, 1000);
        } else if (accountType === "super_agent" || accountType === "agent") {
          result.forEach((el) => {
            let m_amt = 0;

            if (teamWon !== undefined && teamWon === "A") {
              m_amt = el.bettingData[0].total_teamA;
            } else if (teamWon !== undefined && teamWon === "B") {
              m_amt = el.bettingData[0].total_TeamB;
            } else {
              m_amt = 0;
            }

            let s_amt_tot =
              el.SessoinData.length != 0
                ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                    return a + c;
                  })
                : 0;

            let s_amt = 0;
            if (el.SessoinData.length != 0) {
              el.SessoinData.forEach((ele) => {
                if (ele.declare != null) {
                  if (ele.rate < 1) {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount * ele.rate;
                    } else {
                      s_amt = s_amt - ele.amount;
                    }
                  } else {
                    if (ele.won) {
                      s_amt = s_amt + ele.amount;
                    } else {
                      s_amt = s_amt - ele.amount * ele.rate;
                    }
                  }
                }
              });
            }

            if (
              (m_amt === null || m_amt === 0) &&
              s_amt == 0 &&
              s_amt_tot == 0
            ) {
              return;
            }

            s_amt = s_amt * -1;

            let t_amt = 0;

            let c_com = 0;
            let agent_m_com = 0;
            let super_agent_m_com = 0;
            let master_m_com = 0;
            let client_m_com = 0;
            let m_comm = 0;
            let s_comm = 0;
            let sub_admin_m_com = 0;
            let super_admin_m_com = 0;
            let mob_charge = 0;
            if (m_amt !== 0) {
              m_amt = m_amt * -1;
            }
            if (m_amt > 0) {
              c_com =
                (m_amt * el.match_commission) / 100 +
                (s_amt_tot * el.session_commission) / 100;
              agent_m_com = (m_amt * el.parents.match_commission) / 100;
              super_agent_m_com =
                (m_amt * el.parents.parents.match_commission) / 100;
              client_m_com = (m_amt * el.match_commission) / 100;
              m_comm = (m_amt * el.match_commission) / 100;
              s_comm = (m_amt * el.session_commission) / 100;
              master_m_com =
                (m_amt * el.parents.parents.parents.match_commission) / 100;
              sub_admin_m_com =
                (m_amt * el.parents.parents.parents.parents.match_commission) /
                100;
              super_admin_m_com =
                (m_amt *
                  el.parents.parents.parents.parents.parents.match_commission) /
                100;
            }

            let client_t_amt =
              client_m_com + (s_amt_tot * el.session_commission) / 100;

            let client_net_amt = m_amt + s_amt - client_t_amt;

            let client_final =
              m_amt +
              s_amt -
              client_t_amt +
              (mob_charge * el.mobile_share) / 100;

            let agent_t_com =
              agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
            let agent_net_amt = 0;

            agent_net_amt = s_amt + m_amt - agent_t_com;
            let agent_shr_amount =
              el.parents.share !== 0
                ? (el.parents.share / 100) * agent_net_amt
                : 0;

            let agent_final = agent_net_amt - agent_shr_amount;

            let super_agent_t_com =
              super_agent_m_com +
              (s_amt_tot * el.parents.parents.session_commission) / 100;
            let super_agent_net_amt = 0;
            super_agent_net_amt = s_amt + m_amt - super_agent_t_com;
            let super_agent_shr_amount =
              (super_agent_net_amt * el.parents.parents.share) / 100;
            let super_agent_final =
              super_agent_net_amt - super_agent_shr_amount;

            result__.push({
              client: `(${el.code}) ${el.name}`,
              client_id: el.id,
              m_amt: m_amt,
              s_amt: s_amt,
              t_amt: m_amt + s_amt,
              m_com: m_comm,
              s_com: (s_amt_tot * el.session_commission) / 100,
              c_com: client_t_amt,
              net_amt: client_net_amt,
              c_mob: (mob_charge * el.mobile_share) / 100,
              final: client_final,
              agent: `(${el.parents.code}) ${el.parents.name}`,
              agent_id: el.parents.id,
              agent_m_com: agent_m_com,
              agent_s_com: (s_amt_tot * el.parents.session_commission) / 100,
              agent_t_com: agent_t_com,
              agent_net_amt: agent_net_amt,
              agent_shr_amt: agent_shr_amount,
              agent_mob_app: mob_charge,
              agent_final: agent_final,
              super_agent: `(${el.parents.parents.code}) ${el.parents.parents.name}`,
              super_agent_id: el.parents.parents.id,
              super_agent_m_com: super_agent_m_com,
              super_agent_s_com:
                (s_amt_tot * el.parents.parents.session_commission) / 100,
              super_agent_t_com: super_agent_t_com,
              super_agent_shr_amt: super_agent_shr_amount,
              super_agent_net_amt: super_agent_net_amt,
              super_agent_mob_app: mob_charge,
              super_agent_final: super_agent_final,
            });
          });

          result__.forEach((el) => {
            if (!resp.some((em) => em.id === el.agent_id)) {
              resp.push({
                id: el.agent_id,
                name: el.agent,
                client: [],
              });
            }
          });

          result__.forEach((el) => {
            const agent_index = resp.findIndex((em) => em.id === el.agent_id);
            if (agent_index != -1) {
              if (
                !resp[agent_index].client.some((eb) => eb.id === el.client_id)
              ) {
                resp[agent_index].client.push(el);
              }

              const agent = resp[agent_index];

              let agent_totals = {
                m_amt: 0,
                s_amt: 0,
                t_amt: 0,
                m_com: 0,
                s_com: 0,
                c_com: 0,
                net_amt: 0,
                c_mob: 0,
                final: 0,

                agent_m_com: 0,
                agent_s_com: 0,
                agent_t_com: 0,
                agent_net_amt: 0,
                agent_shr_amt: 0,
                agent_mob_app: 0,
                agent_final: 0,

                super_agent_m_com: 0,
                super_agent_s_com: 0,
                super_agent_t_com: 0,
                super_agent_shr_amt: 0,
                super_agent_net_amt: 0,
                super_agent_mob_app: 0,
                super_agent_final: 0,
              };

              agent.client.forEach((el) => {
                agent_totals.m_amt += el.m_amt;
                agent_totals.s_amt += el.s_amt;
                agent_totals.t_amt += el.t_amt;
                agent_totals.m_com += el.m_com;
                agent_totals.s_com += el.s_com;
                agent_totals.c_com += el.c_com;
                agent_totals.net_amt += el.net_amt;
                agent_totals.c_mob += el.c_mob;
                agent_totals.final += el.final;
                agent_totals.agent_m_com += el.agent_m_com;
                agent_totals.agent_s_com += el.agent_s_com;
                agent_totals.agent_t_com += el.agent_t_com;
                agent_totals.agent_net_amt += el.agent_net_amt;
                agent_totals.agent_shr_amt += el.agent_shr_amt;
                agent_totals.agent_mob_app += el.agent_mob_app;
                agent_totals.agent_final += el.agent_final;
                agent_totals.super_agent_m_com += el.super_agent_m_com;
                agent_totals.super_agent_s_com += el.super_agent_s_com;
                agent_totals.super_agent_t_com += el.super_agent_t_com;
                agent_totals.super_agent_shr_amt += el.super_agent_shr_amt;
                agent_totals.super_agent_net_amt += el.super_agent_net_amt;
                agent_totals.super_agent_mob_app += el.super_agent_mob_app;
                agent_totals.super_agent_final += el.super_agent_final;
              });

              agent.totals = agent_totals;
            }
          });

          if (!resp.totals) {
            resp.totals = {
              m_amt: 0,
              s_amt: 0,
              t_amt: 0,
              m_com: 0,
              s_com: 0,
              c_com: 0,
              net_amt: 0,
              c_mob: 0,
              final: 0,

              agent_m_com: 0,
              agent_s_com: 0,
              agent_t_com: 0,
              agent_net_amt: 0,
              agent_shr_amt: 0,
              agent_mob_app: 0,
              agent_final: 0,

              super_agent_m_com: 0,
              super_agent_s_com: 0,
              super_agent_t_com: 0,
              super_agent_shr_amt: 0,
              super_agent_net_amt: 0,
              super_agent_mob_app: 0,
              super_agent_final: 0,
            };
          }

          resp.forEach((agent) => {
            resp.totals.m_amt += agent.totals.m_amt;
            resp.totals.s_amt += agent.totals.s_amt;
            resp.totals.t_amt += agent.totals.t_amt;
            resp.totals.m_com += agent.totals.m_com;
            resp.totals.s_com += agent.totals.s_com;
            resp.totals.c_com += agent.totals.c_com;
            resp.totals.net_amt += agent.totals.net_amt;
            resp.totals.c_mob += agent.totals.c_mob;
            resp.totals.final += agent.totals.final;
            resp.totals.agent_m_com += agent.totals.agent_m_com;
            resp.totals.agent_s_com += agent.totals.agent_s_com;
            resp.totals.agent_t_com += agent.totals.agent_t_com;
            resp.totals.agent_net_amt += agent.totals.agent_net_amt;
            resp.totals.agent_shr_amt += agent.totals.agent_shr_amt;
            resp.totals.agent_mob_app += agent.totals.agent_mob_app;
            resp.totals.agent_final += agent.totals.agent_final;
            resp.totals.super_agent_m_com += agent.totals.super_agent_m_com;
            resp.totals.super_agent_s_com += agent.totals.super_agent_s_com;
            resp.totals.super_agent_t_com += agent.totals.super_agent_t_com;
            resp.totals.super_agent_shr_amt += agent.totals.super_agent_shr_amt;
            resp.totals.super_agent_net_amt += agent.totals.super_agent_net_amt;
            resp.totals.super_agent_mob_app += agent.totals.super_agent_mob_app;
            resp.totals.super_agent_final += agent.totals.super_agent_final;
          });

          setTimeout(() => {
            console.log(resp);
            setFinalReport(resp);
          }, 1000);
        }
      },
      onError: (error) => {
        toast.error("Something went wrong", error);
      },
    });
  };

  useEffect(() => {
    getCompanyReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match & Session Plus Minus Report</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/complete-games">Complete Games</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match & Session Plus Minus Report</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card long-table-11">
              <div
                className="card-header bg-white"
                style={{
                  width:
                    finalReport.length > 0
                      ? accountType === "admin"
                        ? "5000px"
                        : accountType === "super_admin"
                        ? "4000px"
                        : accountType === "sub_admin"
                        ? "3000px"
                        : "2000px"
                      : "inherit",
                }}
              >
                <h5 className="mb-0">MATCH CODE : {matchString}</h5>
              </div>

              <div
                className="card-body bg-white"
                style={{
                  width:
                    finalReport.length > 0
                      ? accountType === "admin"
                        ? "5000px"
                        : accountType === "super_admin"
                        ? "4000px"
                        : accountType === "sub_admin"
                        ? "3000px"
                        : "2000px"
                      : "inherit",
                }}
              >
                {finalReport.length > 0 ? (
                  accountType === "admin" ? (
                    <AdminCard
                      finalReport={finalReport}
                      userCode={userCode}
                      userName={userName}
                    />
                  ) : accountType === "super_admin" ? (
                    <SuperAdminCard
                      finalReport={finalReport}
                      userCode={userCode}
                      userName={userName}
                    />
                  ) : accountType === "sub_admin" ? (
                    <SubAdminCard
                      finalReport={finalReport}
                      userCode={userCode}
                      userName={userName}
                    />
                  ) : accountType === "master" ? (
                    <MasterCard
                      finalReport={finalReport}
                      userCode={userCode}
                      userName={userName}
                    />
                  ) : accountType === "super_agent" ? (
                    <SuperAgentCard
                      finalReport={finalReport}
                      userCode={userCode}
                      userName={userName}
                    />
                  ) : (
                    <AgentCard finalReport={finalReport} />
                  )
                ) : (
                  <div className="alert alert-danger">No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
