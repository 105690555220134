/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PageHeader from "../../Components/PageHeader";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import useGetCassinoBetsTrash from "../../hooks/Games/Casino/useGetCassinoBetsTrash";
import useRevertCassinoBet from "../../hooks/Games/Casino/useRevertCassinoBet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

export default function CasinoBetReportTrash() {
  const loggedAccType = localStorage.getItem("account");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");

  const { mutate: getCassinoBetsTrash } = useGetCassinoBetsTrash();
  const { mutate: revertCassinoBet, isLoading: isRevertingCassinoBet } =
    useRevertCassinoBet();

  const [betsData, setBetsData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const trashPayload = {
      logged_acc_type: loggedAccType,
    };

    getCassinoBetsTrash(trashPayload, {
      onSuccess: (response) => {
        const data = response.data.data.sort((a, b) => {
          return +new Date(b.updatedAt) - +new Date(a.updatedAt);
        });

        let filteredData = [];

        if (isMasterAdmin !== "true" && isMasterAdmin !== "1") {
          filteredData = data.filter((item) => {
            return item.deletedBy !== "AD94244";
          });
        } else {
          filteredData = data;
        }

        setBetsData(filteredData);
        setCompleteData(filteredData);
      },
      onError: (error) => {
        toast.error("No data found!");
        console.error(error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalPages = Math.ceil(betsData?.length / itemsPerPage);
  const middlePagesToShow = 3;
  const pageNumbers = [];

  if (totalPages <= middlePagesToShow + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= Math.ceil(middlePagesToShow / 2) + 1) {
    for (let i = 1; i <= middlePagesToShow + 1; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - Math.floor(middlePagesToShow / 2)) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = totalPages - middlePagesToShow; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - Math.floor(middlePagesToShow / 2);
      i <= currentPage + Math.floor(middlePagesToShow / 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = betsData.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber === 1) {
      setIsFirstPage(true);
    } else if (pageNumber === Math.ceil(betsData?.length / itemsPerPage)) {
      setIsLastPage(true);
    } else {
      setIsFirstPage(false);
      setIsLastPage(false);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(betsData?.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 === Math.ceil(betsData?.length / itemsPerPage)
      );
    }
  };

  const handleRevertBet = (id, mid, user_id) => {
    const payload = {
      logged_acc_type: loggedAccType,
      bet_id: id,
      mid: mid,
      user_id: user_id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: `You want to revert this bet?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, revert it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@9ex") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        revertCassinoBet(payload, {
          onSuccess: (response) => {
            console.log(response.data);
            toast.success("Bet reverted successfully");
            window.location.reload();
          },
          onError: (error) => {
            toast.error(error?.response?.data?.message || "An error occurred");
            console.error(error);
          },
        });
      }
    });
  };

  return (
    <div className="content-wrapper">
      <PageHeader title={"Casino Bet Report"} />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="bg-yellow">
                      <tr>
                        <th>#</th>
                        <th>Client</th>
                        <th>GameName</th>
                        <th>Round Id</th>
                        <th>Player</th>
                        <th>Rate</th>
                        <th>Amount</th>
                        <th>Result</th>
                        <th>Date</th>
                        <th>Deleted By</th>
                        {loggedAccType === "admin" ? <th>Action</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {betsData && betsData?.length !== 0 ? (
                        currentItems?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.user_account.code} {item.user_account.name}
                            </td>
                            <td>{item.cassino_game.name}</td>
                            <td>{item.mid}</td>
                            <td>{item.nation}</td>
                            <td>{item.rate}</td>
                            <td>{item.amount}</td>
                            <td>{item.result}</td>
                            <td>{dateTimeFormatter(item.updatedAt)}</td>
                            <td>{item.deletedBy}</td>
                            {loggedAccType === "admin" &&
                            (item.ledger_status === false ||
                              item.ledger_status === 0) ? (
                              <td>
                                <button
                                  className="btn btn-sm btn-warning"
                                  disabled={isRevertingCassinoBet}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRevertBet(
                                      item.id,
                                      item.mid,
                                      item.user_id
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faRotateLeft} />
                                </button>
                              </td>
                            ) : (
                              <td>Added to Ledger</td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr className="bg-white">
                          <td colSpan="11">
                            <h6 className="text-center">
                              There are no records to display
                            </h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData?.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Previous
                          </a>
                        </li>
                        {pageNumbers?.map((number, index) => (
                          <li key={index} className="page-item">
                            <a
                              onClick={() => {
                                if (number !== "...") {
                                  paginate(number);
                                }
                              }}
                              className={`page-link ${
                                currentPage === number ? "active-page-link" : ""
                              } ${number === "..." ? "disabled" : ""}`}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {number}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
