/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useGetAccount from "../../hooks/useGetAccount";
import useUpdateAccount from "../../hooks/useUpdateAccount";

export default function UpdateMaster() {
  const accountType = localStorage.getItem("account");

  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;
  const { mutate: getAccount } = useGetAccount();
  const { mutate: updateAccount } = useUpdateAccount();
  const [parentData, setParentData] = useState([]);

  const [masterName, setMasterName] = useState("");
  const [masterNumber, setMasterNumber] = useState("");
  const [masterPassword, setMasterPassword] = useState("");
  const [masterReference, setMasterReference] = useState("");
  const [masterLimit, setMasterLimit] = useState(0);
  const [masterShare, setMasterShare] = useState(0);
  const [masterCasinoShare, setMasterCasinoShare] = useState(0);
  const [masterMobileShare, setMasterMobileShare] = useState(0);
  const [masterCommission, setMasterCommission] = useState("");
  const [masterMatchCommission, setMasterMatchCommission] = useState(0);
  const [masterSessionCommission, setMasterSessionCommission] = useState(0);
  const [masterCassinoCommission, setMasterCassinoCommission] = useState(0);
  const [clientCassinoStatus, setClientCassinoStatus] = useState(false);

  useEffect(() => {
    console.log(userData);

    setMasterName(userData.name);
    setMasterNumber(userData.mobile);
    setMasterPassword(userData.password);
    setMasterReference(userData.reference);
    setMasterLimit(userData.limit);
    setMasterShare(userData.share);
    setMasterCasinoShare(userData.cassino_share);
    setMasterMobileShare(userData.mobile_share);
    setMasterCommission(userData.commission);
    setMasterMatchCommission(userData.match_commission);
    setMasterSessionCommission(userData.session_commission);
    setMasterCassinoCommission(userData.cassino_commission);
    setClientCassinoStatus(userData.cassino_status === 1 ? true : false);

    const payload = {
      acc_type: "sub_admin",
      user_id: userData.parent,
    };

    getAccount(payload, {
      onSuccess: (response) => {
        setParentData(response.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, [userData]);

  const generateNewPassword = () => {
    setMasterPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const userId = userData.id;

    const payload = {
      acc_type: "master",
      id: userId,
      code: userData.code,
      reference: masterReference,
      name: masterName,
      mobile: masterNumber,
      password: String(masterPassword),
      parent: userData.parent,
      share: masterShare,
      cassino_share: masterCasinoShare,
      mobile_share: masterMobileShare,
      match_commission: masterMatchCommission,
      commission: masterCommission,
      session_commission: masterSessionCommission,
      cassino_commission: masterCassinoCommission,
      // cassino_status: clientCassinoStatus,
    };

    updateAccount(
      { userId, payload },
      {
        onSuccess: () => {
          toast.success("Master Updated Successfully");
          navigate("/masters");
        },
        onError: (error) => {
          console.log(error);
          toast.error("Master Update Failed");
        },
      }
    );
  }

  function handleCancel(e) {
    e.preventDefault();
    navigate("/masters");
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Master </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/masters">Master</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Edit Master</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="masterName"
                            name="masterName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            value={masterName}
                            onChange={(e) => {
                              setMasterName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="masterNumber">
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="masterNumber"
                            name="masterNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            value={masterNumber}
                            onChange={(e) => {
                              setMasterNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="masterReference"
                            name="masterReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            value={masterReference}
                            onChange={(e) => {
                              setMasterReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="masterPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="masterPassword"
                              name="masterPassword"
                              className="form-control"
                              required
                              value={masterPassword}
                              onChange={(e) => {
                                setMasterPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Master Limit</label>
                            <input
                              type="number"
                              id="masterLimit"
                              name="masterLimit"
                              className="form-control"
                              value={masterLimit}
                              disabled
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Master Share</label>
                            <input
                              type="number"
                              id="masterShare"
                              name="masterShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.share}
                              step="0.01"
                              required
                              value={masterShare}
                              disabled={accountType !== "admin"}
                              onChange={(e) => {
                                setMasterShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Casino Share
                            </label>
                            <input
                              type="number"
                              id="masterCasinoShare"
                              name="masterCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_share}
                              step="0.01"
                              required
                              value={masterCasinoShare}
                              disabled={accountType !== "admin"}
                              onChange={(e) => {
                                setMasterCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select
                              className="form-select"
                              id="clientCassinoStatus"
                              name="clientCassinoStatus"
                              required
                              value={clientCassinoStatus}
                              disabled={true}
                              onChange={(e) => {
                                if (e.target.value === "true") {
                                  setClientCassinoStatus(true);
                                } else {
                                  setClientCassinoStatus(false);
                                }
                              }}
                            >
                              <option value={false}>OFF</option>
                              <option
                                value={true}
                                disabled={parentData?.cassino_status === 0}
                              >
                                ON
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="masterCommission"
                              name="masterCommission"
                              required
                              value={masterCommission}
                              onChange={(e) => {
                                setMasterCommission(e.target.value);
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Match Commission
                            </label>
                            <input
                              type="number"
                              id="masterMatchCommission"
                              name="masterMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.match_commission}
                              required
                              value={masterMatchCommission}
                              onChange={(e) => {
                                setMasterMatchCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Session Commission
                            </label>
                            <input
                              type="number"
                              id="masterSessionCommission"
                              name="masterSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.session_commission}
                              required
                              value={masterSessionCommission}
                              onChange={(e) => {
                                setMasterSessionCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Master Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="masterCassinoCommission"
                              name="masterCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_commission}
                              required
                              value={masterCassinoCommission}
                              onChange={(e) => {
                                setMasterCassinoCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Edit Master
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
