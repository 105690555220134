/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export default function CollectionList() {
  const navigate = useNavigate();

  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(currentPage + 1 === Math.ceil(data.length / itemsPerPage));
    }
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleUserSelect = (e, userId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers(data.map((item) => item.id));
    } else {
      setSelectedUsers([]);
    }

    setSelectAll(isChecked);
  };

  const showResponsiveCols = (action, item) => {
    let existingElement = document.getElementById(`row${item.code}`);

    let showBtn = document.getElementById(`showBtn${item.code}`);
    let hideBtn = document.getElementById(`hideBtn${item.code}`);

    if (existingElement) {
      if (action == "show") {
        let tr = document.createElement("tr");
        tr.setAttribute("id", `showRow${item.code}`);

        let tdTitle = document.createElement("td");
        tdTitle.setAttribute("colspan", "2");
        tdTitle.style.borderRight = "none";

        let tdValue = document.createElement("td");
        tdValue.setAttribute("colspan", "10");
        tdValue.style.borderLeft = "none";

        let title1 = document.createElement("div");
        title1.classList.add("dataTitle");
        title1.textContent = "Status: ";

        let value1 = document.createElement("div");
        value1.classList.add("dataValue");
        let statusSpan = document.createElement("span");
        statusSpan.textContent = item.status ? "Active" : "Inactive";
        if (item.status) {
          statusSpan.classList.add("active_bg");
        } else {
          statusSpan.classList.add("inactive_bg");
        }
        value1.appendChild(statusSpan);

        let title2 = document.createElement("div");
        title2.classList.add("dataTitle");
        title2.textContent = "Limit: ";

        let value2 = document.createElement("div");
        value2.classList.add("dataValue");
        value2.textContent = item.limit;

        let title3 = document.createElement("div");
        title3.classList.add("dataTitle");
        title3.textContent = "S. Com: ";

        let value3 = document.createElement("div");
        value3.classList.add("dataValue");
        value3.textContent = item.session_commission;

        let title4 = document.createElement("div");
        title4.classList.add("dataTitle");
        title4.textContent = "M. Com: ";

        let value4 = document.createElement("div");
        value4.classList.add("dataValue");
        value4.textContent = item.match_commission;

        let title5 = document.createElement("div");
        title5.classList.add("dataTitle");
        title5.textContent = "Share: ";

        let value5 = document.createElement("div");
        value5.classList.add("dataValue");
        value5.textContent = item.share;

        let title6 = document.createElement("div");
        title6.classList.add("dataTitle");
        title6.textContent = "Contact: ";

        let value6 = document.createElement("div");
        value6.classList.add("dataValue");
        value6.textContent = item.mobile;

        let title7 = document.createElement("div");
        title7.classList.add("dataTitle");
        title7.textContent = "Password: ";

        let value7 = document.createElement("div");
        value7.classList.add("dataValue");
        value7.textContent = item.password;

        let title8 = document.createElement("div");
        title8.classList.add("dataTitle");
        title8.textContent = "Name: ";

        let value8 = document.createElement("div");
        value8.classList.add("dataValue");
        value8.textContent = item.name;

        let title9 = document.createElement("div");
        title9.classList.add("dataTitle");
        title9.textContent = "CODE: ";

        let value9 = document.createElement("div");
        value9.classList.add("dataValue");
        value9.textContent = item.code;

        let screenWidth = window.innerWidth;

        if (screenWidth < 992) {
          tdTitle.appendChild(title1);
          tdValue.appendChild(value1);
        }

        if (screenWidth < 912) {
          tdTitle.insertBefore(title2, tdTitle.firstChild);
          tdValue.insertBefore(value2, tdValue.firstChild);
        }

        if (screenWidth < 832) {
          tdTitle.insertBefore(title3, tdTitle.firstChild);
          tdValue.insertBefore(value3, tdValue.firstChild);
        }

        if (screenWidth < 752) {
          tdTitle.insertBefore(title4, tdTitle.firstChild);
          tdValue.insertBefore(value4, tdValue.firstChild);
        }

        if (screenWidth < 672) {
          tdTitle.insertBefore(title5, tdTitle.firstChild);
          tdValue.insertBefore(value5, tdValue.firstChild);
        }

        if (screenWidth < 592) {
          tdTitle.insertBefore(title6, tdTitle.firstChild);
          tdValue.insertBefore(value6, tdValue.firstChild);
        }

        if (screenWidth < 512) {
          tdTitle.insertBefore(title7, tdTitle.firstChild);
          tdValue.insertBefore(value7, tdValue.firstChild);
        }

        if (screenWidth < 432) {
          tdTitle.insertBefore(title8, tdTitle.firstChild);
          tdValue.insertBefore(value8, tdValue.firstChild);
        }

        if (screenWidth < 352) {
          tdTitle.insertBefore(title9, tdTitle.firstChild);
          tdValue.insertBefore(value9, tdValue.firstChild);
        }

        tr.appendChild(tdTitle);
        tr.appendChild(tdValue);

        if (existingElement.parentNode != null) {
          existingElement.parentNode.insertBefore(
            tr,
            existingElement.nextSibling
          );
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      } else {
        let row = document.getElementById(`showRow${item.code}`);
        if (row) {
          row.remove();
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      }
    }
  };

  const CreateNewCollection = () => {
    navigate("/New-collection");
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Collection Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Collection</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-primary mr-2 mt-2"
                      onClick={CreateNewCollection}
                    >
                      New{" "}
                      <i className="fa fa-plus-circle">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </i>
                    </button>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table">
                    <div className="row">
                      <div className="parent-container">
                        <div className="col-6">
                          <div
                            className="label-1 dataTables_length"
                            id="example1_length"
                          >
                            <label>
                              Show
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  setItemsPerPage(e.target.value);
                                }}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="input-container col-6 d-flex gap-2 justify-content-end">
                          <label htmlFor="search">Search:</label>
                          <input
                            type="text"
                            id="search"
                            className="form-control form-control-sm w-auto ml-0"
                            onChange={(e) => {
                              const value = e.target.value.trim();
                              if (value.length > 0) {
                                searchFilter(value);
                              } else {
                                setData(completeData);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <table
                      id="datatable"
                      className="table table-bordered table-striped dataTable no-footer dtr-inline"
                      role="grid"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr>
                          <th className="thr-control">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                          <th>#</th>
                          <th className="mobile-d-9">
                            Code<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-8">
                            Name<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-7">
                            Password<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-6">
                            Contact<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-5">
                            Share<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-4">
                            M.Com<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-3">
                            S.Com<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-2">
                            Limit<span id="sorted-icon">↑↓</span>
                          </th>
                          <th className="mobile-d-1">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.length !== 0 ? (
                          <>
                            {currentItems.map((item, index) => {
                              return (
                                <>
                                  <tr key={index} id={`row${item.code}`}>
                                    <td className="dtr-control">
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        checked={selectedUsers.includes(
                                          item.id
                                        )}
                                        onChange={(e) => {
                                          handleUserSelect(e, item.id);
                                        }}
                                      />
                                      <div
                                        className="dynamicControl showButton"
                                        id={`showBtn${item.code}`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          showResponsiveCols("show", item);
                                        }}
                                      >
                                        +
                                      </div>
                                      <div
                                        className="dynamicControl hide"
                                        id={`hideBtn${item.code}`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          showResponsiveCols("hide", item);
                                        }}
                                      >
                                        -
                                      </div>
                                    </td>
                                    <td>
                                      <span className="mr-2">{index + 1}</span>
                                      <div className="btn-group">
                                        <button
                                          style={{
                                            padding: "2px 4px",
                                            lineHeight: "1",
                                          }}
                                          type="button"
                                          className="btn btn-outline-primary dropdown-toggle"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <span className="sr-only">
                                            Toggle Dropdown
                                          </span>
                                        </button>
                                        <div
                                          className="dropdown-menu"
                                          role="menu"
                                        >
                                          <Link
                                            className="dropdown-item"
                                            to={{
                                              pathname: "/supermaster/update",
                                            }}
                                            state={item}
                                          >
                                            Edit
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              selectedUsers.push(item.id);
                                            }}
                                          >
                                            {item.status
                                              ? "Inactive"
                                              : "Activate"}
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to={{
                                              pathname:
                                                "/supermaster/child-limitUpdate",
                                            }}
                                            state={item}
                                          >
                                            Sub Admin Limit Update
                                          </Link>
                                        </div>
                                      </div>
                                    </td>

                                    <td className="mobile-d-9">{item.code}</td>
                                    <td className="mobile-d-8">{item.name}</td>
                                    <td className="mobile-d-7">
                                      {item.password}
                                    </td>
                                    <td className="mobile-d-6">
                                      {item.mobile}
                                    </td>
                                    <td className="mobile-d-5">{item.share}</td>
                                    <td className="mobile-d-4">
                                      {item.match_commission}
                                    </td>
                                    <td className="mobile-d-3">
                                      {item.session_commission}
                                    </td>
                                    <td className="mobile-d-2">{item.limit}</td>
                                    <td className="mobile-d-1">
                                      {item.status ? (
                                        <button
                                          className="btn btn-sm btn-success"
                                          id="active-1"
                                          style={{
                                            cursor: "default",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          Active
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-danger"
                                          id="active-1"
                                          style={{
                                            cursor: "default",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          Inactive
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={12} className="text-center p-5">
                                <h6 className="p-5">No records to display</h6>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            href="#"
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {Array.from({
                          length: Math.ceil(data.length / itemsPerPage),
                        }).map((_, index) => (
                          <li key={index} className="page-item">
                            <a
                              href="#"
                              onClick={() => paginate(index + 1)}
                              className={`page-link ${
                                currentPage === index + 1
                                  ? "active-page-link"
                                  : ""
                              }`}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            href="#"
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
