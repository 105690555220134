/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import useSocketBetguru from "../../hooks/Sockets/useSocketBetguru";
import useSocketIceexch from "../../hooks/Sockets/useSocketIceexch";
import useSocketPersonal from "../../hooks/Sockets/useSocketPersonal";
import useSocketMatchSettings from "../../hooks/Sockets/useSocketMatchSettings";
import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import useGetBettingAdmin from "../../hooks/Games/Inplay/useGetBettingAdmin";
import useGetSessionByName from "../../hooks/Games/Inplay/useGetSessionByName";
import { match as matchDummy, match4 } from "../../cms/gameData";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import "./InplayControlGame.css";

export default function InplayMatchSessionPosition() {
  const userData = JSON.parse(localStorage.getItem("DATA"));
  const accountType = localStorage.getItem("account");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  const userId = localStorage.getItem("UUID");

  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);
  const matchId = UrlSearchParams.get("id");

  let gameDetails = [];
  let gameId = "";
  let gameCode = "";
  let userShare = 0;
  let session_automatic = true;
  let scoreCode = "";

  if (location.state) {
    gameDetails = location.state;
    gameCode = location.state.code;
    gameId = location.state.id;
    userShare = userData.share;
    session_automatic = location.state.session_automatic;
    scoreCode = location.state.score_code;
  }

  const { mutate: getBetsData } = useGetBetsData();
  const { mutate: getBettingAdmin } = useGetBettingAdmin();
  const { mutate: getSessionByName } = useGetSessionByName();

  const [scoreBoardType, setScoreBoardType] = useState("");

  const [matchBets, setMatchBets] = useState([]);
  const [matchSession, setMatchSession] = useState([]);
  const [gameSessionSetting, setGameSessionSetting] = useState([]);
  const [totalMatch1, setTotalMatch1] = useState(0);
  const [totalMatch2, setTotalMatch2] = useState(0);
  const [totalMatch3, setTotalMatch3] = useState(0);
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [team3, setTeam3] = useState("");
  const [team1Yes, setTeam1Yes] = useState("");
  const [team1No, setTeam1No] = useState("");
  const [team2Yes, setTeam2Yes] = useState("");
  const [team2No, setTeam2No] = useState("");
  const [team3Yes, setTeam3Yes] = useState("");
  const [team3No, setTeam3No] = useState("");
  const [gameTitle, setGameTitle] = useState("");
  const [recentBall, setRecentBall] = useState("");
  const [recentBallData, setRecentBallData] = useState([]);
  const [teamName1, setTeamName1] = useState("");
  const [teamName2, setTeamName2] = useState("");
  const [teamName3, setTeamName3] = useState("");
  const [team1Status, setTeam1Status] = useState("");
  const [team2Status, setTeam2Status] = useState("");
  const [lastWicket, setLastWicket] = useState("");
  const [player1, setPlayer1] = useState("");
  const [player2, setPlayer2] = useState("");
  const [team1Position, setTeam1Position] = useState(0);
  const [team2Position, setTeam2Position] = useState(0);
  const [team3Position, setTeam3Position] = useState(0);
  const [showMatchBets, setShowMatchBets] = useState(false);
  const [showSessionBets, setShowSessionBets] = useState(false);
  const [lastBetPlayed, setLastBetPlayed] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const lastBetRuns = [];
  const [smallTableData, setSmallTableData] = useState([]);

  const [matchId1, setMatchId1] = useState(0);
  const [matchId2, setMatchId2] = useState(0);
  const [matchId4, setMatchId4] = useState(0);

  const betguruData = useSocketBetguru(matchId1);
  const iceexchData = useSocketIceexch(matchId2);
  const personalapiData = useSocketPersonal(matchId4);
  const matchSettingsDataSocket = useSocketMatchSettings(matchId);

  let fancyOdds = [];
  const [fancyOddsData, setFancyOddsData] = useState([]);

  const showUserPlayed = (matchId) => {
    const payload = {
      match_id: matchId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getBetsData(payload, {
      onSuccess: (data) => {
        setMatchBets(data.data);

        let total1 = 0;
        let total2 = 0;
        let total3 = 0;

        data.data.forEach((el) => {
          total1 = total1 + (el.teamA_bet_amount + el.teamA_lay_amount);
          total2 = total2 + (el.teamB_bet_amount + el.teamB_lay_amount);
          total3 = total3 + (el.draw_bet_amount + el.draw_lay_amount);
        });

        setTotalMatch1(Math.round(total1));
        setTotalMatch2(Math.round(total2));
        setTotalMatch3(Math.round(total3));
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const showSessionPlay = (sessionName) => {
    const payload = {
      match_id: gameId,
      session: sessionName,
      acc_type: accountType,
      user_id: userId,
    };

    getSessionByName(payload, {
      onSuccess: (data) => {
        let filteredData = [];

        if (accountType !== "admin") {
          filteredData = data.data.filter((item) => {
            return (
              (item.view === 1 || item.view === true) &&
              (item.trash === false ||
                item.trash === "false" ||
                item.trash === null)
            );
          });
        } else {
          if (isMasterAdmin === "1" || isMasterAdmin === "true") {
            filteredData = data.data;
          } else {
            filteredData = data.data.filter((item) => {
              return item.view === 1 || item.view === true;
            });
          }
        }

        setMatchSession(filteredData);

        let below_share = 0;
        let logged_in_share = 0;
        let profit = 0;

        let runsArray = [];

        if (filteredData.length > 0) {
          filteredData.forEach((el) => {
            runsArray.push(el.runs);
            runsArray.sort((a, b) => a - b);
          });

          for (
            let i = runsArray[0] - 1;
            i <= runsArray[runsArray.length - 1] + 1;
            i++
          ) {
            if (!lastBetRuns?.some((ele) => ele.runs === i)) {
              lastBetRuns.push({ runs: i, amount: 0 });
            }
          }

          filteredData.forEach((el) => {
            if (accountType === "admin") {
              logged_in_share = 100;
              below_share =
                el.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.user_super_admin.share;
            }
            if (accountType === "super_admin") {
              logged_in_share =
                el.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.user_super_admin.share;
              below_share =
                el.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.share;
            }
            if (accountType === "sub_admin") {
              logged_in_share =
                el.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.share;
              below_share =
                el.user_account.user_agent.user_super_agent.user_master.share;
            }
            if (accountType === "master") {
              logged_in_share =
                el.user_account.user_agent.user_super_agent.user_master.share;
              below_share = el.user_account.user_agent.user_super_agent.share;
            }
            if (accountType === "super_agent") {
              logged_in_share =
                el.user_account.user_agent.user_super_agent.share;
              below_share = el.user_account.user_agent.share;
            }
            if (accountType === "agent") {
              logged_in_share = el.user_account.user_agent.share;
              below_share = el.user_account.user_agent.share;
            }

            let share = logged_in_share - below_share;
            if (el.mode === "N") {
              profit = (el.amount * share) / 100;
            } else {
              profit = (el.amount * el.rate * share) / 100;
            }

            if (el.mode === "N") {
              for (
                let i = el.runs;
                i <= runsArray[runsArray.length - 1] + 1;
                i++
              ) {
                // eslint-disable-next-line no-loop-func
                lastBetRuns.forEach((element) => {
                  if (element.runs === i) {
                    element.amount = element.amount + profit;
                  }
                });
              }

              for (let i = el.runs - 1; i >= runsArray[0] - 1; i--) {
                // eslint-disable-next-line no-loop-func
                lastBetRuns.forEach((element) => {
                  if (element.runs === i) {
                    element.amount = element.amount - profit;
                  }
                });
              }
            } else {
              for (
                let i = el.runs;
                i <= runsArray[runsArray.length - 1] + 1;
                i++
              ) {
                // eslint-disable-next-line no-loop-func
                lastBetRuns.forEach((element) => {
                  if (element.runs === i) {
                    element.amount = element.amount - profit;
                  }
                });
              }

              for (let i = el.runs - 1; i >= runsArray[0] - 1; i--) {
                // eslint-disable-next-line no-loop-func
                lastBetRuns.forEach((element) => {
                  if (element.runs === i) {
                    element.amount = element.amount + profit;
                  }
                });
              }
            }
          });

          setLastBetPlayed(filteredData[0].runs);
          const runs = filteredData.reduce((a, b) => ({
            amount: a.amount + b.amount,
          })).amount;

          setTotalAmount(
            runs -
              (runs * Number(filteredData[0].user_account.session_commission)) /
                100
          );

          setSmallTableData(lastBetRuns);
        } else {
          setLastBetPlayed(0);
          setTotalAmount(0);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

    showUserPlayed(gameId);
  };

  const handleMatchSessionSettingsData = (data) => {
    const gameDetails = data.gameDetails;
    const sessionSettings = data.sessionSettings;

    if (gameDetails === null || gameDetails === undefined) {
      toast.error("Game details not found.");
      return;
    }

    if (gameDetails !== null) {
      setGameSessionSetting(sessionSettings);
    }
  };

  const getGameDetails = (data) => {
    setTeam3("DRAW");

    if (data.length > 0) {
      if (gameDetails.match_type !== "Test") {
        setTeamName1(data[0].markets[0].runners[0].runnerName);
        setTeamName2(data[0].markets[0].runners[1].runnerName);
        setTeam1Yes(
          data[0].markets[0].runners[0].backOdds > 99 ||
            data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].backOdds + ""
        );
        setTeam2Yes(
          data[0].markets[0].runners[1].backOdds > 99 ||
            data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].backOdds + ""
        );
        setTeam1No(
          data[0].markets[0].runners[0].layOdds > 99 ||
            data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].layOdds + ""
        );
        setTeam2No(
          data[0].markets[0].runners[1].layOdds > 99 ||
            data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].layOdds + ""
        );
      }
    }

    // if (gameDetails.match_type === "Test") {
    //   setTeam3Yes(
    //     data[0].markets[0].runners[2].backOdds > 99
    //       ? "0"
    //       : data[0].markets[0].runners[2].backOdds
    //   );
    //   setTeam3No(
    //     data[0].markets[0].runners[2].layOdds > 99
    //       ? "0"
    //       : data[0].markets[0].runners[2].layOdds
    //   );
    // }

    fancyOdds = [];
    let fancyOddsIndex = data.length < 3 ? 1 : 2;

    if (gameDetails.match_type !== "Test") {
      if (session_automatic === true) {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });
        }

        gameSessionSetting.forEach((ele) => {
          if (
            fancyOdds.some(
              (elm) => elm.marketType == ele.session_name && ele.allow
            )
          ) {
            const index = fancyOdds.findIndex(
              (elen) => elen.marketType === ele.session_name
            );
            fancyOdds[index].runners[0].rnrBallRunning = true;
          }
        });
      } else {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    } else {
      if (session_automatic === true) {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });

          gameSessionSetting.forEach((ele) => {
            if (
              fancyOdds.some(
                (elm) => elm.marketType === ele.session_name && ele.allow
              )
            ) {
              const index = fancyOdds.findIndex(
                (elen) => elen.marketType === ele.session_name
              );
              fancyOdds[index].runners[0].rnrBallRunning = true;
            }
          });
        }
      } else {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    }

    setFancyOddsData(fancyOdds);
  };

  const getGameDetails3 = (data) => {
    setTeam3("DRAW");

    setTeam1Yes(
      data.market[0].events[0].BackPrice1 === "0.00" ||
        data.market[0].events[0].BackPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[0].BackPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[0].BackPrice1.split(".")[1])
    );

    setTeam1No(
      data.market[0].events[0].LayPrice1 === "0.00" ||
        data.market[0].events[0].LayPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[0].LayPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[0].LayPrice1.split(".")[1])
    );

    setTeam2Yes(
      data.market[0].events[1].BackPrice1 === "0.00" ||
        data.market[0].events[1].BackPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[1].BackPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[1].BackPrice1.split(".")[1])
    );

    setTeam2No(
      data.market[0].events[1].LayPrice1 === "0.00" ||
        data.market[0].events[1].LayPrice1 === "0.0"
        ? "0"
        : "0." +
            (data.market[0].events[1].LayPrice1.split(".").length === 1
              ? "0"
              : data.market[0].events[1].LayPrice1.split(".")[1])
    );

    if (gameDetails.match_type === "Test") {
      setTeam3Yes(
        data.market[0].events[2].BackPrice1 === "0.00" ||
          data.market[0].events[2].BackPrice1 === "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[2].BackPrice1.split(".").length === 1
                ? "0"
                : data.market[0].events[2].BackPrice1.split(".")[1])
      );

      setTeam3No(
        data.market[0].events[2].LayPrice1 === "0.00" ||
          data.market[0].events[2].LayPrice1 === "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[2].LayPrice1.split(".").length === 1
                ? "0"
                : data.market[0].events[2].LayPrice1.split(".")[1])
      );
    }

    fancyOdds = [];

    if (session_automatic) {
      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) === 0,
          },
        ];
        fancyOdds.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      gameSessionSetting.forEach((ele) => {
        if (
          fancyOdds.some(
            (elm) => elm.marketType === ele.session_name && ele.allow
          )
        ) {
          const index = fancyOdds.findIndex(
            (elen) => elen.marketType === ele.session_name
          );
          fancyOdds[index].runners[0].rnrBallRunning = true;
        }
      });
    } else {
      fancyOdds = [];
      let allSessions = [];

      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) === 0,
          },
        ];

        allSessions.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      allSessions.forEach((el) => {
        if (el.runners !== undefined) {
          if (
            gameSessionSetting.some(
              (elem) =>
                elem.session_name === el.marketType && elem.allow === true
            )
          ) {
            fancyOdds.push(el);
          }
        }
      });
    }

    setFancyOddsData(fancyOdds);
  };

  const getGameDetails2 = (data) => {
    if (Object.keys(data).length > 0 && Object.keys(data).length !== 4) {
      let match = data.matchOdds;
      let session = data.sessionOdds;
      let score = data.score;

      if (match.length > 0) {
        setTeam1Yes(match[0].krate);
        setTeam1No(match[0].lrate);
        setTeam2Yes(match[1].krate);
        setTeam2No(match[1].lrate);

        if (gameDetails.match_type === "Test") {
          setTeam3Yes(match[2].krate);
          setTeam3No(match[2].lrate);
        }
      }

      setGameTitle(score?.msg);
      setRecentBall(score?.recentBall);
      let arr = score?.recentBall.split(" ");
      let arrFixed = new Array(6).fill(null).map((_, i) => arr[i] || "-");
      setRecentBallData(arrFixed);
      setTeamName1(score?.team1);
      setTeamName2(score?.team2);
      setTeamName3("DRAW");
      setTeam1Status(score?.score1);
      setTeam2Status(score?.score2);
      setLastWicket(score?.bwl);
      setPlayer1(score?.player1);
      setPlayer2(score?.player2);

      let fancyOdds = [];

      if (session.length > 0) {
        if (session_automatic === true) {
          session.forEach((el) => {
            let newSession = {
              id: el.id,
              marketType: el.name,
              runners: [
                {
                  backRuns: el.yrun,
                  backOdds: el.yrate * 100,
                  layRuns: el.nrun,
                  layOdds: el.nrate * 100,
                  rnrBallRunning: el.yrate === 0 ? true : false,
                  id: el.id,
                },
              ],
            };

            fancyOdds.push(newSession);
          });
        } else {
          session.forEach((el) => {
            if (
              gameSessionSetting.some(
                (elem) => elem.session_name === el.name && elem.allow === true
              )
            ) {
              let newSession = {
                id: el.id,
                marketType: el.name,
                runners: [
                  {
                    backRuns: el.yrun,
                    backOdds: el.yrate * 100,
                    layRuns: el.nrun,
                    layOdds: el.nrate * 100,
                    rnrBallRunning: el.yrate === 0 ? true : false,
                    id: el.id,
                  },
                ],
              };

              fancyOdds.push(newSession);
            }
          });
        }
      }

      setFancyOddsData(fancyOdds);
    }
  };

  const getGameDetails4 = (data) => {
    let match = [];
    if (data.bookMakerOdds.length > 0) {
      match = data.bookMakerOdds[0].bm1;
    }
    let session = data.fancyOdds;

    if (match?.oddDatas?.length > 0) {
      setTeam1Yes(
        parseFloat(match.oddDatas[0].b1) > 99
          ? 0
          : parseFloat(match.oddDatas[0].b1)
      );
      setTeam1No(
        parseFloat(match.oddDatas[0].l1) > 99
          ? 0
          : parseFloat(match.oddDatas[0].l1)
      );
      setTeam2Yes(
        parseFloat(match.oddDatas[1].b1) > 99
          ? 0
          : parseFloat(match.oddDatas[1].b1)
      );
      setTeam2No(
        parseFloat(match.oddDatas[1].l1) > 99
          ? 0
          : parseFloat(match.oddDatas[1].l1)
      );

      if (gameDetails.match_type === "Test") {
        setTeam3No(
          parseFloat(match.oddDatas[2].l1) > 99
            ? 0
            : parseFloat(match.oddDatas[2].l1)
        );

        setTeam3Yes(
          parseFloat(match.oddDatas[2].b1) > 99
            ? 0
            : parseFloat(match.oddDatas[2].b1)
        );
      }

      setTeamName1(match.oddDatas[0].rname);
      setTeamName2(match.oddDatas[1].rname);
    }

    let fancyOdds = [];

    if (session.length > 0) {
      if (session_automatic === true) {
        for (let i = 0; i < session.length; i++) {
          if (session[i].gtype === "Ball By Ball") {
            continue;
          } else {
            session[i].oddDatas.forEach((el) => {
              let newSession = {
                id: el.sid,
                marketType: el.rname,
                remark: el.remark,
                runners: [
                  {
                    backRuns: Math.round(parseFloat(el.b1)),
                    backOdds: parseFloat(el.bs1),
                    layRuns: Math.round(parseFloat(el.l1)),
                    layOdds: parseFloat(el.ls1),
                    rnrBallRunning: parseFloat(el.b1) === 0 ? true : false,
                    id: el.sid,
                  },
                ],
              };

              if (String(el.rname).endsWith("2")) {
                if (gameDetails.match_type === "Test") {
                  fancyOdds.push(newSession);
                } else {
                  return;
                }
              } else if (String(el.rname).endsWith("3")) {
                if (gameDetails.match_type === "Test") {
                  fancyOdds.push(newSession);
                } else {
                  return;
                }
              } else if (String(el.rname).includes(".")) {
                return;
              } else {
                fancyOdds.push(newSession);
              }
            });
          }
        }

        setFancyOddsData(fancyOdds);
      } else {
        for (let i = 0; i < session.length; i++) {
          if (session[i].gtype === "Ball By Ball") {
            continue;
          } else {
            session[i].oddDatas.forEach((el) => {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.rname && elem.allow === true
                )
              ) {
                let newSession = {
                  id: el.sid,
                  marketType: el.rname,
                  remark: el.remark,
                  runners: [
                    {
                      backRuns: Math.round(parseFloat(el.b1)),
                      backOdds: parseFloat(el.bs1),
                      layRuns: Math.round(parseFloat(el.l1)),
                      layOdds: parseFloat(el.ls1),
                      rnrBallRunning: parseFloat(el.b1) === 0 ? true : false,
                      id: el.sid,
                    },
                  ],
                };

                if (String(el.rname).endsWith("2")) {
                  if (gameDetails.match_type === "Test") {
                    fancyOdds.push(newSession);
                  } else {
                    return;
                  }
                } else if (String(el.rname).endsWith("3")) {
                  if (gameDetails.match_type === "Test") {
                    fancyOdds.push(newSession);
                  } else {
                    return;
                  }
                } else if (String(el.rname).includes(".")) {
                  return;
                } else {
                  fancyOdds.push(newSession);
                }
              }
            });
          }
        }

        setFancyOddsData(fancyOdds);
      }
    }
  };

  const handleGetGameDetailsPaid = (gameCode) => {
    setMatchId2(gameCode);
  };

  const handleGetAppleExch = (gameCode) => {
    setMatchId1(gameCode);
  };

  const handleGetPersonal = (gameCode) => {
    setMatchId4(gameCode);
  };

  const getMatchPosition = () => {
    const payload = {
      user_id: userId,
      match_id: gameId,
      acc_type: accountType,
    };

    getBettingAdmin(payload, {
      onSuccess: (data) => {
        let below_share = 0;
        let logged_in_share = 0;

        let team1pos = 0;
        let team2pos = 0;
        let team3pos = 0;

        data.data.data.forEach((el) => {
          if (accountType === "admin") {
            logged_in_share = 100;
            below_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.user_super_admin.share;
          }
          if (accountType === "super_admin") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.user_super_admin.share;
            below_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.share;
          }
          if (accountType === "sub_admin") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.user_master
                .user_sub_admin.share;
            below_share =
              el.user_accounts.user_agent.user_super_agent.user_master.share;
          }
          if (accountType === "master") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.user_master.share;
            below_share = el.user_accounts.user_agent.user_super_agent.share;
          }
          if (accountType === "super_agent") {
            logged_in_share =
              el.user_accounts.user_agent.user_super_agent.share;
            below_share = el.user_accounts.user_agent.share;
          }
          if (accountType === "agent") {
            logged_in_share = el.user_accounts.user_agent.share;
            below_share = el.user_accounts.user_agent.share;
          }

          let share = logged_in_share - below_share;

          team1pos =
            team1pos +
            ((el.teamA_bet_amount + el.teamA_lay_amount) * share) / 100;
          team2pos =
            team2pos +
            ((el.teamB_bet_amount + el.teamB_lay_amount) * share) / 100;
          team3pos =
            team3pos +
            ((el.draw_bet_amount + el.draw_lay_amount) * share) / 100;
        });

        setTeam1Position(team1pos);
        setTeam2Position(team2pos);
        setTeam3Position(team3pos);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const betConverter = (value) => {
    if (gameDetails.api === "2") {
      return value !== "0" && value !== "0.0" && value !== "0.00"
        ? parseFloat(value).toFixed(2)
        : "0";
    } else {
      return value === "0" || value === ""
        ? "0.00"
        : (Number(value) / 100).toFixed(2);
    }
  };

  const displayAsOpposite = (value) => {
    const numValue = Number(value);
    return numValue > 0 ? -Math.abs(numValue) : Math.abs(numValue); // Negate if positive, otherwise take absolute
  };

  useEffect(() => {
    if (gameId) {
      showUserPlayed(gameId);

      setTeam1(gameDetails.team1);
      setTeam2(gameDetails.team2);

      getMatchPosition();

      if (scoreCode !== undefined || scoreCode !== "" || scoreCode !== null) {
        if (scoreCode.startsWith("1")) {
          setScoreBoardType("External");
        } else {
          setScoreBoardType("Betguru");
        }
      }

      if (gameDetails.api === "1") {
        if (gameCode !== "0") {
          handleGetGameDetailsPaid(gameCode);
        }
      } else if (gameDetails.api === "2") {
        if (gameCode !== "0") {
          handleGetAppleExch(gameCode);
        }
      } else if (gameDetails.api === "4") {
        if (gameCode !== "0") {
          handleGetPersonal(gameCode);
        }
      }

      if (gameDetails.api === "1") {
        if (gameCode === "0") {
          getGameDetails(matchDummy.data);
        }
      } else {
        if (gameCode === "0") {
          getGameDetails3(match4);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  useEffect(() => {
    if (iceexchData) {
      getGameDetails(iceexchData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iceexchData]);

  useEffect(() => {
    if (betguruData) {
      getGameDetails2(betguruData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betguruData]);

  useEffect(() => {
    if (personalapiData) {
      getGameDetails4(personalapiData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalapiData]);

  useEffect(() => {
    if (matchSettingsDataSocket) {
      handleMatchSessionSettingsData(matchSettingsDataSocket);
    }
  }, [matchSettingsDataSocket]);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Session Position</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match Session Position</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            <div className="card">
              <div className="card-header card-header-top-line">inplay</div>
              <div className="card-body">
                {scoreBoardType === "Betguru" ? (
                  <iframe
                    title="demo"
                    width="100%"
                    src={`https://score.crickexpo.in/socket-iframe-3/${scoreCode}`}
                    style={{ height: "160px" }}
                  ></iframe>
                ) : (
                  <iframe
                    title="demo"
                    width="100%"
                    src={`https://score.crickexpo.in/socket-iframe-1/${scoreCode}`}
                    style={{ height: "180px" }}
                  ></iframe>
                )}

                <div className="row align-items-start pt-3">
                  <div className="col-md-6 px-1">
                    <table className="table table-bordered ">
                      <thead>
                        <tr>
                          <th>TEAM</th>
                          <th
                            className="text-primary"
                            style={{ width: "60px" }}
                          >
                            LAGAI
                          </th>
                          <th
                            className="text-danger"
                            style={{ width: "60px", height: "48px" }}
                          >
                            KHAI
                          </th>
                          <th>
                            <span
                              className="text-primary"
                              style={{ height: "48px" }}
                            >
                              PLUS
                            </span>{" "}
                            / <span className="text-danger">MINUS</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          style={{ height: "60px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            getMatchPosition();
                            setShowMatchBets(true);
                            setShowSessionBets(false);
                            showUserPlayed(gameId);
                          }}
                        >
                          <td
                            className="text-uppercase"
                            style={{ verticalAlign: "middle" }}
                          >
                            {teamName1 === "" ? team1 : teamName1}
                          </td>
                          <td
                            className="text-primary text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team1Yes)}
                          </td>
                          <td
                            className="text-danger text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team1No)}
                          </td>
                          <td
                            className={`${
                              team1Position > 0 ? "text-danger" : "text-primary"
                            }  text-bold text-md`}
                            style={{ verticalAlign: "middle" }}
                          >
                            {Math.round(team1Position)}
                          </td>
                        </tr>
                        <tr
                          style={{ height: "48px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            getMatchPosition();
                            setShowMatchBets(true);
                            setShowSessionBets(false);
                            showUserPlayed(gameId);
                          }}
                        >
                          <td
                            className="text-uppercase"
                            style={{ verticalAlign: "middle" }}
                          >
                            {teamName2 === "" ? team2 : teamName2}
                          </td>
                          <td
                            className="text-primary text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team2Yes)}
                          </td>
                          <td
                            className="text-danger text-bold text-md"
                            style={{ verticalAlign: "middle" }}
                          >
                            {betConverter(team2No)}
                          </td>
                          <td
                            className={`${
                              team2Position > 0 ? "text-danger" : "text-primary"
                            }  text-bold text-md`}
                            style={{ verticalAlign: "middle" }}
                          >
                            {Math.round(team2Position)}
                          </td>
                        </tr>
                        {gameDetails.match_type === "Test" && (
                          <tr
                            style={{ height: "48px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              getMatchPosition();
                              setShowMatchBets(true);
                              setShowSessionBets(false);
                              showUserPlayed(gameId);
                            }}
                          >
                            <td
                              className="text-uppercase"
                              style={{ verticalAlign: "middle" }}
                            >
                              {teamName3 === "" ? "DRAW" : teamName3}
                            </td>
                            <td
                              className="text-primary text-bold text-md"
                              style={{ verticalAlign: "middle" }}
                            >
                              {betConverter(team3Yes)}
                            </td>
                            <td
                              className="text-danger text-bold text-md"
                              style={{ verticalAlign: "middle" }}
                            >
                              {betConverter(team3No)}
                            </td>
                            <td
                              className={`${
                                team3Position > 0
                                  ? "text-danger"
                                  : "text-primary"
                              }  text-bold text-md`}
                              style={{ verticalAlign: "middle" }}
                            >
                              {Math.round(team3Position)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 px-1">
                    <table className="table table-bordered ">
                      {/* session Heading code */}
                      <thead>
                        <tr>
                          <th>SESSION</th>
                          <th
                            className="text-danger"
                            style={{ width: "100px", height: "48px" }}
                          >
                            NOT
                          </th>
                          <th
                            className="text-primary"
                            style={{ width: "100px" }}
                          >
                            YES
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fancyOddsData?.length !== 0 ? (
                          fancyOddsData?.map((odd, index) => (
                            <tr key={index} style={{ cursor: "pointer" }}>
                              <td
                                style={{ verticalAlign: "middle" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowMatchBets(false);
                                  setShowSessionBets(true);
                                  showSessionPlay(odd.marketType);
                                }}
                              >
                                {odd.marketType}
                              </td>
                              <td className="text-danger text-bold text-md">
                                {odd.runners[0].rnrBallRunning ? (
                                  <>
                                    <div className="pb-2">0</div>
                                    0.0
                                  </>
                                ) : (
                                  <>
                                    <div className="pb-2">
                                      {odd.runners[0].layRuns}
                                    </div>
                                    {odd.runners[0].layOdds / 100}
                                  </>
                                )}
                              </td>
                              <td className="text-primary text-bold text-md">
                                {odd.runners[0].rnrBallRunning ? (
                                  <>
                                    <div className="pb-2">0</div>
                                    0.0
                                  </>
                                ) : (
                                  <>
                                    <div className="pb-2">
                                      {odd.runners[0].backRuns}
                                    </div>
                                    {odd.runners[0].backOdds / 100}
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No Session Available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {showSessionBets && (
                  <div className="row align-items-start">
                    <div className="col-lg-10">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered table-striped"
                          id="datatable"
                        >
                          <thead>
                            <tr>
                              <th style={{ padding: ".85rem" }}>#</th>
                              <th style={{ padding: ".85rem" }}>Session</th>
                              <th style={{ padding: ".85rem" }}>Runs</th>
                              <th style={{ padding: ".85rem" }}>Rate</th>
                              <th style={{ padding: ".85rem" }}>Mode</th>
                              <th style={{ padding: ".85rem" }}>Amount</th>
                              <th style={{ padding: ".85rem" }}>Client</th>
                              <th style={{ padding: ".85rem" }}>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchSession?.length > 0 ? (
                              matchSession.map((session, index) => (
                                <tr key={index}>
                                  <td style={{ padding: ".85rem" }}>
                                    {index + 1}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.session}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.runs}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.rate}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.mode}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {session.amount}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    ({session.user_account.code}){" "}
                                    {session.user_account.name}
                                  </td>
                                  <td style={{ padding: ".85rem" }}>
                                    {dateTimeFormatter(session.createdAt)}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8">No Session found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <table
                        className="table table-bordered table-striped"
                        id="datatable"
                      >
                        <thead>
                          <tr>
                            <th style={{ padding: ".85rem" }}>Runs</th>
                            <th style={{ padding: ".85rem" }}>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchSession?.length > 0 ? (
                            smallTableData.map((el, index) => (
                              <tr key={index}>
                                <td style={{ padding: ".85rem" }}>{el.runs}</td>
                                <td
                                  style={{ padding: ".85rem" }}
                                  className={`${
                                    el.amount > 0
                                      ? "text-primary"
                                      : "text-danger"
                                  }  fw-bold text`}
                                >
                                  {el.amount}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {showMatchBets && (
                  <div className="row align-items-start">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered table-striped"
                          id="datatable"
                        >
                          <thead>
                            <tr>
                              <th style={{ padding: ".85rem" }}>Client</th>

                              <th style={{ padding: ".85rem" }}>Rate</th>
                              <th style={{ padding: ".85rem" }}>Amount</th>
                              <th style={{ padding: ".85rem" }}>Mode</th>
                              <th style={{ padding: ".85rem" }}>Team</th>
                              <th style={{ padding: ".85rem" }}>
                                {teamName1 === "" ? team1 : teamName1}
                              </th>
                              <th style={{ padding: ".85rem" }}>
                                {teamName2 === "" ? team2 : teamName2}
                              </th>
                              {gameDetails.match_type === "Test" && (
                                <th style={{ padding: ".85rem" }}>DRAW</th>
                              )}
                              <th style={{ padding: ".85rem" }}>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchBets?.length > 0 ? (
                              matchBets.map((bets, index) => (
                                <tr key={index}>
                                  <td style={{ padding: ".85rem" }}>
                                    {bets.user_accounts[0].name} (
                                    {bets.user_accounts[0].code})
                                  </td>

                                  {bets.betTeam === "A" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {bets.type === "lay"
                                        ? bets.teamA_lay_rate
                                        : bets.teamA_back_rate}
                                    </td>
                                  ) : bets.betTeam === "B" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {bets.type === "lay"
                                        ? bets.teamB_lay_rate
                                        : bets.teamB_back_rate}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>
                                      {bets.type === "lay"
                                        ? bets.draw_lay_rate
                                        : bets.draw_back_rate}
                                    </td>
                                  )}

                                  <td style={{ padding: ".85rem" }}>
                                    {bets.amount}
                                  </td>

                                  {bets.type === "lay" ? (
                                    <td style={{ padding: ".85rem" }}>Lagai</td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>Khai</td>
                                  )}

                                  {bets.betTeam === "A" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {teamName1 === "" ? team1 : teamName1}
                                    </td>
                                  ) : bets.betTeam === "B" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {teamName2 === "" ? team2 : teamName2}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>DRAW</td>
                                  )}

                                  {bets.type === "lay" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {displayAsOpposite(bets.teamA_lay_amount)}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>
                                      {displayAsOpposite(bets.teamA_bet_amount)}
                                    </td>
                                  )}

                                  {bets.type === "lay" ? (
                                    <td style={{ padding: ".85rem" }}>
                                      {displayAsOpposite(bets.teamB_lay_amount)}
                                    </td>
                                  ) : (
                                    <td style={{ padding: ".85rem" }}>
                                      {displayAsOpposite(bets.teamB_bet_amount)}
                                    </td>
                                  )}

                                  {gameDetails.match_type === "Test" && (
                                    <td style={{ padding: ".85rem" }}>
                                      {bets.type === "lay"
                                        ? displayAsOpposite(
                                            bets.draw_lay_amount
                                          )
                                        : displayAsOpposite(
                                            bets.draw_bet_amount
                                          )}
                                    </td>
                                  )}

                                  <td style={{ padding: ".85rem" }}>
                                    {dateTimeFormatter(bets.createdAt)}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9">No data found</td>
                              </tr>
                            )}
                            {matchBets?.length > 0 ? (
                              <tr>
                                <td
                                  colSpan="5"
                                  className="text-center"
                                  style={{
                                    padding: ".85rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    padding: ".85rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {displayAsOpposite(totalMatch1)}
                                </td>
                                <td
                                  style={{
                                    padding: ".85rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {displayAsOpposite(totalMatch2)}
                                </td>
                                {gameDetails.match_type === "Test" && (
                                  <td
                                    style={{
                                      padding: ".85rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {displayAsOpposite(totalMatch3)}
                                  </td>
                                )}

                                <td
                                  colSpan="2"
                                  style={{ padding: ".85rem" }}
                                ></td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
