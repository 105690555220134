/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./ClientMasterLimitUpdate.css";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import useGetClientMasterList from "../../hooks/ClientMaster/useGetClientMasterList";
import useGetParentLimit from "../../hooks/useGetParentLimit";
import useUpdateLimitClient from "../../hooks/useUpdateLimitClient";
import { useQueryClient } from "react-query";
import useGetWalletBalance from "../../hooks/useGetWalletBalance";

export default function ClientMasterLimitUpdate() {
  const queryClient = useQueryClient();
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { data: ClientMasterListData } = useGetClientMasterList();
  const { mutate: getParentLimit } = useGetParentLimit();
  const { mutate: updateLimitClient } = useUpdateLimitClient();
  const { mutate: getWalletBalance } = useGetWalletBalance();
  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [myLimit, setMyLimit] = useState(0);

  const getMyLimit = () => {
    const payload = {
      acc_type: accountType,
      user_id: uuid,
    };

    getWalletBalance(payload, {
      onSuccess: (data) => {
        const parentLimit = Math.round(data.data.data.limit);
        setMyLimit(parentLimit);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  useEffect(() => {
    const listData = ClientMasterListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return b.code.localeCompare(a.code);
      });
      setData(listData);
      setCompleteData(listData);
    }
  }, [ClientMasterListData]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2
      );
    }
  };

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const handleAddLimit = (id, clientLimit) => {
    const payload = {
      acc_type: "user",
      user_id: id,
    };

    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    getParentLimit(payload, {
      onSuccess: (data) => {
        const parentLimit = Math.round(data.data.data);

        if (value > parentLimit) {
          toast.error(
            "You can not add balance greater than your Parent limit balance"
          );
          inputElement.value = "";
          return;
        }

        const clientLimitPayload = {
          acc_type: "user",
          user_id: id,
          limit: Math.floor(Number(clientLimit)) + Math.floor(Number(value)),
        };

        updateLimitClient(clientLimitPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries("get-client-master-list");
            toast.success("Limit Added Successfully");

            getMyLimit();
            inputElement.value = "";
          },
          onError: (error) => {
            console.error(error);
          },
        });
      },

      onError: (error) => {
        toast.error(error);
      },
    });
  };

  const handleMinusLimit = (id, clientLimit) => {
    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value > clientLimit || value === clientLimit) {
      toast.error("You can not deduct balance greater than your limit balance");
      inputElement.value = "";
      return;
    }

    const clientLimitPayload = {
      acc_type: "user",
      user_id: id,
      limit: Math.floor(Number(clientLimit)) - Math.floor(Number(value)),
    };

    updateLimitClient(clientLimitPayload, {
      onSuccess: () => {
        queryClient.invalidateQueries("get-client-master-list");
        toast.success("Limit Added Successfully");

        getMyLimit();
        inputElement.value = "";
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  useEffect(() => {
    getMyLimit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Client</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/client">Client</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Limit Update</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content" style={{ overflowX: "auto" }}>
        <div className="container-fluid">
          <div className="col-6">
            <div className="card" style={{ width: "685px" }}>
              <div className="card-header bg-primary">
                <h5 className="text-white mb-0">Client Coin Details</h5>
              </div>

              <div className="card-body">
                <div className="">
                  <div className="row">
                    <div className="parent-container limitUpdateParentContainer">
                      <div className="col-6">
                        <div
                          className="label-1 dataTables_length"
                          id="example1_length"
                        >
                          <label>
                            Show
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                setItemsPerPage(e.target.value);
                              }}
                            >
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                            entries
                          </label>
                        </div>
                      </div>
                      <div className="input-container col-6 d-flex gap-2 justify-content-end">
                        <label htmlFor="search">Search:</label>
                        <input
                          type="text"
                          id="search"
                          className="form-control form-control-sm w-auto ml-0"
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            if (value.length > 0) {
                              searchFilter(value);
                            } else {
                              setData(completeData);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <table
                    className="table table-bordered table-striped table-responsive w-100"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>S No</th>
                        <th>Client Name</th>
                        <th>Client Limit</th>
                        <th>Enter Limit</th>
                        <th>
                          {accountType === "agent"
                            ? `My Limit : ${myLimit}`
                            : "Action"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length !== 0 ? (
                        currentItems?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {item.name} ({item.code})
                              </td>
                              <td>{Math.round(item.limit)}</td>

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Limit"
                                  min="0"
                                  id={item.id}
                                  style={{ width: "200px" }}
                                />
                              </td>
                              <td
                                className="action-btn-container"
                                id="agent-plus-minus-box1"
                              >
                                <Link
                                  className="btn btn-primary mr-2 action-btns action-button-plus-minus"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAddLimit(item.id, item.limit);
                                  }}
                                >
                                  Add
                                </Link>
                                <Link
                                  className="btn btn-danger action-btns action-button-plus-minus"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleMinusLimit(item.id, item.limit);
                                  }}
                                >
                                  Minus
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>
                          <tr>
                            <td colSpan={5} className="text-center p-5">
                              <h6 className="p-5">No records to display</h6>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            currentPage <= 1 ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(Math.ceil(data.length / itemsPerPage))].map(
                          (_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber >= currentPage - 2 &&
                              pageNumber <= currentPage + 2
                            ) {
                              return (
                                <li key={index} className="page-item">
                                  <a
                                    onClick={() => paginate(pageNumber)}
                                    className={`page-link ${
                                      currentPage === pageNumber
                                        ? "active-page-link"
                                        : ""
                                    }`}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          }
                        )}

                        <li
                          className={`paginate_button page-item next ${
                            currentPage >= Math.ceil(data.length / itemsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
