/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useGetMatchesList from "../../hooks/Games/Inplay/useGetMatchesList";
import useAddGame from "../../hooks/Games/Inplay/useAddGame";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";

export default function InplayAddGame() {
  const navigate = useNavigate();

  const accountType = localStorage.getItem("account");

  const [data, setData] = useState([]);

  const { mutate: getMatchesList } = useGetMatchesList();
  const { mutate: addGame } = useAddGame();

  const handleGetMatchesList = () => {
    getMatchesList(null, {
      onSuccess: (response) => {
        console.log(response.data.matchList.data);
        let matchData = response.data.matchList.data;

        // Get today's date and set the time to the start of the day
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter matches for today or upcoming dates
        matchData = matchData.filter((match) => {
          const eventDate = new Date(match.eventTime);
          return eventDate >= today; // Include matches happening today or later
        });

        // Sort matches by event time
        matchData = matchData.sort((a, b) => {
          return new Date(a.eventTime) - new Date(b.eventTime);
        });

        setData(matchData);
      },

      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  const handleAddGame = (item) => {
    const payload = {
      code: item.gameId,
      name: String(item.eventName).toUpperCase(),
      match_type: "T20",
      team1: String(item.eventName.split(" v ")[0]).toUpperCase(),
      team2: String(item.eventName.split(" v ")[1]).toUpperCase(),
      server: dateTimeFormatter(item.eventTime),
      score_code: item.gameId,
      tv_code: item.gameId,
      api: "4",
      tv_code_api: "1",
      session_automatic: true,
    };

    console.log(payload);

    addGame(payload, {
      onSuccess: () => {
        toast.success("Game Added Successfully");
        navigate("/inplay");
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.message
            ? error.response.data.message
            : "Failed to add match"
        );
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (accountType === "admin" || accountType === "power_user") {
      handleGetMatchesList();
    } else {
      navigate("/inplay");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Inplay Add Match API</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Inplay Add Match API</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title">Match Details</h3>
                  {accountType === "admin" || accountType === "power_user" ? (
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "16px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setData([]);
                          setTimeout(() => {
                            handleGetMatchesList();
                          }, 1000);
                        }}
                      >
                        <FontAwesomeIcon icon={faRefresh} /> Refresh Matchlist
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ padding: "0.75rem" }}>S. No.</th>
                      <th style={{ padding: "0.75rem" }}>Code</th>
                      <th style={{ padding: "0.75rem" }}>Name</th>
                      <th style={{ padding: "0.75rem" }}>Date Time</th>
                      <th style={{ padding: "0.75rem" }}>Match Type</th>
                      <th style={{ padding: "0.75rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length !== 0 ? (
                      <>
                        {data?.map((item, index) => (
                          <tr key={index} id={`row${item.gameId}`}>
                            <td style={{ padding: "0.75rem" }}>{index + 1}</td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.gameId}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.eventName}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {dateTimeFormatter(item.eventTime)}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.seriesName}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {accountType === "admin" ||
                              accountType === "power_user" ? (
                                <div className="d-flex gap-2 align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleAddGame(item);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlus} /> Add Match
                                  </button>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center p-5">
                          <h6 className="p-5">No records to display</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
