/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./PowerUser.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import useGetPowerUsersList from "../../hooks/PowerUsers/useGetPowerUsersList";
import useUpdateAccountStatus from "../../hooks/useUpdateAccountStatus";
import { useQueryClient } from "react-query";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import Swal from "sweetalert2";

export default function PowerUsers() {
  let accountType = localStorage.getItem("account");

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: powerUsersListData } = useGetPowerUsersList();
  const { mutate: updateAccountStatus } = useUpdateAccountStatus();

  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const listData = powerUsersListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
      setCompleteData(listData);
    }
  }, [powerUsersListData]);

  useEffect(() => {
    if (accountType === "admin" || accountType === "power_user") {
      console.log("ok");
    } else {
      navigate("/inplay");
      toast.error("You don't have access!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function searchFilter(value) {
    const filteredData = data.filter((item) => {
      return (
        item.code.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setData(filteredData);
  }

  const createNewPowerUsers = () => {
    navigate("/power-users/create");
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(currentPage + 1 === Math.ceil(data.length / itemsPerPage));
    }
  };

  const powerUserActive = (userId) => {
    const acc_type = "power_user";
    const id = userId;
    const status = true;

    const payload = { acc_type, id, status };

    updateAccountStatus(payload, {
      onSuccess: () => {
        toast.success("User activated successfully");
        queryClient.invalidateQueries("get-power-users-list");
      },
      onError: () => {
        toast.error("Failed to activate client");
      },
    });
  };

  const powerUserDeactive = (userId) => {
    const acc_type = "power_user";
    const id = userId;
    const status = false;

    const payload = { acc_type, id, status };

    updateAccountStatus(payload, {
      onSuccess: () => {
        toast.success("User deactivated successfully");
        queryClient.invalidateQueries("get-power-users-list");
      },
      onError: () => {
        toast.error("Failed to deactivate client");
      },
    });
  };

  const handleStatusAction = (status, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${status ? "deactivate" : "activate"} this account.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${status ? "deactivate" : "activate"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        status ? powerUserDeactive(id) : powerUserActive(id);
      }
    });
  };

  const showResponsiveCols = (action, item) => {
    let existingElement = document.getElementById(`row${item.code}`);

    let showBtn = document.getElementById(`showBtn${item.code}`);
    let hideBtn = document.getElementById(`hideBtn${item.code}`);

    if (existingElement) {
      if (action === "show") {
        let tr = document.createElement("tr");
        tr.setAttribute("id", `showRow${item.code}`);

        let tdTitle = document.createElement("td");
        tdTitle.setAttribute("colspan", "2");
        tdTitle.style.borderRight = "none";

        let tdValue = document.createElement("td");
        tdValue.setAttribute("colspan", "10");
        tdValue.style.borderLeft = "none";

        let title1 = document.createElement("div");
        title1.classList.add("dataTitle");
        title1.textContent = "Status ";

        let value1 = document.createElement("div");
        value1.classList.add("dataValue");
        value1.textContent = "";

        let title11 = document.createElement("div");
        title11.classList.add("dataTitle");
        let statusSpan = document.createElement("span");
        statusSpan.textContent = item.status ? "Active" : "Inactive";
        if (item.status) {
          statusSpan.classList.add("active_bg");
        } else {
          statusSpan.classList.add("inactive_bg");
        }
        title11.appendChild(statusSpan);

        let value11 = document.createElement("div");
        value1.classList.add("dataValue");
        value1.textContent = "";

        let title2 = document.createElement("div");
        title2.classList.add("dataTitle");
        title2.textContent = "Created at: ";

        let value2 = document.createElement("div");
        value2.classList.add("dataValue");
        value2.textContent = item.createdAt;

        let title4 = document.createElement("div");
        title4.classList.add("dataTitle");
        title4.textContent = "Contact: ";

        let value4 = document.createElement("div");
        value4.classList.add("dataValue");
        value4.textContent = item.mobile;

        let title3 = document.createElement("div");
        title3.classList.add("dataTitle");
        title3.textContent = "Password: ";

        let value3 = document.createElement("div");
        value3.classList.add("dataValue");
        value3.textContent = item.password;

        let title5 = document.createElement("div");
        title5.classList.add("dataTitle");
        title5.textContent = "Name: ";

        let value5 = document.createElement("div");
        value5.classList.add("dataValue");
        value5.textContent = item.name;

        let title9 = document.createElement("div");
        title9.classList.add("dataTitle");
        title9.textContent = "CODE: ";

        let value9 = document.createElement("div");
        value9.classList.add("dataValue");
        value9.textContent = item.code;

        let screenWidth = window.innerWidth;

        if (screenWidth < 992) {
          tdTitle.appendChild(title1);
          tdValue.appendChild(value1);
        }

        if (screenWidth < 992) {
          tdTitle.appendChild(title11);
          tdValue.appendChild(value11);
        }

        if (screenWidth < 790) {
          tdTitle.insertBefore(title2, tdTitle.firstChild);
          tdValue.insertBefore(value2, tdValue.firstChild);
        }
        if (screenWidth < 660) {
          tdTitle.insertBefore(title3, tdTitle.firstChild);
          tdValue.insertBefore(value3, tdValue.firstChild);
        }

        if (screenWidth < 530) {
          tdTitle.insertBefore(title4, tdTitle.firstChild);
          tdValue.insertBefore(value4, tdValue.firstChild);
        }

        if (screenWidth < 400) {
          tdTitle.insertBefore(title5, tdTitle.firstChild);
          tdValue.insertBefore(value5, tdValue.firstChild);
        }

        tr.appendChild(tdTitle);
        tr.appendChild(tdValue);

        if (existingElement.parentNode != null) {
          existingElement.parentNode.insertBefore(
            tr,
            existingElement.nextSibling
          );
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      } else {
        let row = document.getElementById(`showRow${item.code}`);
        if (row) {
          row.remove();
        }

        showBtn.classList.toggle("showButton");
        hideBtn.classList.toggle("showButton");
      }
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1> Power Users</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Power Users</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-primary mr-2 mt-2"
                      onClick={createNewPowerUsers}
                    >
                      New{" "}
                      <i className="fa fa-plus-circle">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </i>
                    </button>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table">
                    <div className="row">
                      <div className="parent-container">
                        <div className="col-6">
                          <div
                            className="label-1 dataTables_length"
                            id="example1_length"
                          >
                            <label>
                              Show
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  setItemsPerPage(e.target.value);
                                }}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>

                        <div className="input-container col-6 d-flex gap-2 justify-content-end">
                          <label htmlFor="search">Search:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm w-auto ml-0"
                            onChange={(e) => {
                              const value = e.target.value.trim();
                              if (value.length > 0) {
                                searchFilter(value);
                              } else {
                                setData(completeData);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <table
                      id="datatable"
                      className="table table-bordered table-striped dataTable no-footer dtr-inline"
                      role="grid"
                      aria-describedby="example1_info"
                    >
                      <thead>
                        <tr>
                          <th className="thr-control"></th>
                          <th className="pd12" style={{ textAlign: "start" }}>
                            Code<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-5"
                            style={{ textAlign: "start" }}
                          >
                            Name<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-4"
                            style={{ textAlign: "start" }}
                          >
                            Mobile<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-3"
                            style={{ textAlign: "start" }}
                          >
                            Password<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-2"
                            style={{ textAlign: "start" }}
                          >
                            Created at<span id="sorted-icon">↑↓</span>
                          </th>
                          <th
                            className="mobile-d-1"
                            style={{ textAlign: "start" }}
                          >
                            Status
                          </th>
                          <th className="pd12">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.length !== 0 ? (
                          <>
                            {currentItems.map((item, index) => (
                              <tr key={index} id={`row${item.code}`}>
                                <td className="dtr-control">
                                  <div
                                    className="dynamicControl showButton"
                                    id={`showBtn${item.code}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showResponsiveCols("show", item);
                                    }}
                                  >
                                    +
                                  </div>
                                  <div
                                    className="dynamicControl hide"
                                    id={`hideBtn${item.code}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showResponsiveCols("hide", item);
                                    }}
                                  >
                                    -
                                  </div>
                                </td>
                                <td style={{ textAlign: "start" }}>
                                  {item.code}
                                </td>
                                <td
                                  className="mobile-d-5"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.name}
                                </td>
                                <td
                                  className="mobile-d-4"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.mobile}
                                </td>
                                <td
                                  className="mobile-d-3"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.password}
                                </td>
                                <td
                                  className="mobile-d-2"
                                  style={{ textAlign: "start" }}
                                >
                                  {item.createdAt
                                    ? dateTimeFormatter(item.createdAt)
                                    : item.createdAt}
                                </td>
                                <td className="mobile-d-1">
                                  {item.status ? (
                                    <button
                                      className="btn btn-sm btn-success"
                                      id="active-1"
                                      style={{
                                        cursor: "default",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Active
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-danger"
                                      id="active-1"
                                      style={{
                                        cursor: "default",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      Deactive
                                    </button>
                                  )}
                                </td>
                                <td className="action-btn-container">
                                  <Link
                                    className="btn btn-primary mr-2 action-btns"
                                    to="/power-users/update"
                                    state={item}
                                  >
                                    <FontAwesomeIcon icon={faPen} />
                                  </Link>
                                  <a
                                    className="btn btn-danger action-btns"
                                    onClick={() => {
                                      handleStatusAction(item.status, item.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={13} className="text-center p-5">
                              <h6 className="p-5">No records to display</h6>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            href="#"
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {Array.from({
                          length: Math.ceil(data.length / itemsPerPage),
                        }).map((_, index) => (
                          <li key={index} className="page-item">
                            <a
                              href="#"
                              onClick={() => paginate(index + 1)}
                              className={`page-link ${
                                currentPage === index + 1
                                  ? "active-page-link"
                                  : ""
                              }`}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            href="#"
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
