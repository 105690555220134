/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../Components/PageHeader";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import useGetTrashCompleteMatch from "../../hooks/Games/useGetTrashCompleteMatch";

export default function RecycleComplete() {
  const { data: inPlayGames, isLoading: isDataLoading } =
    useGetTrashCompleteMatch();

  const [data, setData] = useState([]);
  const accountType = localStorage.getItem("account");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if (inPlayGames) {
      setData(inPlayGames?.data?.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoading]);

  const completeData = data || [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2
      );
    }
  };

  const totalPages = Math.ceil(completeData?.length / itemsPerPage);
  const middlePagesToShow = 3;
  const pageNumbers = [];

  if (totalPages <= middlePagesToShow + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= Math.ceil(middlePagesToShow / 2) + 1) {
    for (let i = 1; i <= middlePagesToShow + 1; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - Math.floor(middlePagesToShow / 2)) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = totalPages - middlePagesToShow; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - Math.floor(middlePagesToShow / 2);
      i <= currentPage + Math.floor(middlePagesToShow / 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  return (
    <div className="content-wrapper">
      <PageHeader title="Cancel Bets Complete Match" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title">Sports Details</h3>
                  {/* {accountType === "admin" || accountType === "power_user" ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleAddGame();
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Game
                    </button>
                  ) : null} */}
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {/* <th style={{ padding: "0.75rem" }}>#</th> */}
                      <th style={{ padding: "0.75rem" }}>S. No.</th>
                      <th style={{ padding: "0.75rem" }}>Code</th>
                      <th style={{ padding: "0.75rem" }}>Name</th>
                      <th style={{ padding: "0.75rem" }}>Date Time</th>
                      <th style={{ padding: "0.75rem" }}>Match Type</th>
                      <th style={{ padding: "0.75rem" }}>Lock Game</th>
                      <th style={{ padding: "0.75rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length !== 0 ? (
                      <>
                        {currentItems?.map((item, index) => (
                          <tr key={index} id={`row${item.code}`}>
                            <td style={{ padding: "0.75rem" }}>{index + 1}</td>
                            <td style={{ padding: "0.75rem" }}>{item.code}</td>
                            <td style={{ padding: "0.75rem" }}>{item.name}</td>
                            <td style={{ padding: "0.75rem" }}>
                              {dateTimeFormatter(item.server)}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.match_type}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {!item.isLocked ? (
                                <button
                                  className="btn btn-sm btn-success"
                                  id="active-1"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "none",
                                  }}
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-danger"
                                  id="active-1"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "none",
                                  }}
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {accountType === "admin" ||
                              accountType === "power_user" ? (
                                <div className="d-flex gap-2 align-items-center justify-content-center">
                                  <Link
                                    className="btn btn-sm btn-primary btn-warning"
                                    to={`/recyclesession?id=${item.id}`}
                                  >
                                    <span className="mr-1">S</span>
                                    <FontAwesomeIcon icon={faGamepad} />
                                  </Link>

                                  <Link
                                    className="btn btn-sm btn-primary btn-danger"
                                    to={`/recyclematch?id=${item.id}`}
                                    state={{
                                      team1: item.team1,
                                      team2: item.team2,
                                    }}
                                  >
                                    <span className="mr-1">M</span>
                                    <FontAwesomeIcon icon={faGamepad} />
                                  </Link>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center p-5">
                          <h6 className="p-5">No records to display</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData?.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            currentPage <= 1 ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(Math.ceil(data?.length / itemsPerPage))].map(
                          (_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber >= currentPage - 2 &&
                              pageNumber <= currentPage + 2
                            ) {
                              return (
                                <li key={index} className="page-item">
                                  <a
                                    onClick={() => paginate(pageNumber)}
                                    className={`page-link ${
                                      currentPage === pageNumber
                                        ? "active-page-link"
                                        : ""
                                    }`}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          }
                        )}

                        <li
                          className={`paginate_button page-item next ${
                            currentPage >= Math.ceil(data.length / itemsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
