/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import "./ledgermanagement.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useGetLedgerData from "../../hooks/Ledger/useGetLedgerData";

export default function AgentLedger(props) {
  const { mutate: getLedgerData } = useGetLedgerData();

  const [users, setUsers] = useState([]);
  const [ledger, setLedger] = useState([]);
  const [denaHai, setDenaHai] = useState([]);
  const [denaHaiComplete, setDenaHaiComplete] = useState([]);
  const [lenaHai, setLenaHai] = useState([]);
  const [lenaHaiComplete, setLenaHaiComplete] = useState([]);
  const [denaHaiTotal, setDenaHaiTotal] = useState(0);
  const [lenaHaiTotal, setLenaHaiTotal] = useState(0);

  const checkData = () => {
    const UUID = localStorage.getItem("UUID");
    const accountType = localStorage.getItem("account");

    const payload = {
      user_id: UUID,
      logged_acc_type: accountType,
      acc_type: "agent",
    };

    getLedgerData(payload, {
      onSuccess: (response) => {
        // console.log(response);
        // console.log(response.data.won);
        response.data.won.forEach((element) => {
          if (!users.some((el) => el.agent_id === element.agent_id)) {
            users.push({ agent_id: element.agent_id });
            ledger.push(element);
          } else {
            const index = ledger.findIndex(
              (img) => img.agent_id === element.agent_id
            );

            if (element.agent_final !== 0 && element.agent_net_amt !== null) {
              ledger[index].agent_final =
                ledger[index].agent_final + element.agent_final;
            } else if (
              element.agent_final !== 0 &&
              element.agent_net_amt === null
            ) {
              ledger[index].agent_final =
                ledger[index].agent_final + element.agent_final;
            } else {
              ledger[index].agent_final = ledger[index].agent_final + 0;
            }
          }
        });

        ledger.forEach((el) => {
          if (el.agent_final > 0) {
            let new_el = {
              ...el,
              agent_final: parseInt(el.agent_final),
            };

            lenaHai.push(new_el);
            lenaHaiComplete.push(new_el);
            setLenaHaiTotal(
              lenaHai.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.agent_final,
                0
              )
            );
          } else {
            let new_el = {
              ...el,
              agent_final: parseInt(Math.abs(el.agent_final)),
            };

            denaHai.push(new_el);
            denaHaiComplete.push(new_el);
            setDenaHaiTotal(
              denaHai.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.agent_final,
                0
              )
            );
          }
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    checkData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function searchLenaHai(val) {
    const filteredData = lenaHai.filter((item) => {
      return (
        item.user_agents[0].code.toLowerCase().includes(val.toLowerCase()) ||
        item.user_agents[0].name.toLowerCase().includes(val.toLowerCase())
      );
    });

    setLenaHai(filteredData);

    setLenaHaiTotal(
      filteredData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.agent_final,
        0
      )
    );
  }

  function searchDenaHai(val) {
    const filteredData = denaHai.filter((item) => {
      return (
        item.user_agents[0].code.toLowerCase().includes(val.toLowerCase()) ||
        item.user_agents[0].name.toLowerCase().includes(val.toLowerCase())
      );
    });

    setDenaHai(filteredData);

    setDenaHaiTotal(
      filteredData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.agent_final,
        0
      )
    );
  }

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>All Agent Ledger</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>All Client Ledger</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* <button onClick={checkData}>Check</button> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card p-2">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <span className="me-1">Search:</span>
                  <input
                    type="text"
                    className="form-control w-auto ml-1"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (value.length > 0) {
                        searchLenaHai(value);
                      } else {
                        setLenaHai(lenaHaiComplete);
                        setLenaHaiTotal(
                          lenaHaiComplete.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.agent_final,
                            0
                          )
                        );
                      }
                    }}
                  />
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-striped"
                    id="datatable"
                  >
                    <thead>
                      <tr>
                        <th colSpan={6}>PAYMENT RECEIVING FROM (LENA HE)</th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lenaHai && lenaHai.length !== 0 ? (
                        <>
                          {lenaHai.map((el, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {el.user_agents[0].code}{" "}
                                  {el.user_agents[0].name}
                                </td>
                                <td>{el.user_agents[0].mobile}</td>
                                <td>{el.agent_final}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={2}>Total</td>
                            <td>{lenaHaiTotal}</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td colSpan={3}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <span className="me-1">Search:</span>
                  <input
                    type="text"
                    className="form-control w-auto ml-1"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (value.length > 0) {
                        searchDenaHai(value);
                      } else {
                        setDenaHai(denaHaiComplete);
                        setDenaHaiTotal(
                          denaHaiComplete.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.agent_final,
                            0
                          )
                        );
                      }
                    }}
                  />
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-striped"
                    id="datatable"
                  >
                    <thead>
                      <tr>
                        <th colSpan={6}>PAYMENT RECEIVING FROM (DENA HE)</th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {denaHai && denaHai.length !== 0 ? (
                        <>
                          {denaHai.map((el, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {el.user_agents[0].code}{" "}
                                  {el.user_agents[0].name}
                                </td>
                                <td>{el.user_agents[0].mobile}</td>
                                <td>{el.agent_final}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={2}>Total</td>
                            <td>{denaHaiTotal}</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td colSpan={3}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
