/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
// import useGetUserOnlySession from "../../hooks/Games/Inplay/useGetUserOnlySession";
import useGetUserOnlySessionTrash from "../../hooks/Games/Inplay/useGetUserOnlySessionTrash";
// import useDeleteSelectedSession from "../../hooks/Games/Inplay/useDeleteSelectedSession";
import useRevertSelectedSession from "../../hooks/Games/Inplay/useRevertSelectedSession";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
// import useUpdateSessionBet from "../../hooks/Games/useUpdateSessionBet";

export default function RecycleSession() {
  // const accountType = localStorage.getItem("account");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);

  const matchId = UrlSearchParams.get("id");
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  // const { mutate: getUserOnlySession } = useGetUserOnlySession();
  const { mutate: getUserOnlySessionTrash } = useGetUserOnlySessionTrash();
  // const { mutate: deleteSelectedSession } = useDeleteSelectedSession();
  const { mutate: revertSelectedSession } = useRevertSelectedSession();
  // const { mutate: updateSessionBet } = useUpdateSessionBet();

  const [sessionData, setSessionData] = useState([]);

  const handleGetSessionOfMatch = () => {
    const payload = {
      match_id: matchId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getUserOnlySessionTrash(payload, {
      onSuccess: (response) => {
        // console.log(response);

        let filteredData = [];

        if (accountType !== "admin") {
          response.data.filter((item) => {
            if (item.view === true) {
              if (item.trash === true) {
                filteredData.push(item);
              }
            }
          });
        } else {
          if (isMasterAdmin === "1" || isMasterAdmin === "true") {
            filteredData = response.data;
          } else {
            filteredData = response.data.filter(
              (item) => item.deletedBy !== "AD94244"
            );
          }
        }

        setSessionData(filteredData);

        let userData = [];
        let sessionsData = [];

        filteredData.forEach((el) => {
          if (!userData.some((ele) => ele.id === el.user_account.id)) {
            userData.push({
              id: el.user_account.id,
              name: `${el.user_account.name}`,
              code: `${el.user_account.code}`,
            });
          }
          if (!sessionsData.some((ele) => ele.name === el.session)) {
            sessionsData.push({ name: `${el.session}` });
          }
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (matchId) {
      handleGetSessionOfMatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  // function deleteSession(sessionId) {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You want to delete this Bet. You won't be able to revert it.`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //     input: "text",
  //     inputLabel: "Enter your password",
  //     inputValidator: (value) => {
  //       if (!value) {
  //         return "Password is required!";
  //       }
  //       if (value !== "Tihor@ace00") {
  //         return "Invalid Password!";
  //       }
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const payload = {
  //         session_id: sessionId,
  //       };

  //       deleteSelectedSession(payload, {
  //         onSuccess: () => {
  //           toast.success("Session deleted successfully");
  //           handleGetSessionOfMatch();
  //         },
  //         onError: (error) => {
  //           console.log(error);
  //         },
  //       });
  //     }
  //   });
  // }

  function revertSession(sessionId) {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Revert this Bet?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, revert it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@ace00") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // const payload = {
        //   id: session.id,
        //   user_id: session.user_id,
        //   session: session.session,
        //   match_id: session.match_id,
        //   trash: false,
        // };
        const payload = {
          session_id: sessionId,
        };
        revertSelectedSession(payload, {
          onSuccess: () => {
            toast.success("Session reverted successfully");
            handleGetSessionOfMatch();
          },
          onError: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Session Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/recycleinplay">Cancel Bets</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="col-md-12">
                    <table
                      className="table table-striped table-bordered"
                      style={{ padding: "0.75rem" }}
                    >
                      <thead>
                        <tr style={{ padding: "0.75rem" }}>
                          <th style={{ height: "67px", minWidth: "35px" }}>
                            #
                          </th>

                          <th>Code</th>
                          <th style={{ height: "67px", minWidth: "140px" }}>
                            Session
                          </th>

                          <th>Rate</th>
                          <th>Run</th>
                          <th>Mode</th>
                          <th>Amount</th>
                          <th>Dec</th>
                          <th style={{ height: "67px", minWidth: "150px" }}>
                            Date & Time
                          </th>
                          <th style={{ height: "67px", minWidth: "150px" }}>
                            Delete Date & Time
                          </th>
                          <th>Deleted By</th>
                          {accountType === "admin" && (
                            <th style={{ height: "67px", minWidth: "150px" }}>
                              Action
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData?.length > 0 ? (
                          sessionData.map((bets, index) => (
                            <tr key={index}>
                              <td style={{ padding: "0.75rem" }}>
                                {index + 1}
                              </td>

                              <td>
                                {bets.user_account.name}(
                                {bets.user_account.code})
                              </td>
                              <td
                                style={{ padding: "0.75rem", height: "70px" }}
                              >
                                {bets.session}{" "}
                                {!bets.view && (
                                  <span className="text-danger">(Hidden)</span>
                                )}
                              </td>

                              <td>{bets.rate}</td>
                              <td>{bets.runs}</td>
                              <td>{bets.mode}</td>
                              <td>{bets.amount}</td>
                              <td>{bets.declare}</td>

                              <td>{dateTimeFormatter(bets.createdAt)}</td>
                              <td>{dateTimeFormatter(bets.updatedAt)}</td>

                              <td>{bets.deletedBy}</td>

                              {accountType === "admin" ? (
                                <td>
                                  {/* <button
                                    className="btn btn-sm btn-primary btn-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteSession(bets.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button> */}
                                  <button
                                    className="btn btn-sm btn-warning ml-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      revertSession(bets.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faRotateLeft} />
                                  </button>
                                </td>
                              ) : null}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={accountType === "admin" ? 12 : 11}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
