/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PageHeader from "../../Components/PageHeader";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import useGetAllCassinoBets from "../../hooks/Games/Casino/useGetAllCassinoBets";
import useDeleteCassinoBet from "../../hooks/Games/Casino/useDeleteCassinoBet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

export default function CasinoBetReport() {
  const userId = localStorage.getItem("UUID");
  const loggedAccType = localStorage.getItem("account");

  const { mutate: getAllCassinoBets } = useGetAllCassinoBets();
  const { mutate: deleteCassinoBet, isLoading: isDeletingCassinoBet } =
    useDeleteCassinoBet();

  const [data, setData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [betsData, setBetsData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(betsData?.length / itemsPerPage);
  const middlePagesToShow = 3;
  const pageNumbers = [];

  if (totalPages <= middlePagesToShow + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= Math.ceil(middlePagesToShow / 2) + 1) {
    for (let i = 1; i <= middlePagesToShow + 1; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  } else if (currentPage >= totalPages - Math.floor(middlePagesToShow / 2)) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = totalPages - middlePagesToShow; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - Math.floor(middlePagesToShow / 2);
      i <= currentPage + Math.floor(middlePagesToShow / 2);
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(totalPages);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const user = formData.get("user");
    const from = formData.get("from");
    const to = formData.get("to");
    const game = formData.get("game");

    if (user === "" && from === "" && to === "" && game === "") {
      setBetsData(completeData);
    } else {
      // eslint-disable-next-line array-callback-return
      const filteredData = completeData.filter((item) => {
        if (user !== "" && from !== "" && to !== "" && game !== "") {
          return (
            item.user_account.id === user &&
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to) &&
            item.cassino_game.id === game
          );
        } else if (user !== "" && from !== "" && to !== "") {
          return (
            item.user_account.id === user &&
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (game !== "" && from !== "" && to !== "") {
          return (
            item.cassino_game.id === game &&
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (user !== "" && from !== "") {
          return (
            item.user_account.id === user &&
            new Date(item.createdAt) >= new Date(from)
          );
        } else if (user !== "" && to !== "") {
          return (
            item.user_account.id === user &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (from !== "" && to !== "") {
          return (
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (user !== "" && game !== "") {
          return item.user_account.id === user && item.cassino_game.id === game;
        } else if (user !== "") {
          return item.user_account.id === user;
        } else if (from !== "") {
          return new Date(item.createdAt) >= new Date(from);
        } else if (to !== "") {
          return new Date(item.createdAt) <= new Date(to);
        } else if (game !== "") {
          return item.cassino_game.id === game;
        }
      });
      setBetsData(filteredData);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = betsData.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber === 1) {
      setIsFirstPage(true);
    } else if (pageNumber === Math.ceil(betsData?.length / itemsPerPage)) {
      setIsLastPage(true);
    } else {
      setIsFirstPage(false);
      setIsLastPage(false);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 === 1);
      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(betsData?.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 === Math.ceil(betsData?.length / itemsPerPage)
      );
    }
  };

  function searchFilter(value) {
    const filteredData = betsData.filter((item) => {
      return (
        item.user_account.code.toLowerCase().includes(value.toLowerCase()) ||
        item.user_account.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setBetsData(filteredData);
  }

  const handleDeleteBet = (id, mid, user_id, roundWinner) => {
    if (!id || !mid || !user_id) {
      toast.error("Invalid data provided. Please try again later");
      return;
    } else {
      const payload = {
        logged_acc_type: loggedAccType,
        logged_user_id: userId,
        bet_id: id,
        mid: mid,
        user_id: user_id,
        round_winner: roundWinner,
      };

      Swal.fire({
        title: "Are you sure?",
        text: `You want to delete this bet. You won't be able to revert it.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        input: "text",
        inputLabel: "Enter your password",
        inputValidator: (value) => {
          if (!value) {
            return "Password is required!";
          }
          if (value !== "Tihor@9ex") {
            return "Invalid Password!";
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteCassinoBet(payload, {
            onSuccess: (response) => {
              console.log(response.data);
              toast.success("Bet deleted successfully");
              window.location.reload();
            },
            onError: (error) => {
              toast.error(
                error?.response?.data?.message || "An error occurred"
              );
              console.error(error);
            },
          });
        }
      });
    }
  };

  useEffect(() => {
    const payload = {
      logged_user_id: userId,
      logged_acc_type: loggedAccType,
    };

    getAllCassinoBets(payload, {
      onSuccess: (response) => {
        console.log(response.data.data);
        let data = response.data.data.sort((a, b) => {
          return +new Date(b.createdAt) - +new Date(a.createdAt);
        });

        // data = data.filter((item) => item.ledger_status !== 1);

        const userData = [];
        const gameList = [];

        data.forEach((item) => {
          const existingIndex = userData.findIndex(
            (el) => el.id === item.user_account.id
          );

          if (existingIndex === -1) {
            userData.push(item.user_account);
          } else {
            return;
          }
        });

        data.forEach((item) => {
          const existingIndex = gameList.findIndex(
            (el) => el.id === item.cassino_game.id
          );

          if (existingIndex === -1) {
            gameList.push(item.cassino_game);
          } else {
            return;
          }
        });

        setData(userData);
        setGameData(gameList);

        setBetsData(data);
        setCompleteData(data);
      },
      onError: (error) => {
        console.error(error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <PageHeader title={"Casino Bet Report"} />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header bg-purple">
                <form onSubmit={handleSubmit}>
                  <div className="row align-items-end px-2 top-row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Client</label>
                        <select className="form-select w-100" name="user">
                          <option value="">Select</option>
                          {data &&
                            data?.length !== 0 &&
                            data?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.code} {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Game</label>
                        <select className="form-select w-100" name="game">
                          <option value="">Select</option>
                          {gameData &&
                            gameData?.length !== 0 &&
                            gameData?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Date From</label>
                        <input
                          type="date"
                          name="from"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Date To</label>
                        <input type="date" name="to" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="row px-2 pb-1 top-row justify-content-between align-items-start">
                    <div className="col-md-3">
                      <div className="form-group w-100 align-self-end">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="col-md-3 last-row">
                      <div className="form-group">
                        <label htmlFor="search">Search:</label>
                        <input
                          type="text"
                          id="search"
                          className="form-control"
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            if (value.length > 0) {
                              searchFilter(value);
                            } else {
                              setBetsData(completeData);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="bg-yellow">
                      <tr>
                        <th>#</th>
                        <th>Client</th>
                        <th>GameName</th>
                        <th>Round Id</th>
                        <th>Player</th>
                        <th>Rate</th>
                        <th>Amount</th>
                        <th>Result</th>
                        <th>Date</th>
                        {loggedAccType === "admin" ? <th>Action</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {betsData && betsData?.length !== 0 ? (
                        currentItems?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.user_account.code} {item.user_account.name}
                            </td>
                            <td>{item.cassino_game.name}</td>
                            <td>{item.mid}</td>
                            <td>{item.nation}</td>
                            <td>{item.rate}</td>
                            <td>{item.amount}</td>
                            <td>{item.result}</td>
                            <td>{dateTimeFormatter(item.createdAt)}</td>
                            {loggedAccType === "admin" &&
                            (item.ledger_status === false ||
                              item.ledger_status === 0) ? (
                              <td>
                                <button
                                  className="btn btn-sm btn-primary btn-danger"
                                  disabled={isDeletingCassinoBet}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let roundWinner = "";
                                    if (item.result === "loss") {
                                      if (item.bet === "A") {
                                        roundWinner = "B";
                                      } else {
                                        roundWinner = "A";
                                      }
                                    } else {
                                      roundWinner = item.bet;
                                    }
                                    handleDeleteBet(
                                      item.id,
                                      item.mid,
                                      item.user_id,
                                      roundWinner
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                              </td>
                            ) : loggedAccType === "admin" ? (
                              <td>Added to Ledger</td>
                            ) : null}
                          </tr>
                        ))
                      ) : (
                        <tr className="bg-white">
                          <td colSpan="9">
                            <h6 className="text-center">
                              There are no records to display
                            </h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData?.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            isFirstPage ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Previous
                          </a>
                        </li>
                        {pageNumbers?.map((number, index) => (
                          <li key={index} className="page-item">
                            <a
                              onClick={() => {
                                if (number !== "...") {
                                  paginate(number);
                                }
                              }}
                              className={`page-link ${
                                currentPage === number ? "active-page-link" : ""
                              } ${number === "..." ? "disabled" : ""}`}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {number}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`paginate_button page-item next ${
                            isLastPage ? "disabled" : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
