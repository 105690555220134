/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useGetSessionBetLog from "../hooks/useGetSessionBetLog";
import PageHeader from "../Components/PageHeader";
import { dateTimeFormatter } from "../utils/dateFormatter";

export default function SessionBetLogs() {
  const navigate = useNavigate();

  const uuid = localStorage.getItem("UUID");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  const { mutate: getSessionBetLog } = useGetSessionBetLog();

  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const user = formData.get("user");
    const from = formData.get("from");
    const to = formData.get("to");

    if (user === "" && from === "" && to === "") {
      setData(completeData);
    } else {
      // eslint-disable-next-line array-callback-return
      const filteredData = completeData.filter((item) => {
        if (from !== "" && to !== "") {
          return (
            new Date(item.createdAt) >= new Date(from) &&
            new Date(item.createdAt) <= new Date(to)
          );
        } else if (from !== "") {
          return new Date(item.createdAt) >= new Date(from);
        } else if (to !== "") {
          return new Date(item.createdAt) <= new Date(to);
        }
      });
      setData(filteredData);
    }
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2
      );
    }
  };

  useEffect(() => {
    if (isMasterAdmin !== "1" && isMasterAdmin !== "true") {
      navigate("/dashboard");
    } else {
      const logPayload = {
        user_id: uuid,
      };

      getSessionBetLog(logPayload, {
        onSuccess: (response) => {
          console.log(response);
          setData(response.data.logs);
          setCompleteData(response.data.logs);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <PageHeader title={`Session Bet Logs`} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card ">
              <div className="card-header bg-purple">
                <div className="container-fluid">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Date From</label>
                          <input
                            type="date"
                            name="from"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-3 mt-2">
                        <div className="form-group">
                          <label>Date To</label>
                          <input
                            type="date"
                            name="to"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-3 d-flex mt-2">
                        <div className="form-group w-100 align-self-end">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      <div className="col-md-3 mt-2">
                        <div
                          className="label-1 dataTables_length align-middle justify-content-end"
                          id="example1_length"
                        >
                          <label>
                            Show
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                setItemsPerPage(e.target.value);
                              }}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                            entries
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card-body" style={{ width: "100%" }}>
                <div
                  className="table-responsive"
                  style={{ width: "100%", overflowX: "scroll" }}
                >
                  <table
                    className="table table-bordered table-striped"
                    style={{ width: "200%" }}
                  >
                    <thead className="bg-yellow">
                      <tr>
                        <th>#</th>
                        <th>Match</th>
                        <th>User</th>
                        <th>Session</th>
                        <th colSpan={2}>Runs</th>
                        <th colSpan={2}>Rate</th>
                        <th colSpan={2}>Mode</th>
                        <th colSpan={2}>Amount</th>
                        <th colSpan={2}>View</th>
                        <th>Date Time</th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Old Runs</th>
                        <th>New Runs</th>
                        <th>Old Rate</th>
                        <th>New Rate</th>
                        <th>Old Mode</th>
                        <th>New Mode</th>
                        <th>Old Amount</th>
                        <th>New Amount</th>
                        <th>Old View</th>
                        <th>New View</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length !== 0 ? (
                        currentItems?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <span
                                  className="mb-1 d-block"
                                  style={{ fontSize: "16px" }}
                                >
                                  {item.match_name}
                                </span>
                                ({dateTimeFormatter(item.match_time)})
                              </td>
                              <td>
                                {item.user_account.name} (
                                {item.user_account.code})
                              </td>
                              <td>{item.session}</td>
                              <td>{item.runsOld}</td>
                              <td>{item.runsNew}</td>
                              <td>{item.rateOld}</td>
                              <td>{item.rateNew}</td>
                              <td>{item.modeOld}</td>
                              <td>{item.modeNew}</td>
                              <td>{item.amountOld}</td>
                              <td>{item.amountNew}</td>
                              <td>
                                {item.viewOld === true ? "Active" : "Hide"}
                              </td>
                              <td>
                                {item.viewNew === true ? "Active" : "Hide"}
                              </td>
                              <td>{dateTimeFormatter(item.createdAt)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="bg-white">
                          <td colSpan="15">
                            <h6 className="text-center fs-5">
                              There are no records to display
                            </h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="example1_info">
                      Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                      {completeData.length} entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example1_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item previous ${
                            currentPage <= 1 ? "disabled" : ""
                          }`}
                          id="example1_previous"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        {[...Array(Math.ceil(data.length / itemsPerPage))].map(
                          (_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber >= currentPage - 2 &&
                              pageNumber <= currentPage + 2
                            ) {
                              return (
                                <li key={index} className="page-item">
                                  <a
                                    onClick={() => paginate(pageNumber)}
                                    className={`page-link ${
                                      currentPage === pageNumber
                                        ? "active-page-link"
                                        : ""
                                    }`}
                                  >
                                    {pageNumber}
                                  </a>
                                </li>
                              );
                            }
                            return null;
                          }
                        )}

                        <li
                          className={`paginate_button page-item next ${
                            currentPage >= Math.ceil(data.length / itemsPerPage)
                              ? "disabled"
                              : ""
                          }`}
                          id="example1_next"
                        >
                          <a
                            tabIndex="0"
                            className="page-link"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
