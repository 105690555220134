import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import useLoginNew from "../hooks/useLoginNew";

export default function Login() {
  const { mutate, isLoading: isLoggingIn } = useLoginNew();

  const [code, setCode] = useState("");
  const [codeType, setCodeType] = useState("");
  const [password, setPassword] = useState("");

  let prefix = "";
  let superPrefix = "";
  if (window.location.href.includes("localhost")) {
    prefix = "ACE+10X+JMD";
    superPrefix = "ROHIT BHAIYA KI JAI HO";
  } else {
    console.log(window.location.href);
    if (window.location.href.includes("acebets")) {
      superPrefix = "ACEBETS";
      prefix = window.location.href
        .split("//")[1]
        .split(".acebets")[0]
        .toUpperCase();
    } else {
      superPrefix = "10XBAT";
      prefix = window.location.href
        .split("//")[1]
        .split(".10xbat")[0]
        .toUpperCase();
    }
  }

  async function login(e) {
    e.preventDefault();
    let acc_type = "";

    const accTypeToUrls = {
      power_user: [
        "https://mini.acebets.in/",
        "https://mini.10xbat.in/",
        "http://localhost:3000/",
      ],
      super_admin: [
        "https://mini.acebets.in/",
        "https://mini.10xbat.in/",
        "http://localhost:3000/",
      ],
      sub_admin: [
        "https://sub.acebets.in/",
        "https://sub.10xbat.in/",
        "http://localhost:3000/",
      ],
      master: [
        "https://master.acebets.in/",
        "https://master.10xbat.in/",
        "http://localhost:3000/",
      ],
      super_agent: [
        "https://super.acebets.in/",
        "https://super.10xbat.in/",
        "http://localhost:3000/",
      ],
      agent: [
        "https://agent.acebets.in/",
        "https://agent.10xbat.in/",
        "http://localhost:3000/",
      ],
      admin: [
        "https://ad.acebets.in/",
        "https://ad.10xbat.in/",
        "http://localhost:3000/",
      ],
    };

    const currentUrl = window.location.href;

    const upperCaseCodeType = codeType.toUpperCase();

    switch (upperCaseCodeType) {
      case "PW":
        acc_type = "power_user";
        break;
      case "MINI": //MINI start from 1
        acc_type = "super_admin";
        break;
      case "SBA":
        acc_type = "sub_admin";
        break;
      case "MA": //MA
        acc_type = "master";
        break;
      case "SA": //SA
        acc_type = "super_agent";
        break;
      case "A": //A
        acc_type = "agent";
        break;
      case "AD": //AD
        acc_type = "admin";
        break;
      default:
        toast.error("Incorrect code entered");
        return;
    }

    if (!accTypeToUrls[acc_type].includes(currentUrl)) {
      toast.error("You do not have access to this URL");
      return;
    }

    if (code === "" || password === "") {
      return toast.error("Please fill in all fields");
    }

    let payload = { acc_type: acc_type, code: code, password: password };

    mutate(payload, {
      onSuccess: (response) => {
        let useData = response?.data;
        console.log(response);

        if (useData) {
          const details = useData?.register?.data;
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("account", acc_type);
          localStorage.setItem("UUID", details.id);
          localStorage.setItem("RES_PSS", useData.token);
          localStorage.setItem("NAME", details.name);
          localStorage.setItem("DATA", JSON.stringify(details));
          localStorage.setItem("token", useData.token);
          localStorage.setItem("CODE", details.code);
          localStorage.setItem("LIMIT", details.limit);
          localStorage.setItem("status", details.status);
          localStorage.setItem("STATUS", details.status);
          localStorage.setItem("password", password);
          if (acc_type === "admin") {
            localStorage.setItem("isMasterAdmin", details.isMasterAdmin);
          }
          toast.success("Login Successful");
          window.location.href = "/dashboard";
        } else {
          toast.error(response?.message);
        }
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    });
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="hold-transition login-page">
        <div className="login-box">
          <h2 className="text-center">
            {superPrefix} {prefix} Admin
          </h2>
          <div className="card card-primary">
            <div className="card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    name="code"
                    className="form-control"
                    placeholder="username"
                    onChange={(e) => {
                      setCode(e.target.value);
                      setCodeType(e.target.value.replace(/[^A-Za-z]/g, ""));
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="fas fa-envelope">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="fas fa-lock">
                        <FontAwesomeIcon icon={faLock} />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember Me</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={login}
                      className="btn btn-info btn-block"
                    >
                      {isLoggingIn ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
