import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../Components/PageHeader";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import useGetTrashLiveMatch from "../../hooks/Games/useGetTrashLiveMatch";

export default function RecycleInplay() {
  const { data: inPlayGames } = useGetTrashLiveMatch();

  const [data, setData] = useState([]);
  const accountType = localStorage.getItem("account");

  useEffect(() => {
    if (inPlayGames) {
      setData(inPlayGames?.data?.data);
    }
  }, [inPlayGames]);

  return (
    <div className="content-wrapper">
      <PageHeader title="Cancel Bets Inplay Match" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title">Sports Details</h3>
                  {/* {accountType === "admin" || accountType === "power_user" ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleAddGame();
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Game
                    </button>
                  ) : null} */}
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {/* <th style={{ padding: "0.75rem" }}>#</th> */}
                      <th style={{ padding: "0.75rem" }}>S. No.</th>
                      <th style={{ padding: "0.75rem" }}>Code</th>
                      <th style={{ padding: "0.75rem" }}>Name</th>
                      <th style={{ padding: "0.75rem" }}>Date Time</th>
                      <th style={{ padding: "0.75rem" }}>Match Type</th>
                      <th style={{ padding: "0.75rem" }}>Lock Game</th>
                      <th style={{ padding: "0.75rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length !== 0 ? (
                      <>
                        {data?.map((item, index) => (
                          <tr key={index} id={`row${item.code}`}>
                            <td style={{ padding: "0.75rem" }}>{index + 1}</td>
                            <td style={{ padding: "0.75rem" }}>{item.code}</td>
                            <td style={{ padding: "0.75rem" }}>{item.name}</td>
                            <td style={{ padding: "0.75rem" }}>
                              {dateTimeFormatter(item.server)}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.match_type}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {!item.isLocked ? (
                                <button
                                  className="btn btn-sm btn-success"
                                  id="active-1"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "none",
                                  }}
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-danger"
                                  id="active-1"
                                  style={{
                                    cursor: "default",
                                    pointerEvents: "none",
                                  }}
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {accountType === "admin" ||
                              accountType === "power_user" ? (
                                <div className="d-flex gap-2 align-items-center justify-content-center">
                                  <Link
                                    className="btn btn-sm btn-primary btn-warning"
                                    to={`/recyclesession?id=${item.id}`}
                                  >
                                    <span className="mr-1">S</span>
                                    <FontAwesomeIcon icon={faGamepad} />
                                  </Link>
                                  <Link
                                    className="btn btn-sm btn-primary btn-danger"
                                    to={`/recyclematch?id=${item.id}`}
                                    state={{
                                      team1: item.team1,
                                      team2: item.team2,
                                    }}
                                  >
                                    <span className="mr-1">M</span>
                                    <FontAwesomeIcon icon={faGamepad} />
                                  </Link>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center p-5">
                          <h6 className="p-5">No records to display</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
