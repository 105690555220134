import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound404() {
  const navigate = useNavigate();

  let prefix = "";

  // Check if the site is running locally
  if (window.location.href.includes("localhost")) {
    prefix = "localhost";
  } else {
    // Extract the main domain (removes subdomain if present)
    const domainParts = window.location.href.split("//")[1].split(".");
    const domainName = domainParts.slice(-2, -1)[0]; // Gets the main domain name, e.g., "acebets"

    // Set the prefix as the extracted domain name, converted to uppercase
    prefix = domainName.toUpperCase();
  }

  console.log(prefix);

  return (
    <div className="w-100 h-100 d-flex flex-column text-center align-items-center pt-5">
      <div className="MuiTypography-root MuiTypography-h1 txtcolor fw-bold mb-4">
        {prefix}
      </div>
      <div className="fs-2 text-red mb-3 fw-bolder">
        <span className="fs-3">❌</span>404 Not found
        <span className="fs-3">❌</span>
      </div>
      <div className="fs-3 mb-3">
        It looks like you got on the wrong path. Get back now. 👇
      </div>
      <button
        className="btn btn-primary w-25"
        onClick={() => {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }}
      >
        Home
      </button>
    </div>
  );
}
