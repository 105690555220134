/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./header.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import user2image from "../img/user2-160x160.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import useLogout from "../hooks/useLogout";
import useSocketUserdata from "../hooks/Sockets/useSocketUserdata";

const Header = ({ toggleSidebar }) => {
  let userId = "";
  userId = localStorage.getItem("UUID");
  const accType = localStorage.getItem("account");
  const userPassword = localStorage.getItem("password");

  const navigate = useNavigate();
  const { mutate: logoutUser } = useLogout();
  const socketData = useSocketUserdata(userId);

  const [data, setData] = useState([]);

  const logout = () => {
    const payload = {
      user_id: userId,
      acc_type: accType,
    };

    logoutUser(payload, {
      onSuccess: (response) => {
        console.log(response);
        toast.success("Logout Successfully");
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      },
      onError: (error) => {
        console.error(error);
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      },
    });
  };

  useEffect(() => {
    if (socketData) {
      if (
        socketData.user.status === false ||
        socketData.user.status === "false" ||
        socketData.user.status === "0" ||
        socketData.user.status === 0
      ) {
        logout();
      } else if (userPassword !== socketData.user.password) {
        logout();
      } else {
        setData(socketData.user);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  const Profile = () => {
    navigate("/profile");
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <Toaster position="top-right" reverseOrder={false} />

      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" role="button" onClick={toggleSidebar}>
            <i className="fas fa-bars icon-size-header">
              <FontAwesomeIcon icon={faBars} />
            </i>
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        <li className="nav-item dropdown user-menu">
          <a
            href="#"
            className="nav-link user-pic-code-size dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src={user2image}
              className="user-image img-circle elevation-2"
              alt="UserProfile"
            />
            {data?.code ? data?.code : "000"}
          </a>
          <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            {/* User image */}
            <li className="user-header bg-primary ">
              <img
                src={user2image}
                className="img-circle elevation-2"
                alt="UserProfile"
              />
              <p>
                {data?.code ? data?.code : "000"}{" "}
                {data?.name ? data?.name : "Demo"}
              </p>
            </li>
            {/* Menu Footer*/}
            <li className="user-footer">
              <button onClick={Profile} className="btn btn-default btn-flat">
                Profile
              </button>
              <button
                onClick={logout}
                className="btn btn-default btn-flat float-right"
              >
                Sign out
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
