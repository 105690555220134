/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useGetUserOnlySession from "../../hooks/Games/Inplay/useGetUserOnlySession";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import Select from "react-select";

export default function CompleteSessionBets() {
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);

  const matchId = UrlSearchParams.get("id");
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  const { mutate: getUserOnlySession } = useGetUserOnlySession();

  const [users, setUsers] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [sessions, setSessions] = useState([]);

  const handleGetSessionOfMatch = () => {
    const payload = {
      match_id: matchId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getUserOnlySession(payload, {
      onSuccess: (response) => {
        //console.log(response);

        let filteredData = [];

        if (accountType !== "admin") {
          // eslint-disable-next-line array-callback-return
          response.data.filter((item) => {
            if (item.view === true) {
              filteredData.push(item);
            }
          });
        } else {
          filteredData = response.data;
        }

        setSessionData(filteredData);
        setAllData(filteredData);

        let userData = [];
        let sessionsData = [];

        filteredData.forEach((el) => {
          if (!userData.some((ele) => ele.id === el.user_account.id)) {
            userData.push({
              id: el.user_account.id,
              name: `${el.user_account.name}`,
            });
          }
          if (!sessionsData.some((ele) => ele.name === el.session)) {
            sessionsData.push({ name: `${el.session}` });
          }
        });

        setUsers(userData);
        setSessions(sessionsData);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (matchId) {
      handleGetSessionOfMatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  function changeUser(value) {
    if (value === "All" || value === "") {
      setSessionData(allData);
    } else {
      const filteredData = allData.filter(
        (item) => item.user_account.id === value
      );
      setSessionData(filteredData);
    }
  }

  function changeSession(value) {
    if (value === "All" || value === "") {
      setSessionData(allData);
    } else {
      const filteredData = allData.filter((item) => item.session === value);
      setSessionData(filteredData);
    }
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Session Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/complete-games">Complete Games</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Session Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            <div className="card">
              <div className="card-body">
                <div className="table">
                  <div className="col-md-12">
                    <table className="table table-bordered align-middle">
                      <tbody>
                        <tr>
                          <th>Client</th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <Select
                              options={[
                                { value: "", label: "Select" },
                                { value: "All", label: "All" },
                                ...users.map((user) => ({
                                  value: user.id,
                                  label: `${user.code} - ${user.name}`,
                                })),
                              ]}
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={""}
                              isDisabled={false}
                              isLoading={users.length === 0 ? true : false}
                              isSearchable={true}
                              isRtl={false}
                              name="selectedUser"
                              onChange={(e) => {
                                const selected = e?.value;
                                changeUser(selected);
                              }}
                            />
                          </td>
                          <td></td>
                          <td>Declare @ null</td>
                        </tr>
                        <tr>
                          <th>Session</th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <Select
                              options={[
                                { value: "", label: "Select Session" },
                                { value: "All", label: "All" },
                                ...sessions.map((session) => ({
                                  value: session.name,
                                  label: session.name,
                                })),
                              ]}
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={""}
                              isDisabled={false}
                              isLoading={sessions.length === 0 ? true : false}
                              isSearchable={true}
                              isRtl={false}
                              name="selectedUser"
                              onChange={(e) => {
                                const selected = e?.value;
                                changeSession(selected);
                              }}
                            />
                          </td>
                          <td>
                            <button className="btn btn-primary">View</button>
                          </td>

                          <td className="text-danger text-md text-bold">00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="col-md-12">
                    <table
                      className="table table-striped table-bordered"
                      style={{ padding: "0.75rem" }}
                    >
                      <thead>
                        <tr style={{ padding: "0.75rem" }}>
                          <th style={{ height: "67px", minWidth: "35px" }}>
                            #
                          </th>

                          <th>Code</th>
                          <th style={{ height: "67px", minWidth: "140px" }}>
                            Session
                          </th>

                          <th>Rate</th>
                          <th>Run</th>
                          <th>Mode</th>
                          <th>Amount</th>
                          <th>Dec</th>
                          <th style={{ height: "67px", minWidth: "150px" }}>
                            Created By
                          </th>
                          {/* {accountType === "admin" && (
                            <th style={{ height: "67px", minWidth: "150px" }}>
                              Action
                            </th>
                          )} */}
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData?.length > 0 ? (
                          sessionData.map((bets, index) => (
                            <tr key={index}>
                              <td style={{ padding: "0.75rem" }}>
                                {index + 1}
                              </td>

                              <td>
                                {bets.user_account.name}(
                                {bets.user_account.code})
                              </td>
                              <td
                                style={{ padding: "0.75rem", height: "70px" }}
                              >
                                {bets.session}{" "}
                                {!bets.view && (
                                  <span className="text-danger">(Hidden)</span>
                                )}
                              </td>

                              <td>{bets.rate}</td>
                              <td>{bets.runs}</td>
                              <td>{bets.mode}</td>
                              <td>{bets.amount}</td>
                              <td>{bets.declare}</td>

                              <td>{dateTimeFormatter(bets.createdAt)}</td>

                              {/* {accountType === "admin" ? (
                                <td>
                                  <button
                                    className="btn btn-sm btn-primary btn-danger mr-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteSession(bets.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleSessionView(bets.id, bets.view);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </button>
                                </td>
                              ) : null} */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={accountType === "admin" ? 10 : 9}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
